import React, { useEffect,useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import { verrifyAccount } from '../../services/userService';
import { useState } from 'react';
import EmailVerificationSuccess from './EmailVerificationSuccess';
import EmailVerificationFail from './EmailVerficationFail';

const EmailVerificationPage = () => {
    const { userInfo } = useSelector(state => state.userLogin)
    const navigate = useNavigate();
    const { verificationToken } = useParams();
    const[loading,setLoading] = useState(true)
    const[success,setSuccess] = useState(false)
    const[fail,setFail] = useState(false)

    useEffect(() => {
        if(userInfo) return navigate("/")
                verrifyAccount(verificationToken).then(res=>{
                        setLoading(false)
                        setSuccess(true)
                        console.log("ignore success")
                }).catch(ex=>{
                console.log(ex)
                    setLoading(false)
                    setFail(true)
                    console.log("ignore success")
                })
    }, [])
    return (
        <>
           {loading&&<div className='spinner-contatiner'>
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
            </div>}

            {success && <EmailVerificationSuccess/>}

            {fail && <EmailVerificationFail/>}
        </>
    );
}

export default EmailVerificationPage;