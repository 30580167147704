import swal from "@sweetalert/with-react";
import { getCurrentUser, loginUser } from "../services/userService";
import { storeData } from "./cart";

//user's actions
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";



//user reducers
export function userLoginReducer(state={},action){
    switch(action.type){
        case USER_LOGIN_REQUEST:
        return {loading:true};
        case USER_LOGIN_SUCCESS:
            return {loading:false, userInfo:action.payload};
        case USER_LOGIN_FAIL:
            return {loading:false, error:action.payload}
        case USER_LOGOUT:
            return {}; 
        default:
            return state;
    }
}

//user's actions creators
export const login = (user) => async(dispatch)=>{
    try{
dispatch({
    type:USER_LOGIN_REQUEST
})
 
const {data}  = await loginUser(user)

dispatch({
    type:USER_LOGIN_SUCCESS,    
    payload:getCurrentUser(data.generatedToken)
})

localStorage.setItem("netgroAuthToken",data.generatedToken)
console.log("user-login--->",data)
swal("",`${data.message}`,  "success")
    }catch(err){
        console.log(err)
        swal("Something's Wrong",err.response.data!=='undefined'?err.response.data:"","error")
dispatch({
    type:USER_LOGIN_FAIL,
    payload:err.message
})
    }
}

export const logout = () => (dispatch)=>{
    localStorage.removeItem('netgroAuthToken');

    dispatch({
        type:USER_LOGOUT
    })
}
