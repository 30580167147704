import React,{useContext, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import usePagination from "../../../../hooks/usePagination"
import PaginateButton from "../../../../components/PaginateButton"
import { deleteProduct } from '../../../../services/userService';
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { useSelector } from 'react-redux';
import {HiDotsHorizontal} from "react-icons/hi"
import ConfirmModal from '../../../../components/common/confirmModal/ConfirmModal';
import { toast } from 'react-toastify';

const Product = ({data,numberPerDisplay,setProducts}) => {
    const [dropdown, setDropdown] = useState(null);
    const navigate = useNavigate();
        const [page, setPage] = useState(1);
        const { slice, range } = usePagination(data, page, numberPerDisplay);
const {userInfo} = useSelector(state=>state.userLogin)
const confirmModalRef = useRef(null);
const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
const [currentProduct, setCurrentProduct] = useState({});

    function deleteMyProduct(id){
            deleteProduct(userInfo.token,id).then(res=>{
                console.log(res)
                setProducts(res.data)
            }).catch(err=>{
                console.log(err)
            })
    }

    function shareProduct(product){
            navigator
              .share({
                title: "Check out my product!",
                text: product.description,
                url: `https://netgro.co/${userInfo.username}/product/${product._id}`,
              })
              .then(() => {
                console.log("Successfully shared");
              })
              .catch((error) => {
                console.log(error)
                toast.error("Something went wrong");
              });
    }
    return ( 
        <>
        <ConfirmModal 
          modalTitle={"Delete Product?"}
          questionText={"Are you sure you want to delete this product? This process cannot be reversed."}
          confirmButtonText={"Delete"}
          isModalOpen={showConfirmDeleteModal}
          modalRef={confirmModalRef}
          confirmAction={() => deleteMyProduct(currentProduct._id)}
          closeModal={() => setShowConfirmDeleteModal(false)}
        />
        {slice.map((product,id)=>(
            <div className='product' key={id}>
            <div className='dots' onClick={()=> setDropdown((dropdown===id)?null:id)}><HiDotsHorizontal style={{height:"20px",width:"20px"}}/>
               {(dropdown===id) && 
                <div className='product_dropdown'>
                    <span onClick={()=>navigate(`/accounts/business/dashboard/${product._id}/edit`)}>Edit</span> 
                    <span onClick={() => { setCurrentProduct(product); setShowConfirmDeleteModal(true) } }>Delete</span> 
                    <span onClick={()=>shareProduct(product)}>Share</span>                         
                </div>
               }
            </div>
            
            <div className='product_img'><img src={product.image_url} className='img' /></div>
            <div className='product_bottom'>
                <span>{product.name}</span>
                <span>&#8358;{product.price}</span>
            </div>
        </div>
        ))}
        <br/>
        <div className='paginate-dashboard-btn'>
        <PaginateButton
              range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
        </div>
        </>
     
     );
}
 
export default Product;