import React, { useState,useEffect, useContext } from 'react';
import '../dashboard_home.styles.css';
import filter from '../../../images/filter.svg';
import file from '../../../images/file.svg';
import file1 from '../../../images/file1.svg';
import home from '../../../images/home.svg';
import shopCart from '../../../images/shopping_cart.svg';
import lockedCash from '../../../images/locked_cash.svg';
import double from '../../../images/double.svg';
import RecentSales from './recent_sales';
import AffiliateDashboardLayout from "../../../components/dashboard/affiliate/AffiliateDashboardLayout";
import { useSelector } from 'react-redux';
import { getAllStores, getReferrerOrders } from '../../../services/userService';
import {IoMdCopy} from "react-icons/io"
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { IndivUserContext } from '../../../context/IndivUserContext';

const AffiliateDashboardHome = () => {
const {userInfo} = useSelector(state=>state.userLogin);
const {sales,setSales,user} = useContext(IndivUserContext)
const [brands,setBrands] = useState([])

useEffect(()=>{
    if (!userInfo) return
    if(userInfo.token){
        getAllStores(userInfo.token).then(res=>{
        setBrands(res.data)
        }).catch(err=>{
            console.log(err)
        })
    }
},[])

const navigate = useNavigate();

    const data = [
        {id:1, up_txt: "Total Sales", mid_txt: sales.length, bottom_txt: "View >", img: shopCart, url:"/accounts/individual/dashboard/orders" },
        {id:2, up_txt: "Total Income", mid_txt: user.wallet.overallEarnings, bottom_txt: "Cash out >", img: lockedCash},
        {id:3, up_txt: "All Brands", mid_txt: brands.length, bottom_txt: "View Brands >", img: double,url:"/accounts/individual/dashboard/brands" }
    ]
    const url = 'https:netgro.co/ladybstore';

    function copyUrlToClipboard() {
        navigator.clipboard.writeText(userInfo ? userInfo.username : "");
        toast.success("Successfully copied to clipboard",{position:'top-center'})
        console.log(userInfo.username)
    }
    return (
    <AffiliateDashboardLayout>
        <div className='dash_home_container'>
            <div className="dash_mid">
                <div className="dash_mid_top">
                    <span>Your Dashboard</span>
                    <div className="url"  style={{width:"20%",background:"transparent"}}>
                        {/* <div className="url_content">
                        <div className="url_img">
                             <span><IoMdCopy className='copy-icon' onClick={copyUrlToClipboard}/></span>
                            </div>
                            <span>Affiliate Name : {userInfo?userInfo.username:"name"}</span>
                        </div> */}
                    </div>
                        {/* <Link to='/accounts/individual/dashboard/brands' style={{textAlign:"right",marginRight:"30px"}}>View Brands</Link> */}
                </div>
                <div className="dash_mid_base">
                    <div className="dash_mid_overflow">
                        {data.map((arr, idx) => (
                            <div key={idx} className="dash_mids_wrapper">
                                <div className="dash_mids_content">
                                    <div className="dash_midd_top">{arr.up_txt}</div>
                                    <div className="dash_mid_mid"> {arr.id===2?
                                  <span>
                                  &#8358;
                                    {arr.mid_txt}
                                  </span>
                                   : arr.mid_txt}</div>
                                    <div className="dash_mid_bottom">
                                        <span onClick={()=>navigate(arr.url?arr.url:"#")}>{arr.bottom_txt}</span>
                                        <div className="dash_mid_img">
                                            <img src={arr.img} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* </div> */}
                <div className="dash_base">
                    <div className="dash_base_top">
                        <span>Recent Sales</span>
                        {/* <div className="filter">
                            <span>Filter</span>
                            <div className="filter_img" ><img src={filter} alt="" /></div>
                        </div> */}
                    </div>
                    <div className="dash_base_main">
                        <RecentSales  sales={sales}/>
                    </div>

                    {/* </div> */}
                </div>
            </div>
        </div>
        </AffiliateDashboardLayout>
    )
}

export default AffiliateDashboardHome;