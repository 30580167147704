import React,{useContext, useEffect} from 'react';
import { GrFormClose } from "react-icons/gr";
import { Link,useNavigate } from 'react-router-dom';
import {CartContext} from "../context/CartContext"
import {customStyles} from "../components/StoreNavbar"
import Modal from 'react-modal';
import { saveAs } from 'file-saver';

function OrderDetails({order,store, owner, toggleCart}) {
    console.log(owner);
    const navigate = useNavigate()
    const digitalProduct = order.products.filter(product=>product.type==="Digital")
    const physicalProduct = order.products.filter(product=>product.type==="Physical")
    const { setShowCart} = useContext(CartContext);
    const [modalOpen, setModalOpen] = React.useState(false);

useEffect(()=>{
if(digitalProduct.length>=1 && order.paymentStatus==="Paid"){
  var data = new Blob([`https://netgro.co/download/${order._id}`],{type:"text/plain;charset=utf-8"});
  saveAs(data,"product_link.txt")
}
},[])

    function openModal() {
        setModalOpen(true)
        }
        
        
              function closeModal() {
                setModalOpen(false);
              }
        
              function afterOpenModal() {
                // references are now sync'd and can be accessed.
                // subtitle.style.color = '#f00';
              }
    return (
        <>
        <div className='customer-order-details'>
              <GrFormClose className="close__Icon" onClick={toggleCart} style={{width:"30px",height:"30px",cursor:"pointer"}}/>
              {order.paymentStatus==="Paid"? <>
              <h1 style={{textAlign:"center"}}>🥳</h1>
            <h2 style={{textAlign:"center",marginTop:"10px",marginBottom:"20px"}}>Successful order</h2>
              </>:  <h2 style={{textAlign:"center",marginTop:"10px",marginBottom:"20px"}}>Failed order</h2>}
              
            <h3 style={{fontWeight:"300",fontSize:".9rem",margin:"0 auto",textAlign:"center",width:"300px"}}>Order ID: {order._id}</h3>
            <h3 style={{textAlign:"center",fontSize:"1rem",marginTop:"30px"}}>Total Price: &#8358;{order.products.reduce((acc,item)=>acc+item.price*item.qty,0)}</h3>
            <hr style={{width:"300px",marginTop:"10px"}}/>
            {
        order.products.map((product,idx)=>(
    <div className='cart-items'  key={idx}>
            <img src={product.image_url} alt=""/>
            <div className='cart-item' >
            <p>{product.name} X {product.qty}</p>
            <p className='item-price'> &#8358;{Number(product.price*product.qty)}</p>
          
    </div>
    <br/>
<hr style={{width:"300px",marginTop:"-20px"}}/>
            </div>  
        ))}


        
    {/* <p><strong>Delivery Address:</strong> {order.user.address}</p>
    <p><strong>Total Price: </strong>NGN {order.products.reduce((acc,item)=>acc+item.price*item.qty,0)}</p>
    <p><strong>Recipent mobile no.: </strong>{order.user.phone}</p>
    <p><strong>Recipent email addres: </strong>{order.user.email}</p> */}
{physicalProduct.length>=1 && order.paymentStatus==="Paid"? <p style={{fontSize:".9rem",width:"230px"}}>
    Your order is being processed. Check your email {order.user.email} for details.
</p>:<p>
    Your order as been placed but payment failed. Check your email {order.user.email} for details.
</p>}

    {
        digitalProduct.length>=1 && order.paymentStatus==="Paid" ?
        <>
<br/>
<button className='download-btn'>
         <a  href={`https://netgro.co/download/${order._id}`} style={{color:"white"}} target={"_blank"}>Click to download digital product</a>
</button>
    <br/>
        </>:""
        }
 <br/>
    <button type='button' className='contact-btn' onClick={openModal}>
   Contact {owner}
    </button>
    <br/>
  {/* {!mobile && <label>Seller's email address:</label>}
    <button type='button' className='contact-btn'>
    <a href={`mailto:${store.owner.email}`}><i class="fa fa-envelope-o" aria-hidden="true"></i>{" "}
     {mobile?"Mail Seller":`${store.owner.email}`}</a>
    </button> */}
        </div>



        <Modal
        isOpen={modalOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"    
      >
      <div className="modal-container">
        <button onClick={closeModal}><i className='fa fa-close fa-2x'></i></button>
        <h2 style={{textAlign:"center", paddingBottom:"10px"}}>About</h2>
        <hr/>
        <br/>
        <div className="modal-content">
        <h4>Address:</h4>
       <p>{store.address}</p>  
       <br/> 
        <h4>Description:</h4>
       <p>{store.description}</p>  
       <br/>
       <h4>Store email address:</h4>
       <p>{store.owner.email}</p>
       <br/>
       <h4>Store mobile number</h4>
       <p>{store.owner.phonenumber}</p>
<br/>  
<div>   
<a href={store.socialMedia.facebook}  target={"_blank"}>
<i class="fa fa-facebook fa-2x" aria-hidden="true" style={{color:"#545454"}}></i>
</a>
<a href={store.socialMedia.instagram}  target={"_blank"}>
            <i class="fa fa-instagram fa-2x"  aria-hidden="true" style={{color:"#545454"}}></i>
</a>
      <a href={store.socialMedia.twitter}  target={"_blank"}>
            <i class="fa fa-twitter fa-2x" aria-hidden="true" style={{color:"#545454"}}></i>     
      </a>
        </div>
        </div> 
       </div>
        <br/>
      </Modal>
</>
    );
}

export default OrderDetails;