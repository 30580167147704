import React,{useContext,useEffect,useState} from 'react';
import './new_products.styles.css';
//import upload from '../../../images/cloud-upload.svg'
import BusinessDashboardLayout from "../../../../components/dashboard/business/BusinessDashboardLayout";
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { toast } from 'react-toastify';
import {editProductDetails, editProductFile, getUserProduct } from '../../../../services/userService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EditProductPage = () => {
    const {   productType,setProductType,
        setProduct,product,user} = useContext(BusinUserContext);
const [filename1,setFilename1] = useState("Upload new product image or leave the existing one, 2MB max")
const [filename2,setFilename2] = useState("Upload new product content or leave the exisitng one")
const [loading,setLoading] = useState(false);
const [uploadStatus1,setUploadingStauts1] = useState("")
const [uploadStatus2,setUploadingStauts2] = useState("")
const {userInfo} = useSelector(state=>state.userLogin);
const navigate = useNavigate();


const {productId} = useParams();
useEffect(()=>{
    if(userInfo){
        getUserProduct(userInfo.username,productId).then(res=>{
            console.log(res)
            setProduct(res.data)
           
        }).catch(err=>{
            console.log(err)
        })
    }
},[productId,userInfo,setProduct])

const handleImageUpload = (e)=>{
    {e.target.name==="image"? 
    setUploadingStauts1("Uploading ...") 
    :
    setUploadingStauts2("Uploading ...")
}
    const file = e.target.files[0];
    if(e.target.files[0].size>2000000&&e.target.name==="image"){
        toast.info("Logo size must not be greater than 2MB")
        e.target=null
        setUploadingStauts1("Couldn't upload file!") 
        }else{
            const formData = new FormData()
    formData.append(e.target.name,file)
    console.log(e.target.files[0])
 {e.target.name==="image"? 
        setFilename1(file.name) 
        :
        setFilename2(file.name)
    }
    setProduct({...product,[e.target.name]:file})
const token = localStorage.getItem("netgroAuthToken")
if(token){
    editProductFile(token,product.type==="Physical"?"physical":"digital",productId,formData).then(res=>{
        console.log(res)
        setProduct(res.data)
        {e.target.name==="image"? 
        setUploadingStauts1("Uploaded Successfully") 
        :
        setUploadingStauts2("Uploaded Successfully")
    }
    }).catch(err=>{
        console.log(err)
        {e.target.name==="image"? 
        setUploadingStauts1("Couldn't upload file!") 
        :
        setUploadingStauts2("Couldn't upload file!")
    }

    })
}else{
    {e.target.name==="image"? 
    setUploadingStauts1("Couldn't upload file!") 
    :
    setUploadingStauts2("Couldn't upload file!")
}
}

        }
}

function handleUpdateProduct(e) {
    e.preventDefault()
    setLoading(true)
    const token = localStorage.getItem("netgroAuthToken")
    if (!product.image || !product.name || !product.description || !product.price) {
            return toast.warn("Pls fill all fields below to proceed.")
    } else {
            editProductDetails(token,productId, { name: product.name, price: product.price, description: product.description,numberInStock:product.numberInStock}).then(res => {
                            setLoading(false)
                            setProduct(res.data);
                            toast.success("Updated Product!")
                            console.log(res)
                    }).catch(err => {
                            setLoading(false)
                            console.log(err)
                            toast.error(!err.response.data?err.message:err.response.data)
                    })
    }

}

const handleBackBtnClick = (e) => {
    e.preventDefault();
    setProduct({name:"", price:"", description:"", image:"", content:"", numberInStock: 0, type: "" });
    navigate(-1);
}


 return ( 
        <BusinessDashboardLayout>
        {/* {!product.type ? 
            <div className='spinner-contatiner'>
    <i class="fa fa-spinner fa-pulse fa-2x"></i>
   </div>: */}
        <div className='newproduct_container'>
            <h3>Edit Product</h3>
            <Link to={`/accounts/business/dashboard/products`} style={{float:"right", paddingRight:"40px"}} onClick={handleBackBtnClick}><i className='fa fa-arrow-left'></i>{" "}Go back</Link>
            <div className='newproduct_wrapper'>
            <div className='add-product-container'>
            <form onSubmit={handleUpdateProduct}>
            <div style={{textAlign:"center",fontSize:".8rem"}}>
                <span>{uploadStatus1}</span>
                </div>
                <label htmlFor="input-1">
                    <div className='img-upload'>
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        <span>{filename1}</span>
                    </div>
                </label>
               {uploadStatus1.length===0 && uploadStatus2.length===0 ?<input type={"file"} id="input-1" className="upload-1"
                    name='image'
     onChange={handleImageUpload}
                />:""}
             
                
                <br/>
                <input type={"text"} placeholder={"Name"} className="input-1"
                     value={product.name}
                name='name'
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
                />
                <br/>
                <textarea placeholder='Description' className='input-2'
                          name="description"
value={product.description}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}   
                />

                <div className='product-type'>
                    <h5>Type:</h5>
                    <br/>
                    <div style={{paddingTop:"20px"}}>
                      {product.type==="Physical"&&
                        <>
                         <input type={"radio"}
                              name='physical'
    checked={true}
     onChange={({target:{name,checked}})=>setProductType({[name]:checked})}
                        />{" "}
                        <label>Physical</label>
                        </>}
                        {product.type==="Digital"&&
                        <>
                        <input type={"radio"}
                            name='digital'
                             checked={productType.digital || true}
    onChange={({target:{name,checked}})=>setProductType({[name]:checked})}
                        />{" "}
                        <label>Digital</label>
                        </>}
                    </div>
                
                </div>
                <br/>
                {product.type==="Digital"&&
                <>
                <div style={{textAlign:"center",fontSize:".8rem"}}>
                <span>{uploadStatus2}</span>
                </div>
                  <label htmlFor="input-2">
                    <div className='img-upload'>
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        <span>{filename2 ? filename2 : 'Upload digital file of product (pdf, mp3, mp4, zip,  jpg)'}</span>
                    </div>
                </label>
                {uploadStatus1.length===0 && uploadStatus2.length===0 ?<input type={"file"} id="input-2" className="upload-1"
                       name="content" onChange={handleImageUpload}
                />:""}
                </>
                }
               <br/>
               <input type={"number"} placeholder={"Number In Stock"} className="input-1" style={{width:"250px"}}
                               name="numberInStock"
value={product.numberInStock}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
               />
                <br/>
                <div className='price-settings'>
                    <select disabled>
                        <option >NGN</option>
                    </select>
                    <input type={"text"}
                          name="price"
value={product.price}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
                    />
                </div>
                <br/>
                <div className='newproduct_base'>
                    <button type='submit' style={{cursor:"pointer"}}>{loading ? "Please Wait..." : "Update"}</button>
                </div>
                </form>
            </div>
            </div>
        </div>
        {/* } */}
        </BusinessDashboardLayout>
     );
}
 
export default EditProductPage;