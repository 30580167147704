import swal from "@sweetalert/with-react";
import axios from "axios";
import {toast} from "react-toastify"

function log(err) {
  console.log(err);
}

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status >= 500;

  // Unexpected errors (network down, server down, db down, bug)
  // - Log them
  // - Display generic and friendly error message to user

  if (expectedError) {
    log(error);
toast.error(!error.response.data?error.message:error.response.data)
  }
  return Promise.reject(error);
});

let http = {
  get: axios.get,
  post: axios.post,
  put:axios.put,
  delete:axios.delete
};

export default http;
