import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './settings.styles.css';
import { useLocation } from 'react-router-dom';

const SettingsNavBar = () => {
    const location = useLocation()
    const [selected, setSelected] = useState(0);
    const settingsOption = [
        {name: 'Profile', link: '/accounts/business/dashboard/settings/profile'},
        {name: 'Business Info', link: '/accounts/business/dashboard/settings/business-info'},
        {name: 'Affiliate', link: '/accounts/business/dashboard/settings/affiliate'},
        {name: 'Payout', link: '/accounts/business/dashboard/settings/payout'}
    ]

    return (
        <div className='settings_navbar_container'>
            <ul className='settings_lists'>
                {settingsOption.map((arr,id) => (
                    <li className='settings_list' key={id} style={{ borderBottom: (location.pathname===arr.link?'2px solid green':'none') }}>
                        <div onClick={() => setSelected(id)} className='span'><Link to={arr.link}>{arr.name}</Link></div>
                    </li>
                ))}
            </ul>

        </div>

    )
}

export default SettingsNavBar;