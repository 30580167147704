import React, { useEffect, useLayoutEffect } from 'react';
import StoreConfigLayout from '../../../components/storeConfig/StoreConfigLayout';
import "./style.css";
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {USER_LOGIN_SUCCESS} from "../../../store/user";
import { getCurrentUser } from '../../../services/userService';

const SuccessfulSetup = () => {
    const { userInfo } = useSelector(state => state.userLogin)
    const dispatch =useDispatch();


const navigate = useNavigate()
    function handleNavigate(){
        const token = localStorage.getItem("netgroAuthToken")
        if(token){
            dispatch({
                type:USER_LOGIN_SUCCESS,
                payload:getCurrentUser(token)
            })
            return navigate("/accounts/business/dashboard")
        }
    }
    return (
        <>
            <StoreConfigLayout header={"Your new shining store is now ready!"} title={"🥳"}>
                <div className='success-setup'>
                    <h1>Store URL</h1>
                    <div className='store-url'>
                        <h3>{`https://netgro.co/${userInfo.username}`}</h3>
                    </div>
                    <div className='share-url'>
                        <button><i class="fa fa-share-alt" aria-hidden="true"></i>  Share</button>
                    </div>
                </div>
            </StoreConfigLayout>
            <div style={{textAlign:"center"}}>
                <button onClick={handleNavigate} className="proceed-dashboard-btn">Proceed to dashboard
                {" "}<i className='fa fa-arrow-right'></i>
                </button>
            </div>
        </>
    );
}

export default SuccessfulSetup;