import React,{useContext, useState,useEffect, useRef} from 'react';
import Input from './input';
// import circle_plus from '../../../images/plus_circle.svg';
// import img_logo from '../../../images/upload_img.svg';
import './settings_config.styles.css'
import Settings from "./settings";
import { BusinUserContext } from '../../../../context/BusinUserContext';
import PageOneComponent from "../../../../components/storeConfig/PageOneComponent";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { editUserStoreInfo, editUserStoreLogo, getUserStoreDetails } from '../../../../services/userService';
import BackgroundPreviewModal from '../../../../components/backgroundPreviewModal/BackgroundPreviewModal';

const BusinessInfo = () => {
   
    const [loading,setLoading] = useState(false)
    const [logoUploadStatus,setLogoUploadStatus] = useState(false)
    const [loadingUpload,setLoadingUpload] = useState(false)
    const [showBgPreviewModal, setShowBgPreviewModal] = useState(false);
    const {userInfo} = useSelector(state=>state.userLogin);
    const {storeInfo,setStoreInfo} = useContext(BusinUserContext);
    const backgroundPreviewModalRef = useRef(null);
    const [isBackgroundPreviewModalActive, setBackgroundPreviewModalActive] = useState(false);

   const [socialLink,setSocialLink] = useState({
    first:false,
    second:false,
    third:false
   })



//    useEffect(()=>{
//         setSocialMedia(storeInfo.socialMedia)
//    },[])
 

   function handleSocialChange({target:{name,value}}){
    const links = {...storeInfo.socialMedia};
    links[name] = value;
    setStoreInfo({...storeInfo,socialMedia:links})
   }

    // useEffect(()=>{
    //     const token  = localStorage.getItem("netgroAuthToken")
    //     if(token){
    //         getUserStoreDetails(token).then(res=>{
    //             console.log(res)
    //             setStoreInfo(res.data)
    //         }).catch(err=>{
    //             toast.error(!err.response.data?err.message:err.response.data)
    //             console.log(err)
    //         })
    //     }
    // },[userInfo])


    function handleFirstLink(){
        setSocialLink({first:true,second:false,third:false})
    }

    function handleSecondLink(){
        setSocialLink({first:false,second:true,third:false})
    }

    function handleThirdLink(){
        setSocialLink({first:false,second:false,third:true})
    }
 
    function handleFormSubmit(e){
        e.preventDefault();
        uploadStoreInfo()
    }

    function uploadStoreLogo(e){
        setLogoUploadStatus("Uploading File...")
        setLoadingUpload(true)
        const file = e.target.files[0];
        if(e.target.files[0].size>2000000){
            toast.info("Logo size must not be greater than 2MB")
            e.target=storeInfo.logo_url
            setLogoUploadStatus("")
            setLoadingUpload(false)
        }else{  
            const formData = new FormData()
        formData.append('logo',file)
        console.log(e.target.files[0])  
        setStoreInfo(prevState=>({...prevState,logo:file}))
        const token = localStorage.getItem("netgroAuthToken")
        if(token){
            editUserStoreLogo(formData,token,storeInfo._id).then(res=>{
                setLogoUploadStatus("Uploaded Successfully")
                toast.success(logoUploadStatus)
                setStoreInfo(res.data)
                setLoadingUpload(false)
            }).catch(err=>{
                console.log(err)
                setLogoUploadStatus("Couldn't upload file")
                setLoadingUpload(false)
            })
        }else{
            setLogoUploadStatus("Couldn't upload file")
        }
        }

    }

    function uploadStoreInfo(){
        setLoading(true)
        const token = localStorage.getItem("netgroAuthToken")
        if(token){
            editUserStoreInfo({address:storeInfo.address,
                description:storeInfo.description,
                socialMedia:storeInfo.socialMedia,backgroundColor:storeInfo.backgroundColor},storeInfo._id,token).then(res=>{
                    console.log(res)
                    setStoreInfo(res.data)
                    setLoading(false)
                    toast.success("Updated store info!")
                }).catch(err=>{
                    toast.error(!err.response.data?err.message:err.response.data)
                    setLoading(false)
                })
        }
        
    }

    const colors = [
        {color:"Blue",value:"#3A80EE"},
        {color:"Pink",value:"#EA596E"},
        {color:"Green",value:"#34A853"},
        {color:"Yellow",value:"#EEAA33"},
        {color:"Ash",value:"#D9D9D9"}
    ]

    const newColor = colors.filter(color=>color.color!==storeInfo.backgroundColor.color)

    return (
        <>

        <BackgroundPreviewModal
            modalRef={backgroundPreviewModalRef}
            isModalOpen={isBackgroundPreviewModalActive}
            closeModal={() => setBackgroundPreviewModalActive(false)}
            currentBg={storeInfo.backgroundColor.color ? storeInfo.backgroundColor.color : ""}
            updateBgColor={(inputName, valuePassed) => setStoreInfo({...storeInfo,[inputName]: valuePassed}) }
        />

        <Settings>  
            <form onSubmit={handleFormSubmit}>
        <div className='settings_config_container'>
            <div className='settings_config_wrapper'>
                <div className='settings_config_content'>
                    <div className='settings_config_header'>
                    <div className='business-image-upload'>
<img src={storeInfo.logo instanceof File ? URL.createObjectURL(storeInfo.logo):storeInfo.logo_url} alt=""/>
<div className='round-container'>

{!loadingUpload &&
<input type="file" name='logo' onChange={uploadStoreLogo} accept="image/*"/>
}
<i className="fa fa-pencil" aria-hidden="true"></i>
</div>
        </div>
                    </div>
                    <div style={{
                        paddingTop:"30px",
                        textAlign:"center"
                    }}>
<h5>{logoUploadStatus}</h5>
                    </div>
                    <div className='settings_config_body'>
                        <Input placeholder={'Store Description'} 
                         value={storeInfo.description}
                         name="description"
                        onChange={e=>{
                            setStoreInfo({...storeInfo,[e.target.name]:e.target.value})
                        }}
                        />
                        <br/>
                        <Input placeholder={'Store Address'}
                        value={storeInfo.address}
                        name="address"
                         onChange={e=>{
                            setStoreInfo({...storeInfo,[e.target.name]:e.target.value})
                        }}
                        />
                        <Input placeholder={'Store Background Color'}
                            value={storeInfo.backgroundColor.color ? storeInfo.backgroundColor.color : ""}
                            name="backgroundColor"
                            isReadOnly={true}
                            showBgPreviewModalOnClick={true}
                            handleInputClick={() => setBackgroundPreviewModalActive(true)}
                        />
                    </div>
                        {/* <select className='colors-dropdown' name="backgroundColor" value={storeInfo.backgroundColor} onChange={e=>{
const data = JSON.parse(e.target.value)
setStoreInfo({...storeInfo,[e.target.name]:data})
                        }}>
                            <option>{storeInfo.backgroundColor.color}</option>
                            {newColor.map((color,idx)=>(
                                <option value={JSON.stringify({color:color.color,value:color.value})}>{color.color}</option>
                            ))}
                        </select> */}
                    <div className='settings_config_base' >
                        <div className='socials'><span onClick={()=>handleFirstLink()} style={{color:socialLink.first&&"#34A853"}}><i className='fa fa-plus'></i>{" "}Instagram</span></div>
                        <div className='socials'><span onClick={()=>handleSecondLink()} style={{color:socialLink.second&&"#34A853"}}><i className='fa fa-plus'></i>{" "}Facebook</span></div>
                        <div className='socials'><span onClick={()=>handleThirdLink()} style={{color:socialLink.third&&"#34A853"}}><i className='fa fa-plus'></i>{" "}Twitter</span></div>
                    </div>
                    <br/>

                        {socialLink.first&&
                        <input className="social-input" placeholder='Instagram' name="instagram" value={storeInfo.socialMedia.instagram} onChange={handleSocialChange}/>}
                        {socialLink.second&&<input className="social-input"  name="facebook" value={storeInfo.socialMedia.facebook} placeholder='Facebook' onChange={handleSocialChange}/>}
                        {socialLink.third&&<input className="social-input" name="twitter" placeholder='Twitter' value={storeInfo.socialMedia.twitter} onChange={handleSocialChange}/>}

                    <div className='settings_config_base'>
                        <button  type='submit' style={{height:"40px",borderRadius:"10px"}} disabled={loading}>{loading?"Please wait ...":'Update'}</button>
                    </div>
            </div>
                </div>
        </div>
                    </form>
        </Settings>
        </>
    )
}

export default BusinessInfo;