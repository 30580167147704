import React from 'react';
import './settings_config.styles.css';

const Input = ({ placeholder }) => {

    return (
        <div className='settings_config_input'>
            <input placeholder={placeholder} />
        </div>
    )
}

export default Input;