import React, { useState, useEffect } from "react";
import Banner from "../../../components/common/Banner";
import Brand from "../../../components/common/Brand";
import Input from "../../../components/common/input/Input";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import { userMessage, usrEmail } from "../../../utils";
import "./index.css";
import { CountryDropdown } from "react-country-region-selector";
import stateCountry from "state-country";
import { registerUser } from '../../../services/userService';
import swal from "@sweetalert/with-react";
import proceed from "../../../images/proceed.svg";
 import "../../../components/common/signup-btn/SignupBtn.css";

const BusinessRegistration = () => {
 let navigate = useNavigate()
  const [isLoading,setIsLoading] = React.useState(false)
 

  const [user, setUser] = useState({
    fullname: "",
    businessname: "",
    email: usrEmail(),
    phonenumber: "",
    country: "",
    state: "",
    password: "",
    username:""
  });

  useEffect(() => {
    sessionStorage.removeItem("usr_choice");  
  },[])
  
  // function getBusinessDomain(){
  //   if(user.businessname) return slugify(user.businessname)
  //   else return slugify(user.businessname)
  // }
   
    
  const { fullname, businessname, email, phonenumber, country, state, password,username } = user;
  
 function returnState(data) {
   return stateCountry.getAllStatesInCountry(data);
 }



   const handleChange = ({ target }) => {
     const usrDetails = { ...user };
      usrDetails[target.name] = target.value;
      setUser(usrDetails);
  };


    const countryState = returnState(country);
  
    function handleSubmit(e){
      e.preventDefault()
      setIsLoading(true)
          registerUser(user,"business").then(res=>{
            setIsLoading(false)
            setUser({
              fullname: "",
              businessname: "",
              email: usrEmail(),
              phonenumber: "",
              country: "",
              state: "",
              username:"",
              password: "",
            })
            // swal("Successful",`${res.data.message}`,"success")
            swal("Your account has been created successfully.","Check your email for some special info!","success")
            navigate(`/accounts/verify-account/${user.email}`)
      console.log(res)
            }).catch(err=>{
              setIsLoading(false)
              swal("Pls try again",`${err.response.data}`,"error")
              console.log(err)
            })
        }

        function slugify(val) {
          return val
            ?.toString()
            .toLowerCase()
            .trim()
            .replace(/&/g, "-and-") // Replace & with 'and'
            .replace(/[\s\W-]+/g, "-"); // Replace spaces, non-word characters and dashes with a single dash (-)
      }
  return (
    <>
      <PageTitle title={"Netgro | Sign Up | Business Account"} />
      <div className="individual_form">
        <div className="form_content">
          <div style={{ position: "relative", top: "-80px" }}>
            <Brand header={"Business account"} />
          </div>
          <form
            style={{ position: "relative", top: "-20px" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Input
              name={"fullname"}
              type={"text"}
              value={fullname}
              placeholder={"Full name"}
              handleChange={handleChange}
            />
            <Input
              name={"businessname"}
              type={"text"}
              minLength={3}
              value={businessname}
              placeholder={"Business name"}
              handleChange={handleChange}
            />
              <Input
              name={"username"}
              type={"text"}
              minLength={5}
              maxLength={15}
              value={slugify(username)}
              placeholder={"Business domain"}
              handleChange={e=>setUser({...user,username:slugify(e.target.value)})}
            />
            <Input
              name={"email"}
              type={"email"}
              value={email}
              placeholder={"Email"}
              handleChange={handleChange}
            />
            <Input
              name={"phonenumber"}
              type={"number"}
              value={phonenumber} 
              placeholder={"Phone number "}
              handleChange={handleChange}
            />
        
            <CountryDropdown
              value={country}
              className="country"
              onChange={(val) => setUser({ ...user, country: val })}
              required
            />
            <br />
            <select
              required
              name="state"
              onChange={handleChange}
              value={state}
              className="state-select"
            >
              <option>{userMessage(countryState,country)}</option>
              {returnState(country)?.map((val, idx) => (
                <option key={idx} value={val?.name}>
                  {val?.name}
                </option>
              ))}
            </select>
            <br />
            <div className="form-bottom">
              <Input
                name={"password"}
                type={"password"}
                value={password}
                placeholder={"Password"}
                handleChange={handleChange}
              />

              {/* <div style={{ position: "relative", bottom: "20px" }}> */}
              {/* <SignupBtn data={user} /> */}
              <div className="section_proceed">
        <input type="checkbox" required />
        <label>
          Accept <Link to="#">terms</Link> & <Link to="#">Conditions</Link>
        </label>
        <br />
        <button type="submit" className="proceed" onClick={handleSubmit} disabled={isLoading}>
        {isLoading?  "Please wait.. ":   <>
        <span> Sign up </span>
          <img src={proceed} alt="proceed" className="proceed_img" />
        </>}   
        </button>
        <br />
        {/* <span
          id={usrLoc()?"section_proceed":""}
        >
          Already have an account? <Link to="/accounts/signin/all">Sign in</Link>
        </span> */}
        <span id='section_proceed'>
          Already have an account? <Link to="/accounts/signin/all">Sign in</Link>
        </span>
      </div>
              {/* </div> */}
            </div>
          </form>
        </div>

        <Banner />
      </div>
    </>
  );
}
 
export default BusinessRegistration;