import React, { useEffect, useState } from 'react';
import { Link, useParams,useLocation } from 'react-router-dom';
import './settings.styles.css';

const SettingsNavBar = () => {
    
    const [selected, setSelected] = useState(0);
    const data = [
        {name: 'Profile', link: '/accounts/individual/dashboard/settings/profile'},
        {name: 'Payout', link: '/accounts/individual/dashboard/settings/payout'}
    ]
    const { section } = useParams();
    const location = useLocation()

    useEffect(() => {
        
        if (section === "payout") return setSelected(1);

    }, [section])


    return (
        <div className='settings_navbar_container'>
            <ul className='settings_lists'>
                {data.map((arr,id) => (
                    <li className='settings_list' key={id} style={{ borderBottom: (location.pathname==arr.link?'2px solid green':'none') }}>
                        <div onClick={() => setSelected(id)} className='span'><Link to={arr.link}>{arr.name}</Link></div>
                    </li>
                ))}
            </ul>

        </div>

    )
}

export default SettingsNavBar;