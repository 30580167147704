import React, { useEffect, useMemo, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { IoSearchOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import useClickOutside from "../../hooks/useClickOutside";

import "./style.css";


const BackgroundPreviewModal = ({ currentBg, isModalOpen, modalRef, closeModal, updateBgColor }) => {

    const [currentColor, setCurrentColor] = useState("");
    const [selectedColor, setSelectedColor] = useState({});
    const isSmallScreen = useMediaQuery({ query: "(max-width: 992px)"});

    const bgColorOptions = useMemo (() =>{
        return [
            {color: "Blue", value: "#3A80EE"}, 
            {color: "Pink", value: "#EA596E"}, 
            {color: "Green", value: "#34A853"}, 
            {color: "Yellow", value: "#EEAA33"}, 
            {color: "Ash", value: "#D9D9D9"}
        ];
    }, []) 


    useClickOutside(modalRef, () => closeModal());

    useEffect(() => {
        
        setCurrentColor(currentBg);
        
        const colorToSelect = bgColorOptions.find(color => color.color === currentBg);
        setSelectedColor(colorToSelect)

    },[currentBg, bgColorOptions])

    // to update on Chrome
    const handleChange = (e) => {
        const currentColorSelected = JSON.parse(e.target.value);

        setCurrentColor(currentColorSelected.value);
        setSelectedColor(currentColorSelected);
    }

    return <>
        { 
            isModalOpen && <div className="product__Modal__Overlay bg__Modal">
            <div className="product__Modal__Container" ref={modalRef}>
                <GrFormClose className="close__Icon" onClick={() => { updateBgColor("backgroundColor", selectedColor); closeModal(); }} />

                <div className="preview__Background__Container">
                    {!isSmallScreen && <h3 className="title">Store Preview</h3>}
                    <div className="store__Preview__Container">
                        <div className="default__Container">
                            <div className="store__Preview__Item__Container" style={{ background: currentColor }}>
                                <div className="store__Preview__Item" style={{ background: currentColor }}>
                                    <div className="preview__Search__Container">
                                        <IoSearchOutline />
                                        <span>Search Products</span>
                                    </div>
                                    <div className="preview__Products__Container">
                                        <div className="preview__Product__Item default__Container">
                                            <img src="/images/milk.png" alt="preview test product"/>
                                            <p>Cow crown milk (1litter)</p>
                                        </div>
                                        <div className="preview__Product__Item default__Container">
                                            <img src="/images/woman.png" alt="preview test product" />
                                            <p>Skin glow formula</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="change__Color__Item">
                            <p className="title">Change Color</p>
                            <select name="backgroundColor" aria-label="change background color of your site" onChange={handleChange}>
                                {
                                    React.Children.toArray(bgColorOptions.map(colorOption => {
                                        return <option selected={colorOption.color === currentBg ? true: false} value={JSON.stringify(colorOption)}>{colorOption.color}</option>
                                    }))
                                }
                            </select>
                            <button className="done__Btn" onClick={() => { updateBgColor("backgroundColor", selectedColor); closeModal(); }}>Done</button>

                            {isSmallScreen && <h3 className="title">Store Preview</h3>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </>
}

export default BackgroundPreviewModal;
