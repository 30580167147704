import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import "./wallet.css";
import Transaction from "./Transaction";
import info from '../../../../images/info.svg';
import bank from '../../../../images/bank.svg';
import arrow from '../../../../images/arrow.svg';
import Modal from 'react-modal';
import BusinessDashboardLayout from "../../../../components/dashboard/business/BusinessDashboardLayout";
import { BusinUserContext } from "../../../../context/BusinUserContext";
import AddAccountModal from "./addAccountModal/AddAccountModal";
import PayoutModal from "./payoutModal/PayoutModal";


Modal.setAppElement("#root");


const Wallet = () => {

    const [modal, setModal] = useState(false);
    const [screen, setScreen] = useState(false);
    const {user} = useContext(BusinUserContext);
    const addAccountModalRef = useRef(null);
    const payoutModalRef = useRef(null);
    const [ showAddAccountModal, setShowAddAccountModal ] = useState(false);
    const [ showPayoutModal, setShowPayoutModal ] = useState(false);

    useEffect(() => {
        return () => {
            window.addEventListener("resize", () => {
              if (window.innerWidth <= 1400) setScreen(true);
              else setScreen(false);
            });
        };
    }, []);
    const customStyles = {
        content : {
            top: "50%",
            width: screen ? "70%" : "auto",
            bottom: "auto",
            left: "50%",
            right: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px"
        },
        overlay: {
            background: "rgba(0, 0, 0, 0.4)",
            position: "fixed",
            zIndex: "1000000"
        },
    };

    const handleAddAccountClick = (e) => {
        e.preventDefault();
        setShowAddAccountModal(true);
    }

    const handleCashOutBtnClick = (e) => {
        e.preventDefault();
        setShowPayoutModal(true);
    }

    return (
        <BusinessDashboardLayout>

        <AddAccountModal 
            modalRef={addAccountModalRef}
            isModalOpen={showAddAccountModal}
            closeModal={() => setShowAddAccountModal(false)}
        />

        <PayoutModal 
            modalRef={payoutModalRef}
            isModalOpen={showPayoutModal}
            closeModal={() => setShowPayoutModal(false)}
            availableBal={"200.7k"}
        />

        <div className="wallet_container">
            <div className="wallet_wrapper">
                <div className="wallet_content">
                    <div className="wallet_top">
                        <h1>Wallet</h1>
                        {/* <Link to="#" className="add_account" onClick={() => setModal(true)} >+ Add account </Link> */}
                        <Link className="add_account" to="#" onClick={handleAddAccountClick}><i style={{paddingRight:"10px"}} className="fa fa-plus"></i>{" "}Add Account</Link>
                    </div>
                    <div className="wallet_mid_container">
                        <div className="wallet_mid">
                            <div className="wallet_mid_wrapper">
                                <div className="wallet_mid_content">
                                    <div className="wallet_mid_top">Total income</div>
                                    <div className="wallet_mid_mid">₦200.7k</div>
                                    <div className="wallet_mid_base">
                                        <span>Affiliate account</span>
                                        <img src={info} />
                                    </div>
                                </div>
                            </div>
                            <div className="wallet_mid_img">
                                <img src={arrow} />
                            </div>
                            <div className="wallet_mid_wrapper">
                                <div className="wallet_mid_content">
                                    <div className="wallet_mid_txt">My Account</div>
                                    <div className="wallet_mid_texts">
                                        <div className="wallet_small_txt">
                                         {user&&user.accountDetails.accountName.length>1?
                                         <>
                                            <span>{user.accountDetails.accountName}</span>
                                            <span>{user.accountDetails.bankName}</span>
                                            <span>{user.accountDetails.accountNumber}</span>
                                         </>
                                         :
                                         <>
                                         <span style={{fontSize:"13px"}}>Add your account details in settings</span>
                                         </>
                                       
                                         }
                                        </div>
                                        <div className="image">
                                            <img src={bank} />
                                        </div>
                                        
                                    </div>
                                    <div className="wallet_mid_base" style={{border: 'none'}}>
                                        <Link to="#" className="span" onClick={handleCashOutBtnClick}>{"Cash out >"}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Transaction />
                </div>
            </div>
            
            <Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                style={customStyles}
            >
                <div className="modal_wrapper">
                    <div className="modal_content">
                        <h1>Payout</h1>
                        <span className="med_txt"><span className="span">2% transaction fee will be deducted for every successful payout </span></span>
                        <span className="big_txt">₦200.7k</span>
                        <div className="modal_input"><input type="text" placeholder="Amount"></input></div>
                        <div className="modal_input"><input type="text" placeholder="Select bank"></input></div>
                        <div className="modal_base">
                            <Link to="#" className="add_account">Request</Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
        </BusinessDashboardLayout>
    )
}

export default Wallet;