import React from 'react';
import logo from "../images/vector.png";

const StoreFooter = () => {
    return ( 
        <>
            <footer>
            <div className='store-footer'>
                <div className='footer-span'>
                <img src={logo} alt=""/>
                <span style={{fontWeight:"600"}}>Powered by netgro | </span><span style={{color:"#34A853"}} > <a href="https://netgro.co" target="_blank">Sell & earn</a></span>
                </div>
            </div>
            </footer>
        </>
     );
}
 
export default StoreFooter;