import React, {useState,useEffect} from 'react';
import Banner from '../../../components/common/Banner';
import Brand from '../../../components/common/Brand';
import Input from '../../../components/common/input/Input';
import "./index.css";
import PageTitle from "../../../components/PageTitle";
import { usrEmail,userMessage } from '../../../utils';
import { CountryDropdown } from "react-country-region-selector";
 import stateCountry from "state-country";
import { Link, useNavigate } from 'react-router-dom';
import { registerUser } from '../../../services/userService';
import swal from "@sweetalert/with-react";
import proceed from "../../../images/proceed.svg";


const IndividualRegistration = () => {
let navigate = useNavigate()
  const [isLoading,setIsLoading] = React.useState(false)
  const [user, setUser] = useState({
    fullname: "",
    email: usrEmail(),
    phonenumber: "",
    country: "",
    state: "",
    password: "",
    username:""
  });


 

    useEffect(() => {
      sessionStorage.removeItem("usr_choice");
    }, []);

  
  
  
    const handleChange = ({ target }) => {
      const usrDetails = { ...user };
      usrDetails[target.name] = target.value;
      setUser(usrDetails);
    };

  const { fullname, email, phonenumber, country, state, password,username } = user;

  function returnState(data) {
    return stateCountry.getAllStatesInCountry(data);
  }

  
  const countryState = returnState(country);

  function handleSubmit(e){
e.preventDefault()
setIsLoading(true)
    registerUser(user,"individual").then(res=>{
      setIsLoading(false)
      setUser({
        fullname: "",
        email: usrEmail(),
        phonenumber: "",
        country: "",
        state: "",
        password: "",
        username:""
      })
      //swal("Successful",`${res.data.message}`,"success")
      swal("Your account has been created successfully.","Check your email for some special info!","success")
      navigate(`/accounts/verify-account/${user.email}`)
console.log(res)
      }).catch(err=>{
        setIsLoading(false)
        swal("Pls try again",`${err.response.data}`,"error")
        console.log(err)
      })
  }
  return (
    <>
      <PageTitle title={"Netgro | Sign Up | Individual Account"} />
      <div className="individual_form">
        <div className="form_content">
          <div style={{ position: "relative", top: "-60px" }}>
            <Brand header={"Individual account"} />
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <Input
              name={"fullname"}
              type={"text"}
              value={fullname}
              placeholder={"Full name"}
              handleChange={handleChange}
            />
            <Input
              name={"username"}
              type={"text"}
              value={username}
              maxLength={7}
              placeholder={"Unique Username"}
              handleChange={handleChange}
            />
            <Input
              name={"email"}
              type={"email"}
              value={email}
              placeholder={"Email"}
              handleChange={handleChange}
            />
            <Input
              name={"phonenumber"}
              type={"number"}
              value={phonenumber}
              placeholder={"Phone number"}
              handleChange={handleChange}
            />

            <CountryDropdown
              value={country}
              className="country"
              onChange={(val) => setUser({ ...user, country: val })}
              required
            />
            <br />
            <select
              required
              name="state"
              onChange={handleChange}
              value={state}
              className="state-select"
            >
              <option>{userMessage(countryState,country)}</option>
              {returnState(country)?.map((val, idx) => (
                <option key={idx} value={val?.name}>
                  {val?.name}
                </option>
              ))}
            </select>
            <br />
            <div className="form-bottom">
              <Input
                name={"password"}
                type={"password"}
                value={password}
                placeholder={"Password"}
                handleChange={handleChange}
              />

              {/* <SignupBtn data={user} /> */}
              <div className="section_proceed">
        <input type="checkbox" required />
        <label>
          Accept <Link to="#">terms</Link> & <Link to="#">Conditions</Link>
        </label>
        <br />
        <button type="submit" className="proceed" onClick={handleSubmit} disabled={isLoading}>
        {isLoading?  "Please wait.. ":   <>
        <span> Sign up </span>
          <img src={proceed} alt="proceed" className="proceed_img" />
        </>}   
        </button>
        <br />
        {/* <span
          id={usrLoc()?"section_proceed":""}
        >
          Already have an account? <Link to="/accounts/signin/all">Sign in</Link>
        </span> */}
        <span id='section_proceed'>
          Already have an account? <Link to="/accounts/signin/all">Sign in</Link>
        </span>
      </div>
            </div>
          </form>
        </div>

        <Banner />
      </div>
    </>
  );
}
 
export default IndividualRegistration;