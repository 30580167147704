import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { TbCurrencyNaira } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnalyticsCard from "../../../../components/common/analyticsCard/AnalyticsCard";
import AffiliateDashboardLayout from "../../../../components/dashboard/affiliate/AffiliateDashboardLayout";
import { IndivUserContext } from "../../../../context/IndivUserContext";
import { getTransactions } from "../../../../services/userService";
import { formatDateAndTime } from "../../../../utils";
import AddAccountModal from "./addAccountModal/AddAccountModal";
import PayoutModal from "./payoutModal/PayoutModal";


const AffiliateWallet = () => {
    const payoutModalRef = useRef(null);
    const updateAccountModalRef = useRef(null);
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [showUpdateAccountModal, setShowUpdateAccountModal] = useState(false);
    const { user } = useContext(IndivUserContext);
    const { userInfo } = useSelector(state => state.userLogin);
    const [transactions, setTransactions] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {

        if (!userInfo) return;

        getTransactions(userInfo.token, userInfo.accountType).then(res => {
            setTransactions(res.data);
        }).catch(err => {
            console.log(err);
        })

    }, [userInfo]);

    return <>
        <AddAccountModal
            modalRef={updateAccountModalRef}
            isModalOpen={showUpdateAccountModal}
            closeModal={() => setShowUpdateAccountModal(false)}
        />
        <PayoutModal
            modalRef={payoutModalRef}
            isModalOpen={showPayoutModal}
            closeModal={() => setShowPayoutModal(false)}
            availableBal={user.wallet.activeBal}
            updateTransactions={setTransactions}
        />

        <AffiliateDashboardLayout>
        <div className="business__Analytics__Container">
                <div className="business__Wallet__Intro">
                    <h3 className="title__Text">Wallet</h3>
                    {/* <button className="update__Btn" onClick={() => setShowUpdateAccountModal(true)}>
                        <FaPlus className="update__Account__Icon" />
                        <span>Update Account</span>
                    </button> */}
                </div>
                <section className="business__Analytics__Intro" role={"main"}>
                    <AnalyticsCard 
                        analyticTitle={'Total Income'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={user.wallet.overallEarnings}
                        hasAdditionalInfo={true}
                        additionalInfo={"Affiliate Account"}
                        additionalInfoIcon={<AiOutlineInfoCircle />}
                    />
                    {/* <AnalyticsCard
                        analyticTitle={'Pending Income'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={user.wallet.awaitingClearanceBal}
                        hasAdditionalInfo={true}
                        additionalInfo={"View details"}
                        additionalInfoIcon={<MdOutlineKeyboardArrowRight />}
                        handleCardItemClick={() => {}}
                    /> */}
                    <AnalyticsCard
                        analyticTitle={'Available Balance'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={user.wallet.activeBal}
                        hasAdditionalInfo={true}
                        additionalInfo={"Cash Out"}
                        additionalInfoIcon={<MdOutlineKeyboardArrowRight />}
                        handleCardItemClick={() => setShowPayoutModal(true)}
                    />

<AnalyticsCard 
                        analyticTitle={'My bank account'}
                        // analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        dataInfo={true}
                        analyticValue={user.accountDetails.accountName?user.accountDetails.accountName:"_____"}
                        analyticValue2={user.accountDetails.bankName?user.accountDetails.bankName:"_____"}
                        analyticValue3={user.accountDetails.accountNumber?user.accountDetails.accountNumber:"_____"}
                        hasAdditionalInfo={true}
                        additionalInfo={"Update Account"}
                        additionalInfoIcon={<MdOutlineKeyboardArrowRight />}
                        handleCardItemClick={() => navigate('/accounts/individual/dashboard/settings/payout', { state: { from: "/accounts/individual/dashboard/wallet"}} )}
                    />
                </section>

                <section className="business__Wallet__Transactions order-container" role={"complementary"}>
                    <h3>Transactions</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Id</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                React.Children.toArray(transactions.map(transaction => {
                                    return <tr onClick={() => console.log(transaction._id)}>
                                        <td>{formatDateAndTime(transaction.createdAt)}</td>
                                        <td>{transaction._id}</td>
                                        <td>{transaction.amount}</td>
                                        <td>{transaction.type}</td>
                                        <td>{transaction.description}</td>
                                    </tr>
                                }))
                            }
                        </tbody>
                    </table>
                </section>
            </div>
        </AffiliateDashboardLayout>
    </>
}

export default AffiliateWallet;
