import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
//reducers
import { cartReducer } from './cart'
import { userLoginReducer } from './user';    
import { getCurrentUser } from '../services/userService'

const reducer = combineReducers({
    cart:cartReducer,
    userLogin:userLoginReducer
})
const cartItemsFromStorage = sessionStorage.getItem("netgroCartItems") ? JSON.parse(sessionStorage.getItem("netgroCartItems")):[]
const shippingAddressFromStorage = localStorage.getItem("shippingAddress") ? JSON.parse(localStorage.getItem("shippingAddress")):{}
const initialState = {
    cart:{cartItems:cartItemsFromStorage,shippingAddress:shippingAddressFromStorage},
    userLogin:{userInfo:getCurrentUser(localStorage.getItem("netgroAuthToken"))}
}

  
  const middleware = [thunk]

  const store = createStore(
    reducer,  
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  )
  
  export default store