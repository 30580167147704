import React, { useContext, useEffect, useState } from 'react';
import './recent_sales.styles.css';
import empty from '../../../images/empty.svg';
import proceed from '../../../images/proceed.svg';
import { useLocation,useNavigate } from 'react-router-dom';
import {BusinUserContext} from "../../../context/BusinUserContext";
import {useSelector} from "react-redux";
import { getStoreOrders } from '../../../services/userService';
import RecentSalesTable from '../recentSalesTable/RecentSalesTable';
import "../recentSalesTable/styles.css";

const RecentSales = ({sales}) => {
    const navigate = useNavigate();

   const data = sales.slice(0,5)
    const EmptyList = () => {
        const location = useLocation();
        return (
            <div className='emptysales_container'>
                <div className='emptysales_wrapper'>
                    <div className='emptysales_content'>
                        <img src={empty} alt=""/>
                        <h2>No Sales yet</h2>
                        <span>Your first sales can be a milestone, but it will surely happen. </span>
                        <div className='button'><div className='button_content' style={{cursor:"pointer"}} onClick={()=>navigate("/accounts/business/dashboard/products/new")}><span style={{ paddingLeft: location.pathname === "/accounts/dashboard/affiliate" && "15px" }}>{location.pathname === "/accounts/dashboard/affiliate" ? "Products" : "Add Products"}</span><img src={proceed} alt="" style={{ paddingRight: location.pathname === "/accounts/dashboard/affiliate" && "30px" }} /></div></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
   {sales.length >=1?
   <div className='recent-sales-table'>
            <RecentSalesTable data={data} rowsPerPage={5}/> 
            <br/>
            <div className='view-more'>
            <button onClick={()=>navigate("/accounts/business/dashboard/orders")}>View more {" "}<i className='fa fa-arrow-right'></i></button>
            </div> 
   </div>:
               <div className='sales_container'>
            <EmptyList/>
        </div>
            }
     
        </>
    )
}

export default RecentSales;