import React, { useState, useEffect,useContext,useRef} from "react";
import DashboardSidebar from "./dashboard_sidebar";
import user_avatar from '../../../images/profile_user.svg';
import notification_bell from '../../../images/notification_bell.svg';
import "./affiliate_dashboard.styles.css";
import { useSelector,useDispatch } from "react-redux";
import {useNavigate } from "react-router-dom";
import BusinContextProvider, { BusinUserContext } from "../../../context/BusinUserContext";
import { getStoreOrders, getUserProfile,getUserStoreDetails } from "../../../services/userService";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import { Link } from "react-router-dom";
import useClickOutside from "../../../hooks/useClickOutside";
import { CgProfile } from "react-icons/cg";
import { logout } from "../../../store/user";
import { AiOutlineLogout } from "react-icons/ai";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const BusinessDashboardLayout = ({children}) => {
    const { userInfo } = useSelector(state => state.userLogin);
    const [full, setFull] = useState(true);
    const navigate = useNavigate()
    const notifyRef = useRef(null);
    const selectRef = useRef(null);
    const profileRef = useRef(null);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const dispatch = useDispatch();

    const {user,setUser,setStoreInfo,storeInfo,orders,setOrders} = useContext(BusinUserContext);

useClickOutside(selectRef)
    const handleChange = () => {
        setFull(!full);
    }

    useEffect(() => {
        if (!userInfo || userInfo.accountType !== "business") {
            return navigate("/accounts/signin/all")
        } else if (userInfo && userInfo?.accountType === "business" && userInfo?.storeConfigured.toString() === "false") {
            return navigate("/configure-store-1")
        }else{
            if(userInfo&&userInfo.token){
                getUserProfile(userInfo.token,"business").then(res=>{
                    console.log(res)
                    setUser(res.data)
                }).catch(error=>{
                   console.log(error)
                   swal("",!error.response.data?error.message:error.response.data,"error")
                })

                getUserStoreDetails(userInfo.token).then(res=>{
                    console.log(res)
                    setStoreInfo(res.data)
                    console.log(storeInfo)
                }).catch(err=>{
                    console.log(err)
                })

                getStoreOrders(userInfo.token).then(res=>{
                    console.log(res)
                    setOrders(res.data)
                }).catch(err=>{
                    console.log(err);
                })
            }
        }


        return () => {
            window.addEventListener("resize", () => {
                if (window.innerWidth <= 500) setIsMobile(true);
                else setIsMobile(false);
            });
        };
    }, [navigate,setStoreInfo,userInfo,setUser]);

    const [isMobile, setIsMobile] = useState(false);
    //const url = 'https:netgro.co/ladybstore';
    const [notification, setNotification] = useState(false)

    useClickOutside(notifyRef,()=>setNotification(false))
    const slicedOrders = orders.slice(0,5)

    function handleLogout(){
        dispatch(logout());
        swal("Logged out Successfully", "", "success")
        navigate("/");
    }

    useClickOutside(profileRef,()=>setShowProfileDropdown(false))
    return (
        <BusinContextProvider>
        <div className="affiliate_container">
            {/* <div className="side_bar_wrapper" style={{width: (full ? '80px' : '250px')}}> */}
            <DashboardSidebar handleChange={handleChange} full={full} user={true} />
            {/* //style={{ width: (full ? "100%" : "90%")}} */}
            <div className="affiliate_wrapper" style={{ width: (isMobile ? '100%' : (full ? "95%" : "90%")) }}>

            {notification && (
                    <div className="notifications_list" ref={notifyRef}>
                        
                        <ul className="notification_lists">
                           
                            {slicedOrders.map((order,idx) => (
                                <li key={idx} className="notification_list" onClick={()=>navigate(`/accounts/business/dashboard/order-details/${order._id}`)}>
                                    <img src={order.products[0].image_url} />
                                    <div className="spans">
                                        <span className="txt">{order.user.name} placed order for {`${order.products.reduce((acc,item)=> acc+item.qty,0)} Products`}</span>
                                        <span className="date">{order.updatedAt.substring(0,10)}</span>
                                    </div>
                                </li>
                            ))}
                            
                        </ul>
                        <span><Link to="/accounts/business/dashboard/orders" style={{color: '#a0aec0', cursor:"pointer"}}>See More</Link></span>
                    </div>
                )}
                <div className="affiliate_content">
                    <div className="dash_top">
                     <h3>{user?user.businessname:"Business Name"}</h3>

                        <div className="right_top">
                        <i onClick={() => { setNotification(!notification); setShowProfileDropdown(false) } } className="fa fa-bell-o" aria-hidden="true"></i>
                          {storeInfo.logo_url ?<img
                          onClick={() => { setNotification(false); setShowProfileDropdown(!showProfileDropdown)} } 
                           src={storeInfo.logo_url}/>:
                            <i className="fa fa-user-circle-o fa-3x"
                            onClick={() => { setNotification(false); setShowProfileDropdown(!showProfileDropdown)} } 
                             aria-hidden="true"></i>}
                             {/* <br/>
                             <button onClick={()=>window.history.back("-1")}>
                             <i class="fa fa-arrow-right" aria-hidden="true"></i> Go Back
                             </button> */}

                          {
                                showProfileDropdown && 
                                
                                <div className="profile__Dropdown__Container" ref={profileRef}>
                                    <ul>
                                        <li onClick={()=>navigate("/accounts/business/dashboard/settings/profile")}><CgProfile className="profile__Dropdown__Icon" />Profile</li>
                                        <li onClick={handleLogout}><AiOutlineLogout className="profile__Dropdown__Icon" />Logout</li>
                                    </ul>
                                </div>
                            }
                        </div>

                    </div>
                  {children}
                </div>
            </div>
        </div>
        </BusinContextProvider>
    )
}

export default BusinessDashboardLayout;