import React from 'react';
import brandLogo from "../../images/logo_.png"
import "./styles.css";

function DeliveryConfirmationLayout({children,name}) {
    return (
        <div className='layout-container'>
        <img src={brandLogo} alt="" className='layout-logo' />
            <h3 className='layout-title'>Hey {name?name:"Customer"} 🥳</h3>
            <p className='layout-info'>Please confirm you have received your order and you are satisfied with it.</p>
            <br/>
            <div className='confirmation-wrapper'>
                {children}
            </div>
            <br/>
            <div className='confirmation-footer'>
              <span>&copy; {new Date().getFullYear()} netgro.co</span>  
              <br/>
              <span> A democratized way of selling and earning big </span>
<br/>
            </div>
        </div>
    );
}

export default DeliveryConfirmationLayout;