import React, {useState,useEffect} from 'react';
import Brand from '../../components/common/Brand';
import Input from '../../components/common/input/Input';
import proceed from "../../images/proceed.svg";
import "./index.css";
import "./index_res.css";
import Banner from '../../components/common/Banner';
import { Link } from 'react-router-dom';
import PageTitle from "../../components/PageTitle";
import {useDispatch,useSelector} from "react-redux"
import { login } from '../../store/user';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [user, setUser] = useState({
        email: "",
        password: ""
    }); 
    const navigate = useNavigate();
    const { loading,error,userInfo} = useSelector(state=>state.userLogin);

    useEffect(()=>{
      if(userInfo && !userInfo?.storeConfigured && userInfo?.accountType==="business"){
        navigate("/configure-store-1")
      }
else if(userInfo && userInfo?.accountType==="business"){
navigate("/accounts/business/dashboard")
}else if(userInfo && userInfo?.accountType==="individual"){
  navigate("/accounts/individual/dashboard")
  }
    },[navigate,userInfo])  

    const handleChange = ({ target }) => {
        const usrDetails = { ...user };
        usrDetails[target.name] = target.value;
        setUser(usrDetails);
    }

   

  const dispatch = useDispatch()

    const handleSubmit = () => {
           console.log(user);
dispatch(login(user));
if(userInfo && !userInfo?.storeConfigured && userInfo?.accountType==="business"){
 return navigate("/configure-store-1")
}else if(userInfo &&  userInfo?.accountType==="business" && userInfo?.storeConfigured.toString()==="true"){
  navigate("/accounts/business/dashboard")
}
else if(!userInfo?.accountType==="individual"){
  navigate("/accounts/individual/dashboard")
  }
    }

    const { email, password } = user;
  return (
    <>
      <PageTitle title={"Netgro Sign In - Sign in to your account"}/>
      <div className="login_container">
        <div className="login_content">
          <Brand header={"Login to your account"} />

          <form>
            <div className="login_input">
              <Input
                name={"email"}
                type={"email"}
                value={email}
                placeholder={"Email"}
                handleChange={handleChange}
              />
              <Input
                name={"password"}
                type={"password"}
                value={password}
                placeholder={"Password"}
                handleChange={handleChange}
              />
              <input type="checkbox" required />
              <label>Remember me</label>
            </div>

            <button type="button" className="proceed" onClick={handleSubmit} disabled={loading}>
            {loading ? <span>Please wait...</span>:<>
            <span> Sign in </span>
              <img src={proceed} alt="proceed" className="proceed_img" />
            </>}
            
            </button>
            <br />
            <span className="proceed_signup">
              Don’t have an Account?{" "}
              <Link to="/accounts/signup/select">Sign up</Link>
            </span>
          </form>
        </div>
        <Banner />
      </div>
    </>
  );
}
 
export default Login;