import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './verification.styles.css';
import Brand from './Brand.js';
import check from '../../images/check.svg';

const EmailVerificationSuccess = () => {

    return (
        <div className='ver-container'>
            <div className='ver-wrapper'>
                <div className='ver-brand'>
                    <Brand />
                </div>
                <div className='note-wrapper'>
                    <div className='note-content'>
                        <div className='icon'>
                            <div className='icon-content' style={{overflow: 'hidden', height: '100px', width: '100px', display: 'flex', justifyContent: 'center'}}>
                                <img src={check} style={{ width: '100px', height: '190px',paddingTop:'30px',paddingLeft:'15px' }}/>
                            </div>
                        </div>
                        
                        <span className='ver-big-text'>Account verified successful</span>
                        <span className='ver-med-text'> Redirecting to your account ...</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailVerificationSuccess;
