import React ,{useEffect} from 'react';
import PageTwoComponent from '../../../components/storeConfig/PageTwoComponent';
import StoreConfigLayout from '../../../components/storeConfig/StoreConfigLayout';
import { useSelector } from 'react-redux';
import { getAllProducts } from '../../../services/userService';
import { useNavigate } from 'react-router-dom';

const StoreConfigPageTwo = () => {
    const {userInfo} = useSelector(state=>state.userLogin);
    const navigate = useNavigate();
        // useEffect(()=>{
        //    if(userInfo && userInfo?.accountType==="business"){
        //     if(!userInfo.businessname){
        //         return navigate("/login")
        //     }else{
        //         getAllProducts(userInfo?.businessname).then(res=>{
        //            return navigate("/set-affiliate-program-1")
        //                     }).catch(err=>{
        //                         if(err.response.status === 404 && userInfo?.storeConfigured.toString()==="false"){
        //                             return navigate("/configure-store-1")
        //                         }
        //                         console.log(err)
        //                     })
        //                 }
        //    }else if(!userInfo || userInfo?.accountType !== "business"){
        // return navigate("/accounts/signin/all")
        //    }
        //                 },[navigate,userInfo])
        
    return (
        <>
            <StoreConfigLayout title={"Add a product"} nextLink={"/set-affiliate-program-1"} prevLink={"/configure-store-1"}>
                <PageTwoComponent />
            </StoreConfigLayout>
        </>
    );
}

export default StoreConfigPageTwo;