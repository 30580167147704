import React, { useState,useContext, useEffect } from 'react';
import Input from './input';
// import circle_plus from '../../../images/plus_circle.svg';
// import img_logo from '../../../images/upload_img.svg';
import './settings_config.styles.css'
import Settings from './settings';
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { setAffiliateRate } from '../../../../services/userService';
import { toast } from 'react-toastify';

const Affiliate = () => {
    const {storeInfo}  =  useContext(BusinUserContext);
  const [rate,setRate] = useState(0);

    
const [loading,setLoading] = useState(false)
useEffect(()=>{
setRate(storeInfo.affiliateRate)
},[storeInfo])

function handleFormSubmit(e){
    setLoading(true)
    e.preventDefault()
    const token = localStorage.getItem("netgroAuthToken")
    setAffiliateRate(rate,token).then(res=>{
        console.log(res)
        setLoading(false)
        toast.success(res.data)
    }).catch(err=>{
        console.log(err)
        setLoading(false)
        toast.error(!err.response.data?err.message:err.response.data)
    })
}
    return (
        <Settings>
        <div className='settings_config_container'>
            <div className='settings_config_wrapper'>
                <div className='settings_config_content'>
                    <div className='settings_config_header'>
                        <div className='img_logo'>
                            <span style={{fontWeight:"lighter"}}>Your affiliate program is unique to your business, you set the % commision you want to give out. Minimum is 10%</span>
                        </div>
                    </div>
                    <form onSubmit={handleFormSubmit}>
                    
                    <div className='settings_config_body'>
                        <Input placeholder={"Affiliate Rate"} value={rate} onChange={(e)=>setRate(e.target.value)} type={"number"}/>  
                    </div>
                    <br/>
                    <div className='settings_config_base'>
                        <button style={{height:"40px",borderRadius:"10px"}} type="submit">{
                            loading ? "Please wait..." : "Update"
                        }</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </Settings>
    )
}

export default Affiliate;