import React from 'react';
import logo from '../../images/brand_logo.svg';

const Brand = () => {

    const styles = {
        container: {
            width: '180px',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',

        },
        content: {
            width: '150px',
            height: '80px',
            display: 'flex',
            justifyContent: 'space-between',
            boxSizing: 'border-box'

        },
        img: {
            width: '40px',
            height: '40px'

        },
        span: {
            fontSize: '2rem',
            fontWeight: 'bold',
            paddingLeft: '5px',
            color: 'green'

        }
    }
    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <img src={logo} style={styles.img}/>
                <span style={styles.span}>netgro</span>
            </div>
        </div>
    )
}

export default Brand;