import React, { useState,useContext, useEffect } from 'react';
import Input from './input';
// import circle_plus from '../../../images/plus_circle.svg';
// import img_logo from '../../../images/upload_img.svg';
import './settings_config.styles.css'
import Settings from "./settings";
import { editUserDetails, getAllBanks, updatePayoutAccount, validateBankAccountDetails } from '../../../../services/userService';
import { toast } from 'react-toastify';
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { useSelector } from 'react-redux';

const Payout = () => {

    const {userInfo} = useSelector(state=>state.userLogin);

    const [banks,setBanks] = useState([]);
  

    const {user,setUser} = useContext(BusinUserContext);
    const [loading,setLoading] = useState(false)

    const [bankCode,setBankCode] = useState("")

    useEffect(()=>{
        if(userInfo && userInfo.token){
            getAllBanks("business","NG",userInfo.token).then(res=>{
                setBanks(res.data);
                // console.log(res.data)
            }).catch(err=>{
                console.log(err)
            })
        }
       
    },[userInfo])

    function  handleFormInputChange({target:{name,value}}){
        const bankDetails = {...user.accountDetails};
        bankDetails[name] = value;
        setUser({...user,accountDetails:bankDetails})
    }

    function handleFormSubmit(e){
        e.preventDefault();
        if(user.accountDetails.accountName==="Account Name" || !user.accountDetails.accountNumber || !user.accountDetails.bankName){
            return toast.warn("Pls fill the required forms")
        }
        setLoading(true)
        editUserDetails(userInfo.token,"business",user).then(res=>{
            console.log(res)
            setLoading(false)
            toast.success("Updated profile!",{position:"top-center"})
        }).catch(err=>{
            console.log(err)
            setLoading(false)
            toast.error(!err.response.data?err.message:err.response.data)
        })
// const token = localStorage.getItem("netgroAuthToken")
// updatePayoutAccount(token,"business",accountDetails).then(res=>{
//     toast.success(res.data);
//     setLoading(false)
//     console.log(res)
// }).catch(err=>{
//     console.log(err) 
//     setLoading(false)
//         toast.error(!err.response.data?err.message:err.response.data)
// })
    }

    //0690000032

    function handleBankSelect({target:{value}}){
        console.log(value)
        const bankDetails = {...user.accountDetails};
       // bankDetails["accountName"] = "Verifying your account..."
        if(user.accountDetails.accountNumber.length!==10) return toast.warn("Enter a valid account number!")
        bankDetails["accountName"] = "Verifying your account..."
        setUser({...user,accountDetails:bankDetails})
        const{code,name}  = JSON.parse(value);
        console.log(JSON.parse(value));
validateBankAccountDetails("business",{account_number:user.accountDetails.accountNumber,account_bank:code},userInfo.token).then(res=>{
    console.log(res)
    if(res.data.status==="success"){
    bankDetails["accountName"]=res.data.data.account_name
    bankDetails["bankName"]=name
    bankDetails["bankCode"]=code
    setUser({...user,accountDetails:bankDetails})
    toast.success(res.data.message,{position:"top-center"})
    }else{
        toast.error("Couldn't verify your account")
        value=null
        bankDetails["accountName"] = "Account Name"
        setUser({...user,accountDetails:bankDetails})
    }
}).catch(err=>{
    console.log(err)
    toast.error("Something failed...")
    bankDetails["accountName"] = "Account Name"
    setUser({...user,accountDetails:bankDetails})
})
    }


    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }

    return (
        <Settings>
        <div className='settings_config_container'>
            <div className='settings_config_wrapper'>
                <div className='settings_config_content'>
                    <div className='settings_config_header'>
                        <span style={{fontWeight:"300"}}>
                         Enter new account details or leave the existing one if there is one
                        </span>
                    </div>
                    <form onSubmit={handleFormSubmit}>
                    <div className='settings_config_body'>
                        <Input
                        name="accountNumber"
                        onInput={maxLengthCheck}
                        minLength={10}
                        maxLength={10}
                         placeholder={'Account Number'} type={"number"} value={user.accountDetails.accountNumber} onChange={handleFormInputChange}/> 
                        </div>
                        <br/>
                    <select className="banks-select" onChange={handleBankSelect}>
                  <option >{user.accountDetails.bankName.length>1?`Your Bank - ${user.accountDetails.bankName}`:"Select Bank..."}</option>
                            {banks.map((bank,idx)=>(
                                <option key={idx} value={JSON.stringify({code:bank.code,name:bank.name})}>{bank.name}</option>
                            ))}
                        </select>
                        <div className='settings_config_body' style={{marginTop:"-30px"}}>
                        <Input placeholder={'Account Name'} 
                        name="accountName"
                        disabled={true}
                     value={user.accountDetails.accountName}/>
                        {/* <Input 
                        name="bankName"
                        placeholder={'Select Bank'} value={accountDetails.bankName} onChange={handleFormInputChange}/> */}
                        
                    </div>
                   
                    <br/>
                    <div className='settings_config_base'>
                     <button 
                        type='submit'
                         style={{height:"40px",borderRadius:"10px"}}>{loading ?"Please wait...":' Add new account'}</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </Settings>
    )
}

export default Payout;