import React, { useState,useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart,removeFromCart } from '../store/cart';
import { CartContext } from '../context/CartContext';
const CartItems = ({cartItems}) => {
  //  const [qty,setQty] = React.useState(0)
    const dispatch = useDispatch()

    function removeFromCartHandler(id){
        dispatch(removeFromCart(id))
            }

            const {setShowCheckout} = useContext(CartContext);

           
            const handleClick = (action,cartItem) => {
                    
                if (action === "inc"){
                   const qtySelected =  cartItem.qty+1;
                   const qty = qtySelected>=cartItem.numberInStock?cartItem.numberInStock:qtySelected;
                 dispatch(addToCart(cartItem,qty))
                    
                }
                if (action === "dec") {
                    const qtySelected = cartItem.qty-1;
                    const qty = qtySelected===0?1:qtySelected;
                    dispatch(addToCart(cartItem,qty))
                }}

                const handleChange = (e,item) => {
                  let qtySelected;
                  if(Number(e.target.value)>=item.numberInStock){
              qtySelected = item.numberInStock
              dispatch(addToCart(item,qtySelected))
                  }else if(Number(e.target.value)===0){
                     dispatch(addToCart(item,1))
                  }
                  else{
                  dispatch(addToCart(item,e.target.value))
                  }
                 }
    return ( 
        <>
    {
        cartItems.map((cartItem,idx)=>(
    <div className='cart-items'  key={idx}>
            <img src={cartItem.image_url} alt=""/>
            <div className='cart-item' >
            {console.log(cartItems)}
            <h5>{cartItem.name}</h5>
            <h5 className='item-price'>&#8358;{Number(cartItem.price*cartItem.qty)}</h5>
            {/* <select value={cartItem.qty} onChange={e=>dispatch(addToCart(cartItem,Number(e.target.value)))}>
            {[...Array(cartItem.numberInStock).keys()].map(x => (
  <option key={x+1} value={x+1}>
    {x+1}
  </option>
))}
            </select> */}
            <div className="product__Quantity__Container" style={{width:"100px",height:"20px"}}>
                                    <span className="cs__Pointer" onClick={() =>handleClick("dec",cartItem)}>-</span>
                                    <input type={'number'} min="1" value={cartItem.qty} name="productNum" id="productNum" step={'1'} disabled onChange={(e) =>handleChange(e,cartItem)} />
                                    <span className="cs__Pointer" onClick={() =>handleClick("inc",cartItem)}>+</span>
                                </div>  
          
    </div>
    <i class="fa fa-trash-o" aria-hidden="true" style={{cursor:"pointer",color:"red"}} onClick={()=>removeFromCartHandler(cartItem._id)}></i>
            </div>  
        ))}
           <br/>
            <button className='cart-checkout-btn' onClick={()=>setShowCheckout(true)}>Checkout</button>
            <br/>
            <br/>
    </>

     );
}
 
export default CartItems;