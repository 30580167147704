import React, { useContext,useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BusinUserContext } from '../../context/BusinUserContext';
import { toast } from 'react-toastify';


const PageTwoComponent = () => {
    const {   productType,setProductType,
        setProduct,product,} = useContext(BusinUserContext);
const [filename,setFilename] = useState("Upload product image (500px by 500px recommended)")

    return ( 
        <>
            <div style={{   display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    marginTop: '40px',
    alignItems: 'center'}}>
            <div className='add-product-container'>
                <label for="input-1">
                    <div className='img-upload'>
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        <span>{filename}</span>
                    </div>
                </label>
                <input type={"file"} id="input-1" className="upload-1" name='image'
                    required 
     onChange={(e)=>{
            const file = e.target.files[0];
            if(e.target.files[0].size>2000000){
                toast.info("Logo size must not be greater than 2MB")
                e.target=null
                }else{
                    const formData = new FormData()
            formData.append('image',file)
            console.log(e.target.files[0])
            setFilename(e.target.files[0].name)
            setProduct({...product,[e.target.name]:file})
                }
        }}
                />
                
                <br/>
                <input type={"text"} placeholder={"Name"} className="input-1"  value={product.name}
                name='name'
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}

    />
                <br/>
                <textarea placeholder='Description' className='input-2'
                    name="description"
value={product.description}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}   
                />

                <div className='product-type'>
                    <h5>Type:</h5>
                    <br/>
                    <div style={{paddingTop:"20px"}}>
                        <input type={"radio"}
                         name='physical'
    checked={productType.physical || !true}
     onChange={({target:{name,checked}})=>setProductType({[name]:checked})}/>{" "}
                        <label>Physical</label>
                        <input type={"radio"} className="digital-radio-box"  name='digital'
                             checked={productType.digital || !true}
    onChange={({target:{name,checked}})=>setProductType({[name]:checked})}
                        />{" "}
                        <label>Digital</label>
                    </div>
                
                </div>
                <br/>
              {productType.digital&&
              <>
              <label for="input-2">
                    <div className='img-upload'>
                        <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        <span>Upload digital file of product (pdf, mp3, mp4, zip,  jpg)</span>
                    </div>
                </label>
                <input type={"file"} id="input-2" className="upload-1" 
                    name="content" required onChange={(e)=>{
                const file = e.target.files[0];
                const formData = new FormData()
                formData.append('content',file)
                console.log(e.target.files[0])
                setProduct({...product,[e.target.name]:file})
            }}
                />
                </>
               }
                <br/>
                <input type={"number"} placeholder={"Number In Stock"} className="input-1" 
                               name="numberInStock"
                               style={{marginTop:productType.digital?'10px':'0px'}}
value={product.numberInStock}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
               />
                <div className='price-settings' >
                    <select disabled>
                        <option >NGN</option>
                    </select>
                    <input type={"number"}
                        name="price"
value={product.price}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
                    />
                </div>
            </div>
            </div>
        </>
     );
}
 
export default PageTwoComponent;