import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.css";
import "react-toastify/dist/ReactToastify.css";
import CartContextProvider from './context/CartContext';
import store from './store';
import BusinContextProvider from './context/BusinUserContext';
import IndivUserContextProvider from './context/IndivUserContext';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
    <CartContextProvider>
    <BusinContextProvider>
    <IndivUserContextProvider>
    <App />
    </IndivUserContextProvider>
    </BusinContextProvider>
    </CartContextProvider>
    </Provider>
    </BrowserRouter>
  //</React.StrictMode> 
);


