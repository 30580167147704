import React, { useState, useEffect, useContext,useRef } from "react";
import DashboardSidebar from "./dashboard_sidebar";
import "./affiliate_dashboard.styles.css";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import { getUserProfile } from "../../../services/userService";
import { IndivUserContext } from "../../../context/IndivUserContext";
import { CgProfile } from "react-icons/cg";
import { logout } from "../../../store/user";
import swal from "@sweetalert/with-react";
import useClickOutside from "../../../hooks/useClickOutside";
import { AiOutlineLogout } from "react-icons/ai";
import { getReferrerOrders } from "../../../services/userService";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const AffiliateDashboardLayout = ( {children}) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector(state => state.userLogin);
    const {user,setUser,sales,setSales} = useContext(IndivUserContext)
    const profileRef = useRef(null);
    const dispatch = useDispatch();
    const notifyRef = useRef(null);
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    useEffect(() => {
        if (!userInfo || userInfo?.accountType !== "individual") {
            return navigate("/accounts/signin/all")
        }else{
            getUserProfile(userInfo.token,"individual").then(res=>{
setUser(res.data)
            }).catch(err=>{
                console.log(err) 
            })
        }
        return () => {
            window.addEventListener("resize", () => {
                if (window.innerWidth <= 500) setIsMobile(true);
                else setIsMobile(false);
            });
        };
    }, [userInfo, navigate])

    useEffect(()=>{
        if(userInfo){
            getReferrerOrders(userInfo.token,userInfo.username).then(res=>{
                console.log(res)
                setSales([...res.data])
            }).catch(err=>{
                console.log(err)
            })
        }
            },[userInfo])

    const [full, setFull] = useState(true);

    const handleChange = () => {
        setFull(!full);
    }
    // useEffect(() => {
       
    // });

      function handleLogout() {
        dispatch(logout());
        swal("Logged out Successfully", "", "success")
        navigate("/");
    }
    useClickOutside(profileRef,()=>setShowProfileDropdown(false))
    const [isMobile, setIsMobile] = useState(false);
    const url = 'https:netgro.co/ladybstore';
    const [notification, setNotification] = useState(false)

    useClickOutside(notifyRef,()=>setNotification(false))
    const slicedOrders = sales.slice(0,5)
    return (
        <div className="affiliate_container">
            {/* <div className="side_bar_wrapper" style={{width: (full ? '80px' : '250px')}}> */}
            <DashboardSidebar handleChange={handleChange} full={full} user={false} />
            {/* //style={{ width: (full ? "100%" : "90%")}} */}
            <div className="affiliate_wrapper" style={{ width: (isMobile ? '100%' : (full ? "95%" : "90%")) }}>

            {notification && (
                    <div className="affiliate_notifications_list" ref={notifyRef}>
                        
                        <ul className="affiliate_notification_lists">
                           
                            {slicedOrders.map((order,idx) => (
                                <li key={idx} className="affiliate_notification_list" onClick={()=>navigate(`/accounts/individual/dashboard/order-details/${order._id}`)}>
                                    <img src={order.products[0].image_url} />
                                    <div className="spans">
                                        <span className="txt">{order.user.name} placed order for {`${order.products.reduce((acc,item)=> acc+item.qty,0)} Products`}</span>
                                        <span className="date">{order.updatedAt.substring(0,10)}</span>
                                    </div>
                                </li>
                            ))}
                            
                        </ul>
                        <span><Link to="/accounts/individual/dashboard/orders" style={{color: '#a0aec0', cursor:"pointer"}}>See all notifications</Link></span>
                    </div>
                )}

                <div className="affiliate_content">
                    <div className="dash_top">
                        <h3>Hi {userInfo ? userInfo.fullname:"User name"}  👋</h3>

                        <div className="right_top">
                            <i className="fa fa-bell-o" onClick={() => { setNotification(!notification); setShowProfileDropdown(false) } } aria-hidden="true"></i>
                            {user.image_url?<img 
                            onClick={() => { setNotification(false); setShowProfileDropdown(!showProfileDropdown)} } 
                            style={{marginLeft: "10px"}} src={user.image_url} alt=""/>:<i 
                            onClick={() => { setNotification(false); setShowProfileDropdown(!showProfileDropdown)} } 
                            className="fa fa-user-circle-o fa-3x" aria-hidden="true"></i>}
                            {
                                showProfileDropdown && 
                                
                                <div className="profile__Dropdown__Container" ref={profileRef}>
                                    <ul>
                                        <li onClick={()=>navigate("/accounts/individual/dashboard/settings/profile")}><CgProfile className="profile__Dropdown__Icon" />Profile</li>
                                        <li onClick={handleLogout}><AiOutlineLogout className="profile__Dropdown__Icon" />Logout</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AffiliateDashboardLayout;