import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useNavigate, useParams } from 'react-router-dom';
import AffiliateDashboardLayout from "../../../../components/dashboard/affiliate/AffiliateDashboardLayout";
import { getOrder} from '../../../../services/userService';
import affiliate from "../../../../images/affiliate.svg";
import { toast } from 'react-toastify';

const OrderDetailsAff = () => {
    const {orderId} = useParams()
    const {userInfo} = useSelector(state=>state.userLogin)
    const ref = React.createRef();
    const [orderStatus,setOrderStatus] = useState("");
    const [reason,setReason] = useState("")
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
    const [order,setOrder] = useState({
        products:[],
        user:{
            name:"",
        },
        deliveryStatus:"",
        paymentStatus:"",
        referrer:""
    });
    useEffect(()=>{
       if(userInfo&&userInfo.token){
        getOrder(orderId,userInfo.token,"individual").then(res=>{
            setOrder(res.data);
           setOrderStatus(order.deliveryStatus)
            console.log(res.data)
        }).catch(err=>{
            console.log(err)
            navigate("*")
        })
       }
    },[])

    return ( 
        <>
        <AffiliateDashboardLayout>
        <div className='order-details-container' ref={ref}>
        <h2>Order ID - #{orderId}</h2> <button type="button" className='print-order'  onClick={() => window.print()}>Print</button>
            <div className='order-details-content'>

<div className='order-details-section-1'>
<h3>CUSTOMER DETAILS</h3>
<h5><strong>Name:</strong> {order.user.name}</h5>
<h5><strong>Email:</strong> XXXXXXXXX</h5>
<h5><strong>Phone:</strong>XXXXXXXXXXX</h5>
<h5><strong>Address</strong> XXXXXXXXX</h5>


<div className='affiliate-details'>
<h3>AFFILIATE DETAILS</h3>
<img src={affiliate}/> <h5><strong>Affiliate:</strong> {order.referrer?order.referrer:"NA"}</h5>
</div>

<br/>
<div style={{
    lineHeight:"10px"
}}>
    <h5><strong>Payment Status: </strong>{order.paymentStatus}</h5>
    <h5><strong>Delivery Status: </strong>{order.deliveryStatus}</h5>
</div>

</div>

    <div className='order-details-divider'></div>
<div className='order-details-section-2'>
<div className='order-details-section-2-first'>
<h3>Total: &#8358; {order.products.reduce((acc,item)=>acc+item.price*item.qty,0)}</h3>
</div>


<div className='order-product-container'>
    

<div className='order-products'>
{
    order.products.map((product,idx)=>(
        <div className='order-product' key={idx}>

<img src={product.image_url} alt=""/>
<div className='order-product-detail'>
<h5 style={{fontWeight:"600"}}>{product.name}</h5>
<h5><strong>Qty</strong>: {product.qty}</h5>
<h5><strong>Cost</strong>: &#8358;{product.qty*product.price}</h5>
</div>

        </div>
    ))
}
</div>
</div>

</div>


            </div>
           </div>
        </AffiliateDashboardLayout>
        </>
     );
}
 
export default OrderDetailsAff;