import React from "react";
import womanPlant from "../../images/woman_plant.png";

const Banner = () => {
    return (
      <div className="section_2">
        <div className="section_2_content">
          <span className="caption_text">
            A democratized way of selling and earning big.
          </span>
          <img src={womanPlant} alt="brand name" className="woman_plant" />
        </div>
      </div>
    );
}
 
export default Banner;
