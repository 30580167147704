import React, { createContext, useState} from 'react';
export const CartContext = createContext();


    const CartContextProvider = ({ children }) => {
        const [showCart, setShowCart] = useState(false);
        const [showOrderDetails,setShowOrderDetails] = useState(false)

    //     const [cartItems, dispatch] = useReducer(cartReducer, [],()=>{
    //         const localData = localStorage.getItem("netgro_cartItems");
    //         return localData?JSON.parse(localData):[]
    //     })

    //     useEffect(() => {
    //      localStorage.setItem("netgro_cartItems", JSON.stringify(cartItems))
    //     }, [cartItems])
    

    // const addItemToCart = (product, qty) => {
    //    return dispatch({type:ADD_TO_CART,payload:{
    //     product,
    //     qty
    //    }})
    // }

    const [showCheckout,setShowCheckout] = useState(false);


    return (
        <CartContext.Provider value={{ showCart, setShowCart,
        showCheckout,setShowCheckout,
        showOrderDetails,setShowOrderDetails,
        }}>
            {children}
        </CartContext.Provider>
    );
}

export default CartContextProvider;