import React , { useEffect, useState,useRef } from "react";
import { TbCurrencyNaira } from "react-icons/tb";
import { FaExchangeAlt } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import AnalyticsCard from "../../../../components/common/analyticsCard/AnalyticsCard";
import AffiliateDashboardLayout from "../../../../components/dashboard/affiliate/AffiliateDashboardLayout";
import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Doughnut, Line } from "react-chartjs-2";

import "./style.css";

import { getAnalytics,getAnalyticsForCustomDate } from "../../../../services/userService";
import { toast } from "react-toastify";
import useClickOutside from "../../../../hooks/useClickOutside";
import { MdKeyboardArrowDown } from "react-icons/md";
import LoadingSpinner from "../../../../components/common/loadingSpinner/loadingSpinner";

ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement,
    Title,
);

const AffliateAnalytics = () => {
    const [affliateAnalytics, setAffliateAnalytics] = useState({});
    const [showDropdownOptions, setShowDropDownOptions] = useState(false);
    const [currentOverviewRange, setOverviewRange] = useState("Last 6 months");
    const [showCustomForm, setShowCustomForm] = useState(false); 
    const selectRef = useRef(null);
    const minDateRef = useRef(null);
    const maxDateRef = useRef(null);
    const [isRequestLoading, setRequestLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [minDateValue, setMinDateValue] = useState("");
    const [maxDateValue, setMaxDateValue] = useState("");

    useClickOutside(selectRef, () => setShowDropDownOptions(false));

    useEffect(() => {

        const token = localStorage.getItem("netgroAuthToken");

        if (token){
            
            getAnalytics(token,"individual").then(res => {
                
                setAffliateAnalytics(res.data.analytics);

            }).catch(err => {
                toast.error(!err.response.data ? err.message: err.response.data);
            });
        }

    }, []);

    useEffect(() => {

        if ( (!minDateRef.current) || (!maxDateRef.current) ) return

        minDateRef.current.max = new Date().toLocaleDateString("en-ca");
        maxDateRef.current.max = new Date().toLocaleDateString("en-ca");

    }, [showCustomForm])

    useEffect(() => {

        if ( (minDateValue === "") || (maxDateValue === "") ) return setDisabled(true);

        if ( minDateValue > maxDateValue ) return setDisabled(true);

        setDisabled(false);

    }, [minDateValue, maxDateValue])

    const overviewOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text:`Revenue in ${currentOverviewRange}`
            }
        }
    }

    const overviewData = {
        labels: affliateAnalytics.revenueOverview ? affliateAnalytics.revenueOverview.months[currentOverviewRange] : [],
        datasets: [
            {
                label: "Revenue",
                data: affliateAnalytics.revenueOverview ? affliateAnalytics.revenueOverview.data[currentOverviewRange] : "",
                backgroundColor: ["#B9F0D2",],
                borderColor: ["#B9F0D2",]
            }
        ]
    }

    const referralsData = {
        labels: ["Referrals"],
        datasets: [
            {
                label: "referrals",
                data: [affliateAnalytics.currentReferrals],
                backgroundColor: ["#B9F0D2"],
                borderColor: ["#B9F0D2"]
            }
        ]
    }


    const handleCustomDateBtnSubmit = (e) => {
        e.preventDefault();

        setRequestLoading(true);
        setDisabled(true);

        const token = localStorage.getItem("netgroAuthToken");

        if (!token) return toast.error("Unauthorized");
        
        getAnalyticsForCustomDate(token, { from: minDateValue, to: maxDateValue},"individual").then(res => {
            const newMonthSelection = Object.keys(res.data.analytics.customSalesOverview.months || {})[0];
            setOverviewRange("Sales " + newMonthSelection);
            setAffliateAnalytics(prevValue => { return { ...prevValue, revenueOverview: { ...prevValue.revenueOverview, months : {  ...prevValue.revenueOverview.months, [`${newMonthSelection}`]: res.data.analytics.customSalesOverview.months[newMonthSelection] } }} });
            setAffliateAnalytics(prevValue => { return { ...prevValue, revenueOverview: { ...prevValue.revenueOverview, data : { ...prevValue.revenueOverview.data, [`${newMonthSelection}`]: res.data.analytics.customSalesOverview.data[newMonthSelection] }}} });
            
            if (!selectRef) return;
            
            const newDistanceFromBottom = Number(window.getComputedStyle(selectRef.current).bottom.split("px")[0]) * 1.25 ;
            selectRef.current.style.bottom = `${newDistanceFromBottom}px`;

            setShowDropDownOptions(false);
            setShowCustomForm(false);
            setRequestLoading(false);
            setDisabled(false);

        }).catch(err => {
            toast.error(!err.response.data ? err.message: err.response.data);
        })

    }
    return <>
        <AffiliateDashboardLayout>
            <div className="affliate__Analytics__Container">
                <h3>Analytics</h3>

                <section className="affliate__Analytics__Intro" role={"main"}>
                    <AnalyticsCard
                        analyticTitle={'Revenue'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={affliateAnalytics.totalRevenue ? affliateAnalytics.totalRevenue : 0}
                    />
                    <AnalyticsCard
                        analyticTitle={'Referrals'}
                        analyticIcon={<FaExchangeAlt className="dark__Blue__Color" />}
                        analyticValue={affliateAnalytics.totalReferrals ? affliateAnalytics.totalReferrals : 0}
                    />
                </section>

                <section className="affliate__Analytics__Revenue__Overview first" role={"complementary"}>
                <div className="overview__Intro">
                    <h3>Overview</h3>
                    <div className="sales__Overview__Select" onClick={() => setShowDropDownOptions(!showDropdownOptions)}>
                            {currentOverviewRange}
                            <MdKeyboardArrowDown className={`dropdown__Icon ${showDropdownOptions ? 'invert' : ''}`} />
                        </div>

                        { 
                            showDropdownOptions && <div className={`sales__Overview__Select__Options__Container ${showCustomForm ? 'custom__Show' : ''}`} ref={selectRef}>
                            
                            {
                                showCustomForm ? <form>
                                    <IoIosArrowBack className="form__Icon" onClick={() => setShowCustomForm(false)} />
                                    <label htmlFor="minDate">From</label>
                                    <input type={"date"} name={"minDate"} ref={minDateRef} value={minDateValue} onChange={(e) => setMinDateValue(e.target.value)} />
                                    <label htmlFor="maxDate">To</label>
                                    <input type={"date"} name={"maxDate"} ref={maxDateRef} value={maxDateValue} onChange={(e) => setMaxDateValue(e.target.value)} />
                                    <button className="sales__Overview__Select__Button" onClick={handleCustomDateBtnSubmit} type={"submit"} disabled={disabled}>{ isRequestLoading ? <LoadingSpinner color={"#283A57"} /> : "Show Results"}</button>
                                </form> :

                                React.Children.toArray(Object.keys(affliateAnalytics.revenueOverview ? affliateAnalytics.revenueOverview.months || {} : {}).map((key, index) => (
                                    <>
                                        {
                                            index + 1 === Object.keys(affliateAnalytics.revenueOverview.months || {}).length ? <>
                                                <div className="sales__Overview__Select__Option" onClick={() => { setOverviewRange(key); setShowDropDownOptions(false);} }>{key[0].toLocaleUpperCase() + key.slice(1)}</div>
                                                <button className="sales__Overview__Select__Button" onClick={() => setShowCustomForm(true)}>Select Other</button>
                                            </> :
                                            <div className="sales__Overview__Select__Option" onClick={() => { setOverviewRange(key); setShowDropDownOptions(false); }}>{key[0].toLocaleUpperCase() + key.slice(1)}</div>
                                        }
                                    </>

                                )))
                            }
                            </div>
                        }
</div>
                    <div className="charts__Container first">
                        <div className="chart__Container">
                            <Line options={overviewOptions} data={overviewData} />
                        </div>
                    </div>
                </section>

                <section className="affliate__Referral__Count__Overview" role={"complementary"}>
                    <h3>Referrals this month</h3>
                  
                     
                    <div className="charts__Container">
                        <p className="referral__Count">You have made {affliateAnalytics.currentReferrals ? affliateAnalytics.currentReferrals : 0} referrals this month</p>
                        <div className="chart__Container">
                            <Doughnut data={referralsData} />
                        </div>
                    </div>
                </section>
            </div>
        </AffiliateDashboardLayout>
    </>
}

export default AffliateAnalytics;
