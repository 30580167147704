import React from 'react';
import SettingsNavBar from './settings_navbar';
import { Routes, Route } from 'react-router-dom';
import Profile from './profile';
import BusinessInfo from './businessinfo';
import Affiliate from './affiliate';
import Payout from './payout';

const Settings = () => {

    return (
        <div className='settings_container'>
            <h3>Settings</h3>
            <div className='settings_wrapper'>
                <div className='settings_content'>
                    <SettingsNavBar />
                    <Routes>
                        <Route path='/' element={<Profile />} />
                        <Route path='businessinfo' element={<BusinessInfo />} />
                        <Route path='affiliate' element={<Affiliate />} />
                        <Route path='payout' element={<Payout />} />
                    </Routes>

                    {/* <BusinessInfo /> */}
                </div>
            </div>
        </div>
    )
}

export default Settings;