import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../context/CartContext';
import { placeOrder, validateReferrer } from '../services/userService';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { toast } from 'react-toastify';
import swal from '@sweetalert/with-react';
import {useDispatch,useSelector} from "react-redux"
import {   removeAllCartItems} from "../store/cart";
import { useParams } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import { GrFormClose } from "react-icons/gr";

const Checkout = ({cartItems,owner, store,toggleCart}) => {
  const {referrer} = useParams();
    const {setShowCheckout,setOrderDetails} = useContext(CartContext);
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
  //  const {cartItems} = useSelector(state=>state.cart);
    const physicalProduct = cartItems.filter(product=>product.type==="Physical")
    const [userInfo,setUserInfo] = useState({
        name:"",
        email:"",
        phone:"",
        address:"",
    });
    const [showOrderDetails,setShowOrderDetails] = useState(false)
const [order,setOrder] = useState({
  user:{
    name:"",
    email:"",
    address:""
  },
  products:[]
})
    const [message,setMessage] = useState("")
console.log(owner);
    const config = {
        public_key: 'FLWPUBK_TEST-e00188484525b06e3a6bfa1e71592b2f-X',
        tx_ref: Date.now(),
        amount: cartItems.reduce((acc,item)=>acc+item.price*item.qty,0),
        currency: 'NGN',
        payment_options: 'card',
        customer: {
          email: userInfo.email,    
          phonenumber: userInfo.phone,
          name: userInfo.name,
        },
        customizations: {
          title: owner,
          description: "Payment for items.",
          logo: 'https://netgro.co/img/fav.png',
        },
      };
    
      const handleFlutterPayment = useFlutterwave(config);


    function handleFormInputChange({target:{name,value}}){
        const newInfo = {...userInfo};
        newInfo[name] = value;
        setUserInfo(newInfo)
    }

    function handleFormSubmit(e){
e.preventDefault()
setLoading(true)
console.log(userInfo)
if (userInfo.phone.length<11) {
  setLoading(false)
  return toast.warn("Invalid phone number")}

  return handleFlutterPayment({  
        callback: (response) => {
           console.log(response);
           // this will close the modal programmatically
            closePaymentModal()

            // making request to api
            if(response.status==="successful"){
              placeOrder(!referrer?
                {products:
    cartItems.map(cartItem=>({productId:cartItem._id,qty:cartItem.qty,name:cartItem.name}))
,user:{...userInfo,address:userInfo.address?userInfo.address:"N/A"},flw_ref:response.flw_ref,tx_ref:response.tx_ref,
transaction_id:response.transaction_id,totalAmount:response.amount,paymentStatus:"Paid",
isPaidAt:Date.now(),ownerStore:store._id,message:message?message:"N/A"
}:{products:
  cartItems.map(cartItem=>({productId:cartItem._id,qty:cartItem.qty,name:cartItem.name}))
,user:{...userInfo,address:userInfo.address?userInfo.address:"N/A"},flw_ref:response.flw_ref,tx_ref:response.tx_ref,
transaction_id:response.transaction_id,totalAmount:response.amount,paymentStatus:"Paid",
isPaidAt:Date.now(),ownerStore:store._id,referrer,message:message?message:"N/A"
}
).then(res=>{
    console.log(res)
    setOrder(res.data)
    setLoading(false)
    toast.success("Your order has been placed successfully!")
 dispatch(removeAllCartItems())
    // window.location.reload()
}).catch(err=>{
  setLoading(false)
  console.log(err)
  toast.error(!err.response.data?err.message:err.response.data)
})
            }
        },
        onClose: (data) => {
            console.log(data)
            if(data){
              placeOrder(!referrer?
                {products:
                cartItems.map(cartItem=>({productId:cartItem._id,qty:cartItem.qty,name:cartItem.name}))
            ,user:{...userInfo,address:userInfo.address?userInfo.address:"N/A"},ownerStore:store._id,message:message?message:"N/A"}: 
            {products:
              cartItems.map(cartItem=>({productId:cartItem._id,qty:cartItem.qty,name:cartItem.name}))
          ,user:{...userInfo,address:userInfo.address?userInfo.address:"N/A"},ownerStore:store._id,referrer,message:message?message:"N/A"}
            ).then(res=>{
                console.log(res)
                setLoading(false)
                setOrder(res.data)
                toast.error("Order placed, but we couldn't complete your payment!")
            }).catch(err=>{
              setLoading(false)
              console.log(err)
              toast.error(!err.response.data?err.message:err.response.data)
            })
            }
        },
      });
    }


    return ( 
      <>
      {order.user.name.length>1?
      <OrderDetails order={order} store={store} owner={owner} toggleCart={toggleCart}/>:
      <>
      <GrFormClose className="close__Icon" onClick={toggleCart} style={{width:"30px",height:"30px",cursor:"pointer"}}/>
      <div className="checkout-container">
          <h2>Checkout</h2>
          <hr/>
      <br/>
      <form onSubmit={handleFormSubmit}>
     <input type={"name"} placeholder="Name" required value={userInfo.name} name="name" onChange={handleFormInputChange}/>
     <input type={"email"} placeholder="Email" required value={userInfo.email} name="email" onChange={handleFormInputChange}/>
     <input type={"number"} placeholder="Phone number" minLength={11}  required value={userInfo.phone} name="phone" onChange={handleFormInputChange}/>
     {physicalProduct.length>=1&& <textarea placeholder='Address' name='address'  required value={userInfo.address} onChange={handleFormInputChange}/>}
     <textarea placeholder='Message (optional)' name="message"  value={message} onChange={({target})=>setMessage(target.value)} minLength={5}/>
     <br/>
   { loading ?<button type='submit' style={{backgroundColor:"#34A853"}} disabled={loading}>Please wait...</button>:
   <button type='submit' style={{backgroundColor:"#34A853"}} >Pay Now{" "}<i className='fa fa-arrow-right'></i></button>}
     <br/>
      </form>
     <button type='button' style={{backgroundColor:"silver"}} onClick={()=>setShowCheckout(false)}>Previous</button>
    </div>
      </>
      }
     
     
    </>
     );
}
 
export default Checkout;