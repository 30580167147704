import React, { useRef, useState } from 'react';
import './settings_config.styles.css';

const Input = ({ placeholder,onChange,value, type="text",name,style,disabled,onInput,defaultValue,minLength,maxLength, isReadOnly, showBgPreviewModalOnClick, handleInputClick}) => {

    const [mouseEntered,setMouseEntered] = useState(false)
    const [isModalActive, setModalActive] = useState(false);
    const backgroundPreviewModalRef = useRef(null);

    return (
        <div className='settings_config_input'> 
            <input placeholder={placeholder} name={name} style={{boxShadow:mouseEntered&&"1px 1px 1px 1px rgba(0, 0, 0, 0.25)",
            border:mouseEntered&&"none",    
            outline:mouseEntered&&"none"}}
            disabled={disabled}
            onInput={onInput}
            onMouseEnter={(e)=>setMouseEntered(true)}
            type={type} onChange={onChange} defaultValue={defaultValue} minLength={minLength} maxLength={maxLength} value={value} onKeyDown={e=>setMouseEntered(true)} onMouseOut={e=>setMouseEntered(false)}
            readOnly={isReadOnly ? true : false}
            onClick={showBgPreviewModalOnClick ? () => handleInputClick(): () => {}}
            />
        </div>
    )
}

export default Input;