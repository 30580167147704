import React,{useContext, useState} from 'react';
import { BusinUserContext } from '../../context/BusinUserContext';
import {toast} from "react-toastify";


const PageOneComponent = () => {

const {setDescription,setAddress,setLogo,logo,address,description, instagram,setInstagram,
    facebook,setFacebook,
    twitter,setTwitter,} = useContext(BusinUserContext);
// const [logo]=useState(logo_)
// const [address] = useState(address_)
// const [description] = useState(description_)

    function handleSubmit(e){
e.preventDefault()
    }

    const [socialLink,setSocialLink] = useState({
        first:false,
        second:false,
        third:false
       })

       function handleFirstLink(){
        setSocialLink({first:true,second:false,third:false})
    }

    function handleSecondLink(){
        setSocialLink({first:false,second:true,third:false})
    }

    function handleThirdLink(){
        setSocialLink({first:false,second:false,third:true})
    }
    return ( 
        <>
        <div className='business-container'>
<form onSubmit={handleSubmit}>

<div className='business-image-upload'>
<img src={logo instanceof File ?URL.createObjectURL(logo):logo} alt="business logo"/>
<div className='round-container'>
<input type="file" required onChange={function(e){
            const file = e.target.files[0];
            if(e.target.files[0].size>2000000){
                toast.info("Logo size must not be greater than 2MB")
                e.target=null
            }else{
                const formData = new FormData()
            formData.append('logo',file)
            console.log(e.target.files[0])
            setLogo(file)
            }

        }}/>
<i className="fa fa-pencil" aria-hidden="true"></i>

</div>
        </div>


        <h5> Image Upload</h5>




        <input type={"text"} required placeholder={"Store description*"} value={description} onChange={(e)=>setDescription(e.target.value)}/>
        <br/>
        <input type={"text"} required placeholder={"Address"} value={address} onChange={e=>setAddress(e.target.value)}/>


        <div className='settings_config_base' style={{width:"60%",margin:"0 auto",paddingTop:"40px"}}>
                        <div className='socials'><span onClick={()=>handleFirstLink()} style={{color:socialLink.first&&"#34A853"}}><i className='fa fa-plus'></i>{" "}Instagram</span></div>
                        <div className='socials'><span onClick={()=>handleSecondLink()} style={{color:socialLink.second&&"#34A853"}}><i className='fa fa-plus'></i>{" "}Facebook</span></div>
                        <div className='socials'><span onClick={()=>handleThirdLink()} style={{color:socialLink.third&&"#34A853"}}><i className='fa fa-plus'></i>{" "}Twitter</span></div>
                    </div>
<br/>
                    {socialLink.first&&
                        <input className="config-social-input" placeholder='Instagram' name="instagram" value={instagram} onChange={(e)=>setInstagram(e.target.value)}/>}
                        {socialLink.second&&<input className="config-social-input"  name="facebook" value={facebook} placeholder='Facebook' onChange={(e)=>setFacebook(e.target.value)}/>}
                        {socialLink.third&&<input className="config-social-input" name="twitter" placeholder='Twitter' value={twitter} onChange={(e)=>setTwitter(e.target.value)}/>}
        </form>
        </div>  
            
        </>
     );
}
 
export default PageOneComponent;