import React,{useContext,useEffect,useState} from 'react';
import AffiliateDashboardLayout from '../../../../components/dashboard/affiliate/AffiliateDashboardLayout';
import "./styles.css";
import { IndivUserContext } from '../../../../context/IndivUserContext';
import { editIndivUserImage, editUserDetails } from '../../../../services/userService';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import profileImg from "../../../../images/profile.png"
import AffliateSettings from './Settings';
import { useLocation, useNavigate } from 'react-router-dom';

const ProfileSettingsPage = () => {
    const {user,setUser} = useContext(IndivUserContext)
    const {userInfo} = useSelector(state=>state.userLogin)
    const [loading,setLoading] = useState(false)
    const [imageUploadStatus,setImageUploadStatus] = useState("")
    const [loadingUpload,setLoadingUpload] = useState(false)
const navigate = useNavigate();
const location = useLocation()

    function handleFormInputChange({target:{name,value}}){
const userDetails = {...user};
userDetails[name]= value;
setUser(userDetails)
    }

    useEffect(()=>{
if(location.pathname==="/accounts/individual/dashboard/settings"){
    return navigate("/accounts/individual/dashboard/settings/profile")
}
    },[])
    function handleFormSubmit(e){
        e.preventDefault();
        setLoading(true)
        console.log(user)
        editUserDetails(userInfo.token,"individual",user).then(()=>{
            setLoading(false)
        toast.success("Profile updated successfully!")
        }).catch(err=>{
            setLoading(false)
            toast.error(!err.response.data?err.message:err.response.data)
        })
    }

    function uploadProfileImage(e){
        setImageUploadStatus("Uploading File...")
        setLoadingUpload(true)
        const file = e.target.files[0];
        if(e.target.files[0].size>2000000){
            toast.info("Logo size must not be greater than 2MB",{position:"top-center"})
            e.target=user.image_url
            setImageUploadStatus("")
            setLoadingUpload(false)
        }else{
            const formData = new FormData()
            formData.append('image',file) 
            setUser(prevState=>({...prevState,image:file}))
            editIndivUserImage(userInfo.token,formData).then(res=>{
                setImageUploadStatus("")
                toast.success("Uploaded Successfully",{position:"top-center"})
                setLoadingUpload(false)
                setUser(res.data)
            }).catch(err=>{
                console.log(err)
                setImageUploadStatus("Couldn't upload file")
                setLoadingUpload(false)
            })
        }
    }
    return ( 
        <>
           <AffliateSettings>
           <div>
                    <div style={{paddingBottom:"70px"}}>
                <div className='settings_content'>
                <div >
<form onSubmit={handleFormSubmit}>
<div className='business-image-upload'>
<img src={user.image instanceof File ? URL.createObjectURL(user.image):!user.image_url?profileImg:user.image_url} alt="" />
{console.log(user)}
<div className='round-container'>
{!loadingUpload &&
<input type="file" name='image' onChange={uploadProfileImage} accept="image/*"/>}
<i className="fa fa-pencil" aria-hidden="true" ></i>
</div>
    <br/>
    <br/>
{imageUploadStatus&&<>
<h5>{imageUploadStatus}</h5>
<br/>
</>}
        </div>
    <input placeholder='Name' name="fullname" type={"text"} value={user.fullname} onChange={handleFormInputChange}/>
    <br/>
    <input placeholder='Email' name='email' type={"email"} value={user.email} onChange={handleFormInputChange}/>
    <br/>
    <input placeholder='Mobile number' name="phonenumber" value={user.phonenumber} onChange={handleFormInputChange}/>
    <br/>
    <button type='submit'>Update</button>
</form>
                </div>

                </div>
            </div>
        </div>
     
           </AffliateSettings>
               
        </>
     );
}
 
export default ProfileSettingsPage;