import React,{createContext,useState} from 'react';
export const IndivUserContext = createContext();

const IndivUserContextProvider = ({children}) => {
    const [user,setUser] = useState({
        email:"",
        fullname:"",
        phonenumber:"",
        image_url:"./images/profile.png",
        accountDetails: {  
            accountName: "Account Name",
            accountNumber: "",
            bankName: "",
            bankCode:""
        },
        wallet: {
            awaitingClearanceBal: 0,
            activeBal: 0,
            overallEarnings: 0,
        },
    })

    const [sales,setSales] = useState([])

    return ( 
<IndivUserContext.Provider value={{user,setUser,sales,setSales}}>
    {children}
</IndivUserContext.Provider>
     );
}
 
export default IndivUserContextProvider;