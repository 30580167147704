import React,{useState,useEffect,useRef, useContext} from 'react';
// import plastic_bottle from '../../../images/plastic bottle.png';
// import beauty_product from '../../../images/beauty_product.png';
// import dots from '../../../images/ellipsis.svg';
// import bi_cardlists from '../../../images/bi_card-list.svg';
// import boxed_bars from '../../../images/boxed_bars.svg';
 import { Link, useNavigate } from 'react-router-dom';
import './all_products.styles.css';
import BusinessDashboardLayout from '../../../../components/dashboard/business/BusinessDashboardLayout';
import { useSelector } from 'react-redux';
import { deleteProduct, getUserProducts } from '../../../../services/userService';
import { getProductByName } from '../../../../services/userService';
import Product from './product';
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { useMediaQuery } from 'react-responsive';

const AllProducts = () => {
    const [dropdown, setDropdown] = useState(null);
    const {userInfo} = useSelector(state=>state.userLogin);
    const navigate = useNavigate();
    const [deleteLoading,setDeleteLoading] = useState(false)

    const [products,setProducts]=useState([])
    // const {products,setProducts} = useContext(BusinUserContext)
     const [productName,setProductName] = useState("")
     const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)"});

    useEffect(()=>{
        if(userInfo){
        if(productName.length===0)
getUserProducts(userInfo.token).then(res=>{
    console.log(res)
    setProducts(res.data)
}).catch(err=>{
    console.log(err)
})}
    },[productName,userInfo])
    
    // const products = [
    //     {name: 'Cow crown milk (1litter)', img: "", dropdown: false, price: 'N2500'},
    //     {name: 'Cow crown milk (1litter)', img: "", dropdown: false, price: 'N2500'},
    //     {name: 'Cow crown milk (1litter)', img:"", dropdown: false, price: 'N2500'},
    //     {name: 'Cow crown milk (1litter)', img: "   ", dropdown: false, price: 'N2500'}
    // ]
    
    // function deleteMyProduct(id){
    //     const token = localStorage.getItem("netgroAuthToken");
    //     setDeleteLoading(true)
    //     if(token){
    //         deleteProduct(token,id).then(res=>{
    //             console.log(res)
    //             setProducts(res.data)
    //             setDeleteLoading(false)
    //         }).catch(err=>{
    //             console.log(err)
    //             setDeleteLoading(false)
    //         })
    //     }else{
    //         setDeleteLoading(true)
    //     }
    // }

    function searchProduct(e){
        e.preventDefault();
        getProductByName(userInfo.username,productName).then(res=>{
            console.log(res)
            setProducts(res.data)
        }).catch(err=>{
            console.log(err)
        })
    
    }
    return (
        <BusinessDashboardLayout>
        <div className='products_container'>
            <div className='products_wrapper'>
                <div className='products_top'>
                    <span>All Products</span>
                    <div className='addnew_button'><Link to='/accounts/business/dashboard/products/new' style={{ textAlign: 'center',color: 'white'}}><i className='fa fa-plus'></i>{" "}{!isSmallScreen && "Add New"}</Link></div>
                </div>
                <div className='products_main'>
                    <div className='products_content'>
                        <div className='products_sort'>
                            <form onSubmit={searchProduct}>
                            <div className='products_sort_content'>
                                <input placeholder='Search products'
                                     value={productName} 
                                     onChange={({target})=>setProductName(target.value)}
                                />
                            </div>
                            </form>
                            <br/>
                            <br/>
                            <br/>
                     {products.length===0 && <span style={{paddingTop:"80px"}}>You have {products.length} product(s)</span>}
                        </div>

                        <div className='allproducts'>
                            {/* {products.map((product, id) => (
                                <div className='product' key={id}>
                                    <div className='dots' onClick={()=> setDropdown((dropdown===id)?null:id)}><i className="fa fa-ellipsis-h fa-2x" aria-hidden="true"></i>
                                       {(dropdown===id) && 
                                        <div className='product_dropdown'>
                                            <span onClick={()=>navigate(`/accounts/business/dashboard/${product._id}/edit`)}>Edit</span> 
                                            <span onClick={()=>deleteMyProduct(product._id)}>{deleteLoading?<i className='fa fa-spinner'></i>: "Delete"}</span> 
                                            <span>Share</span>                         
                                        </div>
                                       }
                                    </div>
                                    
                                    <div className='product_img'><img src={product.image_url} className='img' /></div>
                                    <div className='product_bottom'>
                                        <span>{product.name}</span>
                                        <span>N{product.price}</span>
                                    </div>
                                </div>
                            ))} */}
                            <Product data={products} numberPerDisplay={4} setProducts={setProducts}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </BusinessDashboardLayout>
    )
}

export default AllProducts;