import React from 'react';
import Input from './input';
import circle_plus from '../../../images/plus_circle.svg';
import img_logo from '../../../images/upload_img.svg';
import './settings_config.styles.css'

const Payout = () => {

    return (
        <div className='settings_config_container'>
            
            <h3>Payout Account</h3>
            <div className='settings_config_wrapper'>
                <div className='settings_config_content'>
                    <div className='settings_config_header'>
                        <span>Once set, it can’t be edited. You can only add another account </span>
                    </div>
                    <div className='settings_config_body'>
                        <Input placeholder={'Account Name'} />
                        <Input placeholder={'Account Number'} />
                        <Input placeholder={'Select Bank'} />
                    </div>
                    <div className='settings_config_base'>
                        <button>Add account</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payout;