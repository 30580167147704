import { useParams } from "react-router-dom";
import AffiliateDashboardLayout from "../../../../components/dashboard/affiliate/AffiliateDashboardLayout";
import AffliatePayoutPage from "./payout";
import ProfileSettingsPage from "./ProfileSettingsPage";
import SettingsNavBar from "./settings_navbar";

const AffliateSettings = () => {

    const { section } = useParams();

    return <>
        <AffiliateDashboardLayout>
            <div className="settings_container">
                <div className='settings_wrapper' style={{paddingBottom:"70px"}}>
                    <div className='settings_content'>
                        <SettingsNavBar />
                        <div className='indiv-profile-form'>
                            {
                                (section === undefined || section === "profile") && <>
                                    <ProfileSettingsPage />
                                </>
                            }
                            { section === "payout" && <AffliatePayoutPage /> }
                        </div>
                    </div>
                </div>
            </div>
        </AffiliateDashboardLayout>
    </>
}

export default AffliateSettings;
