import React,{createContext, useState,useEffect} from 'react';
import { getUserProfile } from '../services/userService';
import { toast } from 'react-toastify';
export const BusinUserContext = createContext();


const BusinContextProvider = ({children}) => {

    const [logo,setLogo] = useState("./images/defaultLogo.png");
    const [description,setDescription] = useState("");
    const [address,setAddress] = useState("")
    const [instagram,setInstagram] = useState("")
    const [facebook,setFacebook] = useState("")
    const [twitter,setTwitter] = useState("")
   


    function configureStore(data){
        setAddress(data.address)
        setDescription(data.description)
        setLogo(data.logo_url)
        setInstagram(data.socialMedia.instagram)
        setFacebook(data.socialMedia.facebook)
        setTwitter(data.socialMedia.twitter)
    }

    // const [storeInfo,setStoreInfo]=useState({
    //     logo:"./images/defaultLogo.png",
    //     address:"",
    //     description:"",
    // })

    const [product,setProduct] = useState({
        name:"",
        description:"",
        image:"",
        price:"",
        content:"",
        numberInStock:0,
        type:""
    })

    const [products,setProducts] = useState([])

    function addProduct(newproduct){
        setProduct(newproduct)
        console.log(newproduct)
    }


    const [productType, setProductType] = useState({
        physical: !false,
        digital: false,
      });

const [rate,setRate] = useState(10);

const [user,setUser] = useState({
    email:"",
    businessname:"",
    fullname:"",
    phonenumber:"",
    accountDetails:{  accountName:"Account Name",
    accountNumber:"",
    bankName:"",
    bankCode:""
},
    wallet: {
        awaitingClearanceBal: 0,
        activeBal: 0,
        overallEarnings: 0,
    },
})



const [storeInfo,setStoreInfo] = useState({
    address:"",
    description:"",
    logo_url:"",
    logo:{},
    _id:"",
    backgroundColor:"",
    affiliateRate:"",
    socialMedia:{instagram:"",facebook:"",twitter:""},
    backgroundColor: "",
});
    
const [orders,setOrders] = useState([])

    return ( 
        <BusinUserContext.Provider value={{configureStore,
        setAddress,setDescription,setLogo,
        logo,address,description,
        instagram,setInstagram,
        facebook,setFacebook,
        twitter,setTwitter,
        productType,setProductType,
        setProduct,product,
        setProducts,products,
        addProduct,
        setRate,rate,
        user,setUser,
        storeInfo,setStoreInfo,
        orders,setOrders
        }}>
{children}
        </BusinUserContext.Provider>
     );
}
 
export default BusinContextProvider;