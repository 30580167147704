import swal from "@sweetalert/with-react";
import React, { useContext, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr"
import { TbCurrencyNaira } from "react-icons/tb";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IndivUserContext } from "../../../../../context/IndivUserContext";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { withdrawEarning } from "../../../../../services/userService";

import "./style.css";


const PayoutModal = ({ isModalOpen, closeModal, modalRef, availableBal, updateTransactions }) => {
    const [ disabled, setDisabled ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);
    const { user, setUser } = useContext(IndivUserContext);
    const [ bankDetails, setBankDetails ] = useState("default");
    const [ amountToWithdraw, setAmount ] = useState(0);
    const [ amountToBeCredited, setAmountToCredit ] = useState(0);
    const { userInfo } = useSelector(state => state.userLogin);

    useClickOutside(modalRef, () => closeModal());

    useEffect(() => {

        setAmountToCredit( amountToWithdraw - (amountToWithdraw * 0.05) );

    }, [amountToWithdraw])

    const handlePayoutBtnClick = () => {

        if (bankDetails === "default") return toast.info("Please select a bank")

        if (amountToWithdraw > availableBal) return toast.info("You do not have enough money in your wallet");

        if (amountToWithdraw < 0.01) return toast.info("Withdrawal amount must be greater than or equal to 0.01");

        const dataToSend = {
            amount: amountToWithdraw,
        }

        if (!userInfo) return toast.error("Not authorized!");
        
        setDisabled(true);
        setLoading(true);

        withdrawEarning(userInfo.token, userInfo.accountType, dataToSend).then(res => {
            
            setLoading(false);
            setDisabled(false);

            swal("", `Successfully withdrew ₦${amountToWithdraw}! You will be credited in 1-5 business working days.`, "success");
            closeModal();
            setAmount(0);
            setUser(prevDetails => { return { ...prevDetails, wallet: { ...prevDetails["wallet"], "activeBal": availableBal - amountToWithdraw } } })
            updateTransactions && updateTransactions(prevTransactions => { return [ res.data, ...prevTransactions ]});
            
        }).catch(err => {
            console.log(err)
            setLoading(false);
            setDisabled(false);
            toast.error("Withdrawal to bank failed.");
        })
    }

    return <>
        {
            isModalOpen && <div className="product__Modal__Overlay">
                <div className="product__Modal__Container add__Account__Modal payout__Modal" ref={modalRef}>
                    <GrFormClose className="close__Icon" onClick={() => closeModal()} />

                    <h3 className="title">Payout</h3>
                    <p className="info__Text">5% transaction fee will be deducted for every successful payout</p>

                    <div className="amount__Details__Container">
                        <div className="current__Available__Balance__Item">
                            <TbCurrencyNaira />
                            <span>{availableBal}</span>
                        </div>
                    </div>

                    <div className="account__Details__Container">
                        <p className="amount__Details">You will be credited <TbCurrencyNaira className="amount__Details__Icon" />{amountToBeCredited}</p>
                        <input type={"number"} placeholder={"Amount"} value={amountToWithdraw} onChange={(e) => setAmount(e.target.value)} />
                        <select name="bank" defaultValue={bankDetails} onChange={(e) => setBankDetails(JSON.parse(e.target.value))}>
                            <option disabled={true} value={bankDetails}>Select Bank</option>
                            <option value={JSON.stringify(user.accountDetails)}>{user.accountDetails.bankName} - {user.accountDetails.accountNumber}</option>
                        </select>

                        <button onClick={handlePayoutBtnClick} disabled={disabled}>{isLoading ? "Please wait..." : "Request"}</button>
                    </div>
                </div>
            </div>
        }
    </>
}

export default PayoutModal;
