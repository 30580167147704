import "./style.css";
import { BiCart } from "react-icons/bi";
import useClickOutside from "../../hooks/useClickOutside";
import { useContext, useEffect, useState } from "react";
import {useParams} from "react-router-dom"
import { addToCart } from "../../store/cart";
import { useDispatch } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { BusinUserContext } from "../../context/BusinUserContext";
import { toast } from "react-toastify";


const ProductModal = ({ product, qtySelected, updateQtySelected, isModalOpen, closeModal, modalRef  }) => {
    useClickOutside(modalRef, () => closeModal());
const [qty,setQty] = useState(1)
const {setShowCart} = useContext(BusinUserContext)
// console.log(typeof(product));
const dispatch = useDispatch();
function addToCartHandler(){
    dispatch(addToCart(product,qty))
   closeModal();
   toast.success("Product has been added to cart!",{position:"top-center"})
      }

//       useEffect(()=>{
// setStoreProduct(data)
//       },[data,setStoreProduct])


      const {modal,setModal} = useContext(BusinUserContext)
    const handleClick = (action) => {
        const currentProductQuantity = document.getElementById("productNum");
        
        if (currentProductQuantity.value === "") return currentProductQuantity.value = 1;
            
        if (action === "inc"){
            //currentProductQuantity.value = Number(currentProductQuantity.value) + 1;
            currentProductQuantity.value = Number(currentProductQuantity.value)>=product.numberInStock?product.numberInStock:Number(currentProductQuantity.value) + 1
            setQty(Number(currentProductQuantity.value));
        }
        if (action === "dec") {
            if (Number(currentProductQuantity.value) <= 1) return;

            currentProductQuantity.value = Number(currentProductQuantity.value) - 1;
            setQty(Number(currentProductQuantity.value));
        }
    }

    const handleChange = (e) => {
     if(Number(e.target.value)>=product.numberInStock){
        setQty(product.numberInStock)
     }else if(Number(e.target.value)===0){
        setQty(1)
     }
     else{
        setQty(Number(e.target.value))
     }
    }
    const {storeName} = useParams()


    function shareProduct(product){
        navigator
          .share({
            title: "Check out this product!",
            text: product.description,
            url: `https://netgro.co/${storeName}/product/${product._id}`,
          })
          .then(() => {
            console.log("Successfully shared");
          })
          .catch((error) => {
            console.error("Something went wrong", error);
          });
}
    return <>
        {
            isModalOpen && <div className="product__Modal__Overlay">
                <div className="product__Modal__Container" ref={modalRef}>
                    <GrFormClose className="close__Icon" onClick={closeModal} />

                    <div className="product__Detail__Item">
                        <div className="product__Image__Container">
                            <img src={product.image_url} alt="product item" />
                        </div>
                        <aside className="product__Details__Container">
                            <p className="product__Title">{product.name}</p>
                            <hr />
                            <div className="product__Price__Container">
                                <span className="product__Price">&#8358;{product.price}</span>
                                <div className="product__Quantity__Container">
                                    <span className="cs__Pointer" onClick={() => handleClick("dec")}>-</span>
                                    <input type={'number'} min="1"  value={qty} name="productNum" id="productNum" step={'1'} onInput={handleChange} />
                                    <span className="cs__Pointer" onClick={() => handleClick("inc")}>+</span>
                                </div>
                            </div>
                            <hr />
                            <button className="cart__Btn" onClick={addToCartHandler}><BiCart className="cart__Icon_" /> Add to Cart</button>
                            <button className="share__Btn" onClick={()=>shareProduct(product)} style={{marginTop:"10px"}}>
                            <i className="fa fa-share-alt " style={{marginRight:"10px"}} aria-hidden="true"></i>Share
                            </button>
                            <div className="product__Description">
                                <p>Description</p>
                                <span>{product.description ? product.description.charAt(0).toLocaleUpperCase() + product.description.slice(1) : ""}</span>
                            </div>
                        </aside>
                        
                    </div>
                </div>
            </div>
        }
    </>
}

export default ProductModal;
