import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import brandLogo from "../../images/brand_logo.svg";
import brandName from "../../images/brand_name.svg";

const Brand = ({ header }) => {
  let location = useLocation();

  function usrLoc(){
    if (location.pathname === "/") return true;
    else return false;
  }

    return (
      <>
        <div className={usrLoc() ? "logo_wrapper" : ""}>
          <Link to="/">
            <img
              src={brandLogo}
              alt="netgro logo"
              className={usrLoc() ? "home_logo" : "landing_logo"}
              style={{ cursor: "pointer" }}
            />

            <img
              src={brandName}
              alt="brand name"
              className={usrLoc() ? "home_name" : "landing_name"}
              style={{ cursor: "pointer" }}
            />
          </Link>
          <span className="landing_header">{header}</span>
        </div>
      </>
    );
}
 
export default Brand;