import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import BusinessDashboardLayout from '../../../../components/dashboard/business/BusinessDashboardLayout';
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { getOrder, updateOrderStatus } from '../../../../services/userService';
import affiliate from "../../../../images/affiliate.svg";
import BackButton from '../../../../components/common/backButton/BackButton';
import { toast } from 'react-toastify';

const OrderDetails = () => {
    const {orderId} = useParams()
    const {userInfo} = useSelector(state=>state.userLogin)
    const ref = React.createRef();
    const [orderStatus,setOrderStatus] = useState("");
    const [reason,setReason] = useState("")
  const [loading,setLoading] = useState(false)
  const navigate = useNavigate()
    const [order,setOrder] = useState({
        _id:"",
        products:[],
        user:{
            name:"",
            email:"",
            phone:"",
            address:""
        },
        message:"",
        deliveryStatus:"",
        paymentStatus:"",
        reasonForCancel:""
    });
    useEffect(()=>{
       if(userInfo&&userInfo.token){
        getOrder(orderId,userInfo.token,"business").then(res=>{
            setOrder(res.data);
           setOrderStatus(order.deliveryStatus)
            console.log(res.data)
        }).catch(err=>{
            console.log(err)
            navigate("*")
        })
       }
    },[order.deliveryStatus, orderId, navigate, userInfo])


    

    function handleOrderStatusSubmit(){
        setLoading(true)
        if(order.deliveryStatus===orderStatus){
            setLoading(false)
            return toast.info("Delivery status still the same")
            }else if(!orderStatus){ 
                setLoading(false)
                return toast.info("Select a valid delivery status!")
            }else if(orderStatus==="Cancelled"&&reason.length===0){
                setLoading(false)
                return toast.info("Enter reason for cancelling order.")
            }
            else{
                updateOrderStatus(userInfo.token,reason?{orderId:order._id,status:orderStatus,reasonForCancel:reason}:{orderId:order._id,status:orderStatus}).then(res=>{
                    console.log(res)
                    setLoading(false)
                    setOrder(res.data)
                    return toast.success("Status updated successfully!")
                }).catch(err=>{
                    setLoading(false)
                    console.log(err)
                    return toast.error(!err.response.data?err.message:err.response.data)
                })
            }
    }
    return ( 
        <>
        <BusinessDashboardLayout>
        <div className='order-details-container' ref={ref}>
        <h2>Order ID - #{orderId}</h2> <button type="button" className='print-order'  onClick={() => window.print()}>Print</button>
            <div className='order-details-content'>

<div className='order-details-section-1'>
<h3>CUSTOMER DETAILS</h3>
<h5><strong>Name:</strong> {order.user.name}</h5>
<h5><strong>Email:</strong> {order.user.email}</h5>
<h5><strong>Phone:</strong> {order.user.phone}</h5>
<h5><strong>Address</strong> {order.user.address}</h5>


<div className='affiliate-details'>
<h3>AFFILIATE DETAILS</h3>
<img src={affiliate}/> <h5><strong>Affiliate:</strong> {order.referrer?order.referrer:"NA"}</h5>
<p className='order__Status'>Status: {orderStatus}</p>
</div>

<br/>
<div style={{
    lineHeight:"10px"
}}>
    <h5><strong>Payment Status: </strong>{order.paymentStatus}</h5>
    <h5><strong>Delivery Status: </strong>{order.deliveryStatus}</h5>
</div>

{orderStatus==="Cancelled" && !order.reasonForCancel && order.reasonForCancel.length===0 ?<textarea type={"text"} value={reason} onChange={({target:{value}})=>setReason(value)}
 placeholder={"Enter reason for cancelling order. "} className="order-cancel-reason"/>:null}

<div>
    <button
    type='button'
    className='update-order'
    onClick={handleOrderStatusSubmit}
    disabled={loading}
    >
    {loading?"Please wait...":"Update Order"}
    </button>
</div>
</div>




    <div className='order-details-divider'></div>
<div className='order-details-section-2'>
<div className='order-details-section-2-first'>
<h3>Total: &#8358; {order.products.reduce((acc,item)=>acc+item.price*item.qty,0)}</h3>
<select  value={orderStatus}
onChange={({target:{value}}) => setOrderStatus(value)}>
<option>Delivery Status</option>
<option value={"Pending"}>Pending</option>
<option value={"Shipped"}>Shipped</option>
    <option value={"Delivered"}>Delivered</option>
    <option value={"Cancelled"}>Cancelled</option>
   
</select>
</div>


<div className='order-product-container'>
    

<div className='order-products'>
{
    order.products.map((product,idx)=>(
        <div className='order-product' key={idx}>

<img src={product.image_url} alt=""/>
<div className='order-product-detail'>
<h5 style={{fontWeight:"600"}}>{product.name}</h5>
<h5><strong>Qty</strong>: {product.qty}</h5>
<h5><strong>Cost</strong>: &#8358;{product.qty*product.price}</h5>
</div>

        </div>
    ))
}
</div>
</div>

</div>


            </div>
           </div>
        </BusinessDashboardLayout>
        </>
     );
}
 
export default OrderDetails;