import React, { useEffect } from 'react';
import PageOneComponent from '../../../components/storeConfig/PageOneComponent';
import StoreConfigLayout from '../../../components/storeConfig/StoreConfigLayout';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const StoreConfigPageOne = () => {
    const {userInfo} = useSelector(state=>state.userLogin);
    const navigate = useNavigate()
        // useEffect(()=>{
        //     if(userInfo){
        //         if(!userInfo?.storeConfigured){
        //             return navigate("/accounts/signin/all")
        //         }else{
        //             return navigate("/accounts/dashboard/business")
        //         }
        //     }
        // },[navigate,userInfo])
    return (
        <>
            <StoreConfigLayout title={"Business Info"} nextLink={"/add-product-1"}>
                <PageOneComponent />
            </StoreConfigLayout>
        </>
    );
}

export default StoreConfigPageOne;