import React from 'react';
import Brand from './Brand';
import './email_notification.styles.css';

const EmailNotification = () => {
    
    const name = 'Penciledge';
    return (
        <div className='email_container'>
            <div className='email_wrapper'>
                <div className='brand'>
                    <Brand />
                </div>
                <div className='note_wrapper'>
                    <h3>Hello {name} </h3>
                    <div className='span'>
                        <span>Your netgro account is almost ready</span>
                        <span>Click the button below to verify your account</span>
                    </div>
                    <button>
                        <span>Verify account</span>
                    </button>
                </div>
                <div className='bottom'>
                    <span>© 2022 netgro.co</span>
                    <span>A democratized way of selling and earning big </span>
                </div>
            </div>
        </div>
    )
}

export default EmailNotification;