import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import brandLogo from "../../../images/brand_logo.svg";
import './dashboard_sidebar.styles.css';
import { useDispatch } from "react-redux";
import { logout } from "../../../store/user";
import swal from "@sweetalert/with-react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineLogout } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { FiBarChart2 } from "react-icons/fi";
import  { BsShopWindow, BsWallet2 } from "react-icons/bs";




const DashboardSidebar = ({ handleChange, full, user }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [store, setStore] = useState(false);
    // useEffect(() => {
    //     if (window.innerWidth <= 500) setIsMobile(true) 
    // }, []);
    useEffect(() => {
        return () => {
            window.addEventListener("resize", () => {
                if (window.innerWidth <= 500) setIsMobile(true);
                else setIsMobile(false);
            });
        };
    });

    function handleLogout() {
        dispatch(logout());
        swal("Logged out Successfully", "", "success")
        navigate("/");
    }

    const [isMobile, setIsMobile] = useState(false);
    const Lists = [
        { title: 'Home', icon: <AiOutlineHome className="sidebar_navlist_img dashboard__Icon" />, to: "/accounts/individual/dashboard/home", className: 'sidebar_list', dropdown: null },
        { title: (user ? 'Store' : 'Brands'), icon: <BsShopWindow className="sidebar_navlist_img dashboard__Icon" />, to: '/accounts/individual/dashboard/brands', className: 'sidebar_list', dropdown: (user ? [{ title: 'Products', to: '#', className: 'store_dropdown', }, { title: 'Orders', to: '#', className: 'store_dropdown', }] : null) },
        { title: 'Wallet', icon: <BsWallet2 className="sidebar_navlist_img dashboard__Icon" />, to: '/accounts/individual/dashboard/wallet', className: 'sidebar_list', dropdown: null },
        { title: 'Analytics', icon: <FiBarChart2 className="sidebar_navlist_img dashboard__Icon" />, to: '/accounts/individual/dashboard/analytics', className: 'sidebar_list', dropdown: null },
        { title: 'Settings', icon: <IoSettingsOutline className="sidebar_navlist_img dashboard__Icon" />, to: '/accounts/individual/dashboard/settings', className: 'sidebar_list', dropdown: null }
    ]
    return (
        <div className="dash_nav_container" style={{ width: (isMobile ? '100vw' : (full ? '90px' : '200px')) }}>
            <div className={full ? "dash_nav_contain" : "dash_nav_contain_full"}>
                <div className="dash_nav_content">
                    <div className="dash_nav_top">

                        <div className="brand" onClick={handleChange}>
                            <img src={brandLogo} alt="" />
                            <span style={{ display: (full && (!isMobile) ? 'none' : 'block') }}>netgro</span>
                        </div>
                    </div>
                    <div className="dash_navlists">
                        <ul className="dash_nav_lists">
                            {Lists.map((list, idx) => (
                                <li key={idx}>
                                    {(list.dropdown == null)
                                        ? <Link to={list.to} className={list.className}>{list.icon}<span style={{ display: (full && !isMobile ? 'none' : 'block') }}>{list.title}</span></Link>
                                        : <div className="store_flex">
                                            <div onClick={() => setStore(!store)} className={list.className}>{list.icon}<span style={{ display: (full && !isMobile ? 'none' : 'block') }}>{list.title}</span></div>
                                            {store &&
                                                <ul className="dropdown_lists">
                                                    {list.dropdown.map((dropdowns, id) => (
                                                        <li key={id}><Link to={dropdowns.to} className={dropdowns.className}><span>{dropdowns.title}</span></Link></li>
                                                    ))}
                                                </ul>
                                            }
                                        </div>
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="dash_nav_bottom">
                        <div className="dash_nav_logout" onClick={handleLogout}><Link to="#" className="dash_logout"><AiOutlineLogout className="sidebar_navlist_img dashboard__Icon" /><span style={{ display: (full ? 'none' : 'block') }}>Logout</span></Link></div>
                    </div>

                </div>
            </div>
            <div className="slider" onClick={handleChange} style={{ transform: `rotate(${(full ? `0deg` : `180deg`)})` }}>
                {">"}
            </div>

        </div>
    )
}

export default DashboardSidebar;