import http from "./httpService";
// import jwtDecode from "jwt-decode";
import api from "./config";

import jwtDecode from "jwt-decode"


let apiEndpoint = `${api}/users`;

function headersFormData(token){
return{
    headers:{
        'Content-Type':"multipart/form-data",
        "auth-token":token
    }
    }
}
function headers(token){
return{
    headers:{
        "auth-token":token
    }
    }
}

export async function registerUser(user,accountType) {
    return await http.post(`${apiEndpoint}/${accountType}/signup`,user);
}

//public method
//gets all product for a particular store
export async function getAllProducts(storename){
    return await http.get(`${api}/store/get-products/${storename}`)
}

//login for both biz and affliate user
export async function loginUser(user){
    return await http.post(`${apiEndpoint}/signin`,user);
}

export async function configureUserStore(store,token){
    console.log(store)
    return await http.post(`${api}/store/configure`, store ,headersFormData(token))
    
}

export async function addProduct(product,productType,token){
    return await http.post(`${api}/store/add-products/${productType}`,product,headersFormData(token))
}

export async function setAffiliateRate(rate,token){
    return await http.post(`${api}/store/set-affiliate`,{"rate":Number(rate)}, headers(token))
}

//generate token for business
export async function generateToken(token){
return await http.get(`${api}/store/generate-token`,headers(token))
}

//verify newly created account
export async function verrifyAccount(token){
    return await http.get(`${apiEndpoint}/verify-account/${token}`)
}

export async function getUserStoreDetails(token){
    return await http.get(`${api}/store/get-store-details`,headers(token))
}

//edit store logo for biz user
export async function editUserStoreLogo(formData,token,storeId){
    return await http.put(`${api}/store/edit-store-image/${storeId}`,formData,headersFormData(token))
}

//edit store info for biz user
export async function editUserStoreInfo(data,storeId,token){
    return await http.put(`${api}/store/edit-store/${storeId}`,data,headers(token))
}

//get user's profile
export async function getUserProfile(token,accountType){
    return await http.get(`${apiEndpoint}/${accountType}/profile`,headers(token))
}

//update both bisness and individual user's account
export async function updatePayoutAccount(token,accountType,data){
    return await http.put(`${apiEndpoint}/${accountType}/payout-account`,data,headers(token));
}


//return business store products
export async function getUserProducts(token){
    return await http.get(`${api}/store/get-user-products`,headers(token))
}

//edits users details
export async function editUserDetails(token,accountType,data){
    return await http.put(`${apiEndpoint}/${accountType}/profile`,data,headers(token))
}
export async function getUserProduct(business,productId){
    return await http.get(`${api}/store/get-product/${business}/${productId}`)
}


export async function editProductFile(token,productType,productId,formData){
    return await http.put(`${api}/store/edit-product-image/${productType}/${productId}`,formData,headersFormData(token))
}

export async function editProductDetails(token,productId,data){
    return await http.put(`${api}/store/edit-product/${productId}`, data, headers(token))
}

export async function deleteProduct(token,productId){
    return await http.delete(`${api}/store/delete-product/${productId}`, headers(token))
}

export async function placeOrder(data){
    return await http.post(`${apiEndpoint}/place-order`,data);
}

export async function getStoreOrders(token){
    return await http.get(`${apiEndpoint}/get-orders`,headers(token));
}

export async function getOrder(orderId,token,accountType){
    return await http.get(`${apiEndpoint}/get-order/${accountType}/${orderId}`,headers(token))
}

export async function validateReferrer(username){
    return await http.get(`${apiEndpoint}/validate-referrer/${username}`)
}

export async function getReferrerOrders(token,username){
    return await http.get(`${apiEndpoint}/get-orders-referrer/${username}`,headers(token));
}

export async function editIndivUserImage(token,formData){
    return await http.put(`${apiEndpoint}/individual/profile-image`,formData,headersFormData(token))
}

export async function getProductByName(username,productName){
    return await http.get(`${api}/store/product/${username}/${productName}`);
}

export async function getAllBanks(accountType,country,token){
return await http.get(`${apiEndpoint}/${accountType}/get-banks/${country}`,headers(token))
}

export async function validateBankAccountDetails(accountType,data,token){
    return await http.post(`${apiEndpoint}/${accountType}/validate-bank-account-details`,data,headers(token))
    }

    export async function getAllStores(token){
        return await http.get(`${api}/store/get-all-stores`,headers(token))
    }

    export async function getAnalytics (token,accountType) {
        return await http.get(`${api}/users/${accountType}/get-analytics`, headers(token));
    }

    export async function updateOrderStatus(token,data){
return await http.put(`${api}/users/update-order-status`,data,headers(token))
    }

    export async function getCustomerOrder(orderId){
        return await http.get(`${api}/users/get-customer-order/${orderId}`)
    }

    export async function downloadProduct(orderId,productId){
return await http.get(`${api}/users/download-file/${orderId}/${productId}`);
    }

    export async function getAnalyticsForCustomDate(token, data,accountType) {
        return await http.post(`${api}/users/${accountType}/get-analytics`, data ,headers(token));
    }


    export async function getTransactions(token, accountType) {
        return await http.get(`${apiEndpoint}/wallet/${accountType}/get-transactions`, headers(token));
    }
    
    export async function withdrawEarning(token, accountType, data) {
        return await http.post(`${apiEndpoint}/wallet/${accountType}/withdraw-earning`, data, headers(token));
    }

    export async function confirmOrder(orderId){
        return await http.put(`${api}/users/confirm-order/${orderId}`)
    }
    
    export async function giveOrderFeedback(orderId,feedback){
         return await http.put(`${api}/users/give-order-feedback/${orderId}`,feedback)
    }

    export async function getStore(storeName,token){
        return await http.get(`${api}/store/get-store/${storeName}`,headers(token))
    }
export function getCurrentUser(token){
try{
    if(token) return {...jwtDecode(token),token}
    else return null;
  }
  catch(ex){
    console.log(ex)
  }
  }
