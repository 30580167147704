import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div style={{textAlign:"center",margin:"100px 0 200px"}}>
        <h1 style={{fontSize:"50px"}}>
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </h1>
         <h4>Sorry, there is nothing here</h4>
         <br/>
        <Link to='/'><i className='fa fa-arrow-left'></i>{" "}Go Back Home</Link>
        </div>
      );
}
 
export default NotFoundPage;