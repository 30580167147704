//cart actions
const CART_ADD_ITEM="CART_ADD_ITEM";
const CART_REMOVE_ITEM="CART_REMOVE_ITEM";
export const CLEAR_CART="CLEAR_CART";
const CART_SAVE_SHIPPING_ADDRESS="CART_SAVE_SHIPPING_ADDRESS";


//cart reducers
export const cartReducer = (state={cartItems:[], shippingAddress:{}}, action)=>{
    switch(action.type){
        case CART_ADD_ITEM:
            const item = action.payload;
    
                
            const existItem = state.cartItems.find(cartItem => cartItem._id === item._id)
            if(existItem){
                return{
                    ...state,
                    cartItems:state.cartItems.map(cartItem => cartItem._id===existItem._id?item:cartItem)
                }
            }else{
                    return{ 
                        ...state,
                        cartItems:[...state.cartItems, item]
                    }
            }
    
            case CART_REMOVE_ITEM:
    return{
        ...state,
        cartItems:state.cartItems.filter(cartItem=>cartItem._id!==action.payload)
    }

    case CLEAR_CART:
        return{
            ...state,
            cartItems:[]
        }
    
    case CART_SAVE_SHIPPING_ADDRESS:
        return{
            ...state,
            shippingAddress:action.payload
            }

            default:
                return state;   
    }
    }

//cart action creators
 export function storeData(key, value){
    localStorage.setItem(key,JSON.stringify(value))
}

// export const addToCart = (product,qty) => async(dispatch,getState)=>{
export const addToCart = (product,qty) => (dispatch,getState)=>{
// const {data} = await axios.get(`${apiEndPoint}/api/products/${id}`);
 dispatch({
     type:CART_ADD_ITEM,
     payload:{
         _id:product._id,
         name:product.name,
         image_url:product.image_url,
         price:product.price,
         numberInStock:product.numberInStock,
         type:product.type,
         qty
     }
 })

 sessionStorage.setItem("netgroCartItems",JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = _id => (dispatch,getState) =>{
    dispatch({
        type:CART_REMOVE_ITEM,
        payload:_id
    })
    sessionStorage.setItem("netgroCartItems", JSON.stringify(getState().cart.cartItems))
}

export const removeAllCartItems = ()=> (dispatch) =>{
dispatch({
    type:CLEAR_CART
})
sessionStorage.setItem("netgroCartItems",JSON.stringify([]));
}
