import React, { useEffect, useState } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import DeliveryConfirmationLayout from './Layout';
import {getCustomerOrder, giveOrderFeedback} from "../../services/userService";
import { toast } from 'react-toastify';

function DeliveryFeedback(props) {
    const [order,setOrder] = useState({
        _id:"",
        user:{name:""},
    });
    
    const [feedback,setFeedback] = useState("")
    const [loading,setLoading] = useState(false)
const navigate = useNavigate();
    const {orderId} = useParams()
    useEffect(()=>{
getCustomerOrder(orderId).then(res=>{
console.log(res);
setOrder(res.data)
}).catch(err=>{
    console.log(err)
    navigate("*")
})
    },[])

    function handleBtnClick(){
        setLoading(true)
if(!feedback) return toast.info("Pls enter your feed back.")
giveOrderFeedback(order._id,{feedback}).then(res=>{
    toast.success(res.data);
    setFeedback("")
    setLoading(false)
}).catch(err=>{
    setLoading(false)
    toast.error(!err.response.data?err.message:err.response.data)
})
    }
    return (
        <DeliveryConfirmationLayout name={order.user.name}>
  <div className='delivery-feedback'>
            <h3>Feedback</h3>
            <textarea
            value={feedback}
            onChange={({target:{value}})=>setFeedback(value)}
             draggable={false} placeholder={"Provide us some feedback to help provide better service."}/>
            <button
            disabled={loading}
             onClick={handleBtnClick}>{loading?"Please wait...":"Submit"}</button>
            </div>
        </DeliveryConfirmationLayout>
      
    );
}

export default DeliveryFeedback;    