import React from 'react';
import "./Input.css";
import "./input_res.css";

const Input = ({type,name,value, handleChange, placeholder,maxLength,disabled,minLength}) => {

    return (
        <div className='site_input'>
        <input
          type={type}
          name={name}
          id={name}
          disabled={disabled}
          value={value}
          minLength={minLength}
        maxLength={maxLength}
          className={name}
          placeholder={placeholder}
                onChange={handleChange}
                required
        />
        <br/>
      </div>
    );
}
 
export default Input;