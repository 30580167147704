import React,{useState,useEffect,useRef} from 'react';
import plastic_bottle from '../../../images/plastic bottle.png';
import beauty_product from '../../../images/beauty_product.png';
import dots from '../../../images/ellipsis.svg';
import bi_cardlists from '../../../images/bi_card-list.svg';
import boxed_bars from '../../../images/boxed_bars.svg';
import { Link } from 'react-router-dom';
import './all_products.styles.css';

const AllProducts = () => {
    const [dropdown, setDropdown] = useState(null);
    
    const products = [
        {name: 'Cow crown milk (1litter)', img: plastic_bottle, dropdown: false, price: 'N2500'},
        {name: 'Cow crown milk (1litter)', img: beauty_product, dropdown: false, price: 'N2500'},
        {name: 'Cow crown milk (1litter)', img: plastic_bottle, dropdown: false, price: 'N2500'},
        {name: 'Cow crown milk (1litter)', img: beauty_product, dropdown: false, price: 'N2500'}
    ]
    
    return (
        <div className='products_container'>
            <div className='products_wrapper'>
                <div className='products_top'>
                    <span>All Products</span>
                    <div className='addnew_button'><Link to='/accounts/user/products/new' style={{ width: '180px', height: '50px', paddingTop: '30px', textAlign: 'center',color: 'white'}}>Add New</Link></div>
                </div>
                <div className='products_main'>
                    <div className='products_content'>
                        <div className='products_sort'>
                            <div className='products_sort_content'>
                                <img src={boxed_bars} />
                                <img src={bi_cardlists} />
                                <input placeholder='Search products'/>
                            </div>
                        </div>
                        <div className='allproducts'>
                            {products.map((product, id) => (
                                <div className='product' key={id}>
                                    <div className='dots' onClick={()=> setDropdown((dropdown==id)?null:id)}><img src={dots} />
                                       {(dropdown==id) && 
                                        <div className='product_dropdown'>
                                            <span>Edit</span> 
                                            <span>Delete</span> 
                                            <span>Share</span>                         
                                        </div>
                                       }
                                    </div>
                                    
                                    <div className='product_img'><img src={product.img} className='img' /></div>
                                    <div className='product_bottom'>
                                        <span>{product.name}</span>
                                        <span>{product.price}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllProducts;