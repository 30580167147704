import React from 'react';
import Input from './input';
import circle_plus from '../../../images/plus_circle.svg';
import img_logo from '../../../images/upload_img.svg';
import './settings_config.styles.css'

const Profile = () => {

    return (
        <div className='settings_config_container'>
            
            <h3>Profile</h3>
            <div className='settings_config_wrapper'>
                <div className='settings_config_content'>
                    <div className='settings_config_header'>
                        <div className='img_logo'>
                            <img src={img_logo} />
                            <span>Logo</span>
                        </div>
                    </div>
                    <div className='settings_config_body'>
                        <Input placeholder={'Store Description'} />
                        <Input placeholder={'Store Address'} />
                    </div>
                    <div className='settings_config_base'>
                        <div className='socials'><img src={circle_plus} /><span>Instagram</span></div>
                        <div className='socials'><img src={circle_plus} /><span>Facebook</span></div>
                        <div className='socials'><img src={circle_plus} /><span>Twitter</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;