import React, { useState, useEffect, useRef } from "react";
import DashboardSidebar from "./dashboard_sidebar";
import { Routes, Route, Link } from 'react-router-dom';
import user_avatar from '../../images/profile_user.svg';
import notification_bell from '../../images/notification_bell.svg';
import AllProducts from './products/all_products';
import Wallet from './wallet/wallet';
import user from '../../images/beauty_product.png';
import NewProducts from './products/new_products';
import Settings from './settings/settings';
// import "./affiliate_dashboard.styles.css";
import DashboardHome from './dashboard_home';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleUser } from '@fortawesome/free-solid-svg-icons';


// Modal.setAppElement("#root");

const UserDashboard = () => {

    const [full, setFull] = useState(true);
    const [modal, setModal] = useState(true)
    const handleChange = () => {
        setFull(!full);
    }
    const handle = (ok) => {
        if(ok) return;
        else {
            setModal(!modal)
        }
    }
    useEffect(() => {
        return () => {
            window.addEventListener("resize", () => {
              if (window.innerWidth <= 500) setIsMobile(true);
              else setIsMobile(false);
            });
        };
    });
    const note = [
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' },
        {img: user, txt: 'You bought a beauty products', when: '20 July 2022 - 02:56 PM' }
    ]
    
    const [isMobile, setIsMobile] = useState(false);
    const [notification, setNotification] = useState(false)

    const url = 'https:netgro.co/ladybstore';
    return (
        <div className="affiliate_container">
            {/* <div className="side_bar_wrapper" style={{width: (full ? '80px' : '250px')}}> */}
            <DashboardSidebar handleChange={handleChange} full={full} user={true} />
            <div className={isMobile ? "affiliate_wrapper" : (!full ? "affiliate_wrapper_full":"affiliate_wrapper")}>
                
                {notification && (
                    <div className="notifications_list">
                        
                        <ul className="notification_lists">
                           
                            {note.map((val,idx) => (
                                <li key={idx} className="notification_list">
                                    <img src={val.img} />
                                    <div className="spans">
                                        <span className="txt">{val.txt}</span>
                                        <span className="date">{val.when}</span>
                                    </div>
                                </li>
                            ))}
                            
                        </ul>
                        <span><Link to="#" style={{color: '#a0aec0'}}>See all notifications</Link></span>
                    </div>
                )}
                <div className="affiliate_content">
                    <div className="dash_top">
                        <h3>Hi LadyB Store      👋</h3>
                        
                        <div className="right_top">
                            {/* <img src={notification_bell} onClick={() => setNotification(!notification)} className='img' /> */}
                            <div onClick={() => setNotification(!notification)} className='img'>
                                <svg width="30" height="30" color="green" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" style={{fill: "green"}} d="M8.83333 3.91738V1.50004C8.83333 0.856041 9.356 0.333374 10 0.333374C10.6428 0.333374 11.1667 0.856041 11.1667 1.50004V3.91738C12.9003 4.16704 14.5208 4.97204 15.7738 6.22504C17.3057 7.75687 18.1667 9.8347 18.1667 12V16.3914L19.1105 18.279C19.562 19.1832 19.5142 20.2565 18.9822 21.1164C18.4513 21.9762 17.5122 22.5 16.5018 22.5H11.1667C11.1667 23.144 10.6428 23.6667 10 23.6667C9.356 23.6667 8.83333 23.144 8.83333 22.5H3.49817C2.48667 22.5 1.54752 21.9762 1.01669 21.1164C0.484686 20.2565 0.436843 19.1832 0.889509 18.279L1.83333 16.3914V12C1.83333 9.8347 2.69319 7.75687 4.22502 6.22504C5.47919 4.97204 7.0985 4.16704 8.83333 3.91738ZM10 6.1667C8.45183 6.1667 6.96902 6.78154 5.87469 7.87587C4.78035 8.96904 4.16666 10.453 4.16666 12V16.6667C4.16666 16.8475 4.12351 17.026 4.04301 17.1882C4.04301 17.1882 3.52384 18.2265 2.9755 19.322C2.88567 19.5029 2.89501 19.7187 3.00117 19.8902C3.10734 20.0617 3.29517 20.1667 3.49817 20.1667H16.5018C16.7037 20.1667 16.8915 20.0617 16.9977 19.8902C17.1038 19.7187 17.1132 19.5029 17.0234 19.322C16.475 18.2265 15.9558 17.1882 15.9558 17.1882C15.8753 17.026 15.8333 16.8475 15.8333 16.6667V12C15.8333 10.453 15.2185 8.96904 14.1242 7.87587C13.0298 6.78154 11.547 6.1667 10 6.1667Z" fill="#1362FC"/>
                                </svg>
                            </div>
			 
                            <img src={user_avatar} />
                        </div>
                    </div>
                    
                    <Routes>
                        <Route path='/' element={<DashboardHome />} />
                        <Route path='products/*' element={<AllProducts />} />
                        <Route path='products/new' element={<NewProducts />} />
                        <Route path='wallet' element={<Wallet />} />
                        <Route path='settings/*' element={<Settings />} />
                    </Routes>
                    
                </div>
                
            </div>
            
            {/* contentLabel="Example Modal" */}
            {/* <Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                style={customStyles}
                // className={"Modal"}
            >
                <div className="modal_wrapper">
                    <div className="modal_content">
                        <h1>Payout</h1>
                        <span className="med_txt">2% transaction fee will be deducted for every successful payout </span>
                        <span className="big_txt">₦200.7k</span>
                        <div className="modal_input"><input></input></div>
                        <div className="modal_input"><input></input></div>
                        <div className="modal_base">
                            <Link to="#" className="add_account">Request Account</Link>
                        </div>
                    </div>
                </div>
            </Modal> */}
        </div>
    )
}

export default UserDashboard;