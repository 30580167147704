import React from 'react';
import { useLocation } from 'react-router-dom';

const Progress = () => {
    const loc1 = "/configure-store-1";
    const loc2 = "/add-product-1";
    const loc3  = "/set-affiliate-program-1";

    const location = useLocation()
   
    return ( 
        <>
        {location.pathname===loc1&&<div className='progress'>
<div className='step' style={{color:"white", backgroundColor:"#34A853"}}>
<span>1</span>
</div>
<div className='divider'  style={{ backgroundColor:"#34A853"}}></div>
<div className='step' ><span  >2</span></div>
<div className='divider' ></div>
<div className='step'><span>3</span></div></div>}

{location.pathname===loc2&&<div className='progress'>
<div className='step' style={{color:"white", backgroundColor:"#34A853"}}>
<span>1</span>
</div>
<div className='divider'  style={{ backgroundColor:"#34A853"}}></div>
<div className='step'  style={{color:"white", backgroundColor:"#34A853"}}><span>2</span></div>
<div className='divider' ></div>
<div className='step' ><span>3</span></div></div>}

{location.pathname===loc3&&<div className='progress'>
<div className='step' style={{color:"white", backgroundColor:"#34A853"}}>
<span>1</span>
</div>
<div className='divider'  style={{ backgroundColor:"#34A853"}}></div>
<div className='step'  style={{color:"white", backgroundColor:"#34A853"}}><span>2</span></div>
<div className='divider' style={{ backgroundColor:"#34A853"}} ></div>
<div className='step' style={{color:"white", backgroundColor:"#34A853"}}><span>3</span></div></div>}
       
</>

     );
}
 
export default Progress;