import React,{useContext} from 'react';
import { BusinUserContext } from '../../context/BusinUserContext';

const PageThreeComponent = () => {
    const {rate,setRate} = useContext(BusinUserContext);
    return (  
        <>
            <div className='affiliate-program'>
<h5>Your affiliate program is unique to your business, 
you set the % commision you want to give out. 
Minimum is 10%</h5>
<input type={"number"} placeholder="Affiliate rate" onChange={({target:{value}})=>setRate(value)}/>
            </div>
        </>
    );
}
 
export default PageThreeComponent;