import React, { useContext, useEffect, useState } from 'react';
import {useSelector,useDispatch} from "react-redux"
import cartEmpty from "../images/cartEmpty.png";
import cartFull from "../images/cartFull.png";
import CartItems from './CartItems';
import {v1} from "uuid";
import { CartContext } from '../context/CartContext';
import Checkout from './Checkout';
import { GrFormClose } from "react-icons/gr";
import OrderDetails from './OrderDetails';

const Cart = ({toggleCart,store,owner}) => {

  const {cartItems} = useSelector(state=>state.cart);
  console.log(cartItems)

  const {showCheckout} = useContext(CartContext);
  
    return ( 
      <>
      <div className='cart-items-container'>
      <div  className="cart-container">

      {showCheckout&&<Checkout cartItems={cartItems} store={store} owner={owner} toggleCart={toggleCart}/>}
      {
  !showCheckout&&
  <div>
      <GrFormClose className="close__Icon" onClick={toggleCart} style={{width:"30px",height:"30px",cursor:"pointer"}}/>
      {cartItems.length >=1 ?  <div className='full-section'>
        <span ><strong>Total Price:</strong>  &#8358;{cartItems.reduce((acc,item)=>acc+item.price*item.qty,0)}</span>
      </div>:<img src={cartEmpty} alt="" className='empty'/>}
<br/>
{cartItems.length >=1 ?
<>
<hr style={{marginTop:"100px"}}/>
  <CartItems cartItems={cartItems} key={v1()}/>
  </>
:
 <>
 <div className='empty-cart'>
  <span >Your cart is empty</span>
 </div>
</>
}
<button onClick={toggleCart} className="last-btn"style={{backgroundColor:cartItems.length >=1 ? "white":"#34A853", color:cartItems.length >=1 ?"black":"white", border:cartItems.length >=1 ?"1px solid #34A853":"none", top:cartItems.length>=1?"auto":'160px',MarginBottom:cartItems.length>=1&&"100px"}}>{cartItems.length >=1 ?"Add more items" : 'Start shopping'}</button>

      </div>}
      </div>
      <br/>
      </div>
        </>
     );
}
 
export default Cart;