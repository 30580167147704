import React from "react";
import "./Transaction.css";


const Transaction = () => {

    return (
        <div className="transaction_container">
            <div className="transactions_content">
                <h1>Transactions</h1>
                <div className="transactions">
                    <div className="skeleton"></div>
                    <div className="skeleton"></div>
                    <div className="skeleton"></div>
                </div>
            </div>
        </div>
    )
}

export default Transaction;