import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Brand from "../common/Brand";
import { Link } from "react-router-dom";
import { navbarData } from "./navbarData";

const Navbar = () => {
  useEffect(() => {
    return () => {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 982) setIsMobile(false);
      });
    };
  });

  function scrollToview(id){
    const element = document.getElementById(id);
    if(id) return element.scrollIntoView();
    else return;
  }

  const [isMobile, setIsMobile] = useState(false);
  return (
    <>
      <div className="logo">
        <Brand />
      </div>
      <nav className="navbar" style={{background:isMobile&&"white"}}>
        <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
      {navbarData.map((data, idx) => (
        <li key={idx} className={data.cName}>
          <Link to={data.path} onClick={()=>scrollToview(data.id)}>{data.title}</Link>
        </li>
      ))}
        </ul>

        <button
          className="mobile-menu-icon"
          onClick={() => setIsMobile(!isMobile)}
        >
          {isMobile ? (
            <i className="fa fa-times" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-bars" aria-hidden="true"></i>
          )}
        </button>
      </nav>
    </>
  );
};

export default Navbar;
