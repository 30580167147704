import { AiOutlineInfoCircle } from "react-icons/ai";
import { TbCurrencyNaira } from "react-icons/tb";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import AnalyticsCard from "../../../../components/common/analyticsCard/AnalyticsCard";
import BusinessDashboardLayout from "../../../../components/dashboard/business/BusinessDashboardLayout";

import "./walletNewStyle.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import AddAccountModal from "./addAccountModal/AddAccountModal";
import PayoutModal from "./payoutModal/PayoutModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTransactions } from "../../../../services/userService";
import { BusinUserContext } from "../../../../context/BusinUserContext";
import { formatDateAndTime } from "../../../../utils";
import Modal from 'react-modal';



Modal.setAppElement("#root");


const WalletNew = () => {
    const payoutModalRef = useRef(null);
    const updateAccountModalRef = useRef(null);
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [showUpdateAccountModal, setShowUpdateAccountModal] = useState(false);
    const navigate = useNavigate();
    const { userInfo } = useSelector(state => state.userLogin);
    const [transactions, setTransactions] = useState([]);
    const { user } = useContext(BusinUserContext);

    useEffect(() => {

        if (!userInfo) return;

        getTransactions(userInfo.token, userInfo.accountType).then(res => {
            setTransactions(res.data);
        }).catch(err => {
            console.log(err);
        })

    }, [userInfo])

    const [modal, setModal] = useState(false);
    const [screen, setScreen] = useState(false);
    useEffect(() => {
        return () => {
            window.addEventListener("resize", () => {
              if (window.innerWidth <= 1400) setScreen(true);
              else setScreen(false);
            });
        };
    }, []);
    const customStyles = {
        content : {
            top: "50%",
            width: screen ? "70%" : "auto",
            bottom: "auto",
            left: "50%",
            right: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "20px"
        },
        overlay: {
            background: "rgba(0, 0, 0, 0.4)",
            position: "fixed",
            zIndex: "1000000"
        },
    };

    return <>
        <AddAccountModal 
            modalRef={updateAccountModalRef}
            isModalOpen={showUpdateAccountModal}
            closeModal={() => setShowUpdateAccountModal(false)}
        />

        <PayoutModal 
            modalRef={payoutModalRef}
            isModalOpen={showPayoutModal}
            closeModal={() => setShowPayoutModal(false)}
            availableBal={user.wallet.activeBal}
            updateTransactions={setTransactions}
        />

        <BusinessDashboardLayout>
            <div className="business__Analytics__Container">
                <div className="business__Wallet__Intro">
                    <h3 className="title__Text">Wallet</h3>
                    {/* <button className="update__Btn" onClick={() => setShowUpdateAccountModal(true)}>
                        <FaPlus className="update__Account__Icon" />
                        <span>Update Account</span>
                    </button> */}
                </div>
                <section className="business__Analytics__Intro" role={"main"}>
                    <AnalyticsCard 
                        analyticTitle={'Total Income'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={user.wallet.overallEarnings}
                        hasAdditionalInfo={true}
                        additionalInfo={"Affiliate Account"}
                        additionalInfoIcon={<AiOutlineInfoCircle />}
                    />
                    <AnalyticsCard
                        analyticTitle={'Available Balance'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={user.wallet.activeBal}
                        hasAdditionalInfo={true}
                        additionalInfo={"Cash Out"}
                        additionalInfoIcon={<MdOutlineKeyboardArrowRight />}
                        handleCardItemClick={() => setShowPayoutModal(true)}
                    />

<AnalyticsCard 
                        analyticTitle={'My bank account'}
                        // analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        dataInfo={true}
                        analyticValue={user.accountDetails.accountName?user.accountDetails.accountName:"_____"}
                        analyticValue2={user.accountDetails.bankName?user.accountDetails.bankName:"_____"}
                        analyticValue3={user.accountDetails.accountNumber?user.accountDetails.accountNumber:"_____"}
                        hasAdditionalInfo={true}
                        additionalInfo={"Update Account"}
                        additionalInfoIcon={<MdOutlineKeyboardArrowRight />}
                        handleCardItemClick={() => navigate('/accounts/business/dashboard/settings/payout', { state: { from: "/accounts/business/dashboard/wallet"}} )}
                    />
                </section>

                <section className="business__Wallet__Transactions order-container" role={"complementary"}>
                    <h3>Transactions</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Transaction Id</th>
                                <th>Amount</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Referrer</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                React.Children.toArray(transactions.map(transaction => {
                                    return <tr onClick={() => console.log(transaction._id)}>
                                        <td>{formatDateAndTime(transaction.createdAt)}</td>
                                        <td>{transaction._id}</td>
                                        <td>{transaction.amount}</td>
                                        <td>{transaction.type}</td>
                                        <td>{transaction.description}</td>
                                        <td>{transaction.referrer ? transaction.referrer : "N/A"}</td>
                                    </tr>
                                }))
                            }
                        </tbody>
                    </table>
                </section>
            </div>
{/* 
            <Modal
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                style={customStyles}
            >

            </Modal> */}
        </BusinessDashboardLayout>
    </>
}

export default WalletNew;
