import React, { useState,useEffect } from "react";
import "./index.css"; 
import "./index_res.css";
import {  useNavigate } from "react-router-dom";
import proceed from "../../images/proceed.svg";
import Card from "./Card";
import Banner from "../../components/common/Banner";
import Brand from "../../components/common/Brand";
import PageTitle from "../../components/PageTitle";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";



const RegistrationPage = () => {

    useEffect(() => {
      sessionStorage.removeItem("usr_choice");
    }, []); 
  
  const [individual] = useState([
    {
      title: "Individual",
      detail: "Earn refferal income Selling for businesses.",
      class: "land_indiv",
      bg: "#34A853",
    
    
    },
  ]);
  const [business] = useState([
    {
      title: "Business",
      detail: "Sell your products or services - digital or physical goods.",
      class: "land_bus",
      bg: "#EA4335",
    },
  ]);


  const [usrOpt, setUsrOpt] = useState({
    indiv: !false,
    busin: false,
  });

  const handleChange = ({ target }) => {
  setUsrOpt({[target.name]:target.checked });
  }; 

  let navigate = useNavigate();
  
  // function storeUsrOption(name) {
  //  sessionStorage.setItem("usr_choice", JSON.stringify({ method: name }));
  // }

  function handleUsrNav() {
       if(indiv) navigate("/accounts/individual/signup");
      else if(busin)navigate("/accounts/business/signup");
  }


    const { indiv, busin } = usrOpt;
  return (
    <>
      <PageTitle title={"Netgro | Sign Up | Select your preferred account"} />
      <div className="landing_container">
        <div className="section_1">
          <div className="section_1_content">
            <Brand header={"Sign up as ?"} />

            {individual.map((content, idx) => (
              <Card key={idx} content={content} id={1}>
                <input
                  type="radio"
                  name={"indiv"}
                  id={`${content.class}`}
                  checked={indiv || !true}
                  // onClick={(e) => storeUsrOption(e.target.name)}
                  onChange={handleChange}
                />
              </Card>
            ))}

            {business.map((content, idx) => (
              <Card key={idx} content={content} id={2}>
                <input
                  type="radio"
                  name={"busin"}
                  id={`${content.class}`}
                  checked={busin || !true}
                  onChange={handleChange}
                  // onClick={(e) => storeUsrOption(e.target.name)}
                />
              </Card>
            ))}

            

            

            <button type="button" className="proceed" onClick={handleUsrNav}>
              <span> Proceed </span>
              <img src={proceed} alt="proceed" className="proceed_img" />
            </button>

            {/* style={{position:"relative", bottom: "-50px", left:"-330px"}} */}
            <span id="proceed">
              Already have an account?{" "}
              <Link to="/accounts/signin/all">Sign in</Link>
            </span>
          </div>
        </div>

        <Banner />
      </div>
    </>
  );    
};

export default RegistrationPage;
