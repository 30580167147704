import React, { useContext, useEffect, useRef, useState } from 'react';
import '../dashboard_home.styles.css';
import { TbCurrencyNaira } from "react-icons/tb";
import filter from '../../../images/filter.svg';
import file from '../../../images/file.svg';
import file1 from '../../../images/file1.svg';
import home from '../../../images/home.svg';
import shopCart from '../../../images/shopping_cart.svg';
import lockedCash from '../../../images/locked_cash.svg';
import double from '../../../images/double.svg';
import RecentSales from './recent_sales';
import BusinessDashboardLayout from "../../../components/dashboard/business/BusinessDashboardLayout";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getAllProducts, getStoreOrders, getUserProduct, getUserProducts } from '../../../services/userService';
import { BusinUserContext } from '../../../context/BusinUserContext'; 
import {IoMdCopy} from "react-icons/io"
import { toast } from 'react-toastify';
import {BsFillBagCheckFill} from "react-icons/bs"


const BusinessDashboardHome = () => {
    
  

    //  const [sales, setSales] = useState([]);
 const {userInfo} = useSelector(state=>state.userLogin);
    const [userUrl,setUserUrl] = useState("")
    const [product,setProduct] = useState([])
    const navigate = useNavigate()
   const{orders:sales,user} = useContext(BusinUserContext);

    useEffect(()=>{
        if (!userInfo) return
        if(userInfo){
            setUserUrl(userInfo.username)
        }

        if(userInfo.token){
            getUserProducts(userInfo.token).then(res=>{
                setProduct(res.data)
                        }).then(err=>console.log(err))
        }
    },[userInfo])

    const url = `https:netgro.co/${userUrl}`;

    const data = [
        { id: 1,up_txt: "Total Sales", mid_txt: sales.length, bottom_txt: "View >", img: shopCart, url:"/accounts/business/dashboard/orders"},
        { id: 2,up_txt: "Total Income", mid_txt: `${user.wallet.overallEarnings}`, bottom_txt: "Cash out >", img: lockedCash },
        { id:3,up_txt: "Your Products", mid_txt: product.length, bottom_txt: "View >", img: <BsFillBagCheckFill style={{width:'20px',height:'20px',color:"#283A57"}}/>, url:"/accounts/business/dashboard/products"}
    ]


    function copyUrlToClipboard() {
        navigator.clipboard.writeText(url);
        toast.success("Successfully copied to clipboard",{position:'top-center'})
        console.log(url)
    }
    return (
        <BusinessDashboardLayout>
        <div className='dash_home_container'>
            <div className="dash_mid">
                <div className="dash_mid_top">
                    <span>Your Dashboard</span>
                    <div className="url">
                        <div className="url_content">
                            <div className="url_img">
                             <span><IoMdCopy className='copy-icon' onClick={copyUrlToClipboard}/></span>
                            </div>
                            <span style={{fontSize:"1rem",fontWeight:400}}>Copy store url</span>
                        </div>
                    </div>
                </div>
                <div className="dash_mid_base">
                    <div className="dash_mid_overflow">
                        {data.map((arr, idx) => (
                            <div key={idx} className="dash_mids_wrapper">
                                <div className="dash_mids_content">
                                    <div className="dash_midd_top">{arr.up_txt}</div>
                                    <div className="dash_mid_mid">
                                    {arr.id===2?
                                  <span>
                                  &#8358;
                                    {arr.mid_txt}
                                  </span>
                                   : arr.mid_txt}
                                    </div>
                                    <div className="dash_mid_bottom">
                                        <span onClick={()=>navigate(arr.url?arr.url:"#")}>{arr.bottom_txt}</span>
                                        <div className="dash_mid_img">
                                        {arr.id===3? arr.img:
                                            <img src={arr.img} alt="" />
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* </div> */}
                <div className="dash_base">
                    <div className="dash_base_top">
                        <span>Recent Sales</span>
                        <br/>
                        {/* <div className="filter">
                            <span>Filter</span>
                            <div className="filter_img" ><img src={filter} alt="" /></div>
                        </div> */}
                    </div>
                    <div className="dash_base_main">
                        {/* <RecentSales sales={{sales:sales.slice(0, 3)}}/> */}
                        <RecentSales sales={sales}/>
                    </div>

                    {/* </div> */}
                </div>
            </div>
        </div>
        </BusinessDashboardLayout>
    )
}

export default BusinessDashboardHome;