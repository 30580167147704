import React,{useContext,useState} from 'react';
import './new_products.styles.css';
//import upload from '../../../images/cloud-upload.svg'
import BusinessDashboardLayout from "../../../../components/dashboard/business/BusinessDashboardLayout";
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { toast } from 'react-toastify';
import { addProduct } from '../../../../services/userService';
import swal from '@sweetalert/with-react';
import { Link, useNavigate } from 'react-router-dom';

const NewProducts = () => {
    const {   productType,setProductType,
        setProduct,product} = useContext(BusinUserContext);
const [filename,setFilename] = useState("Upload product image (500px by 500px recommended)")
const [loading,setLoading] = useState(false);
const navigate = useNavigate();

function handleAddProduct(e) {
    e.preventDefault()
    setLoading(true)
    const token = localStorage.getItem("netgroAuthToken")
    if (!product.image || !product.name || !product.description || !product.price) {
        setLoading(false)
            return toast.warn("Pls fill all fields below to proceed.")
    } else {
            addProduct(!product.content ?
                    { name: product.name, price: product.price, description: product.description, image: product.image,numberInStock:product.numberInStock} :
                    { name: product.name, price: product.price, description: product.description, image: product.image, content: product.content,numberInStock:product.numberInStock}
                    , productType.physical ? "physical" : "digital", token).then(res => {
                            setLoading(false)
                            swal("", "Successfully added product", "success")
                            setProduct({name:"", price:"", description:"", image:"", content:"", numberInStock: 0, type: "" });
                            navigate("/accounts/business/dashboard/products")
                            console.log(res)
                    }).catch(err => {
                            setLoading(false)
                            console.log(err)
                            swal("Something's wrong", !err.response.data?err.message:err.response.data, 'error')
                    })
    }

}
    return ( 
        <BusinessDashboardLayout>
        <div className='newproduct_container'>
            <h3>Add a Product</h3>
            <Link to={`/accounts/business/dashboard/products`} style={{float:"right", paddingRight:"40px"}}><i className='fa fa-arrow-left'></i>{" "}Go back</Link>
            <div className='newproduct_wrapper'>
            <div className='add-product-container'>
            <form onSubmit={handleAddProduct}>
                <label htmlFor="input-1">
                    <div className='img-upload'>
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        <span>{filename}</span>
                    </div>
                </label>
                <input type={"file"} id="input-1" className="upload-1"
                    name='image'
                    required 
     onChange={(e)=>{
            const file = e.target.files[0];
            if(e.target.files[0].size>2000000){
                toast.info("Logo size must not be greater than 2MB")
                e.target=null
                }else{
                    const formData = new FormData()
            formData.append('image',file)
            console.log(e.target.files[0])
            setFilename(e.target.files[0].name)
            setProduct({...product,[e.target.name]:file})
                }
        }}
                />
                
                <br/>
                <input type={"text"} placeholder={"Name"} className="input-1"
                     value={product.name}
                name='name'
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
                />
                <br/>
                <textarea placeholder='Description' className='input-2'
                          name="description"
value={product.description}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}   
                />

                <div className='product-type'>
                    <h5>Type:</h5>
                    <br/>
                    <div style={{paddingTop:"20px"}}>
                        <input type={"radio"}
                              name='physical'
    checked={productType.physical || !true}
     onChange={({target:{name,checked}})=>setProductType({[name]:checked})}
                        />{" "}
                        <label>Physical</label>
                        <input type={"radio"} className="digital-radio-box"
                            name='digital'
                             checked={productType.digital || !true}
    onChange={({target:{name,checked}})=>setProductType({[name]:checked})}
                        />{" "}
                        <label>Digital</label>
                    </div>
                
                </div>
                <br/>
                {productType.digital&&
                <>
                  <label htmlFor="input-2">
                    <div className='img-upload'>
                    <i class="fa fa-cloud-upload" aria-hidden="true"></i>
                        <span>Upload digital file of product (pdf, mp3, mp4, zip,  jpg)</span>
                    </div>
                </label>
                <input type={"file"} id="input-2" className="upload-1"
                       name="content" required onChange={(e)=>{
            const file = e.target.files[0];
            const formData = new FormData()
            formData.append('content',file)
            console.log(e.target.files[0])
            setProduct({...product,[e.target.name]:file})
        }}
                />
                </>
                }
               <br/>
               <h4>Number in Stock:</h4>
               <input type={"number"} placeholder={"Number In Stock"} className="input-1" style={{width:"250px"}}
                               name="numberInStock"
value={product.numberInStock}
    onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
               />
                <br/>
                <div className='price-settings-container'>
                    <h4>Price:</h4>
                    <div className='price-settings'>
                        <select disabled>
                            <option >NGN</option>
                        </select>
                        <input type={"text"}
                            name="price"
    value={product.price}
        onChange={({target:{name,value}})=>setProduct({...product,[name]:value})}
                        />
                    </div>
                </div>
                <br/>
                <div className='newproduct_base'>
                    <button type='submit'>{loading ? "Please Wait..." : "Add Product"}</button>
                </div>
                </form>
            </div>
            </div>
        </div>
        </BusinessDashboardLayout>
     );
}
 
export default NewProducts;