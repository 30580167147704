import React from 'react';
import PageThreeComponent from '../../../components/storeConfig/PageThreeComponent';
import StoreConfigLayout from '../../../components/storeConfig/StoreConfigLayout';

const StoreConfigPageThree = () => {
   return (
      <>
         <StoreConfigLayout title={"Setup your affiliate program"} nextLink={"/sucessful-setup"} prevLink={"/add-product-1"}>
            <PageThreeComponent />
         </StoreConfigLayout>
      </>
   );
}

export default StoreConfigPageThree;