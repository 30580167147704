import React, { useEffect, useState,useContext,useRef } from 'react';
import BusinessDashboardLayout from "../../../../components/dashboard/business/BusinessDashboardLayout";
import usePagination from '../../../../hooks/usePagination';
import PaginateButton from "../../../../components/PaginateButton";
import { getStoreOrders } from '../../../../services/userService';
import "./styles.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BusinUserContext } from '../../../../context/BusinUserContext';
import { MdKeyboardArrowDown } from 'react-icons/md';
import useClickOutside from '../../../../hooks/useClickOutside';

const Orders = () => {
    const {userInfo} = useSelector(state=>state.userLogin);
    const {orders,setOrders} = useContext(BusinUserContext);
    // useEffect(()=>{
    //     if(userInfo){
    //         getStoreOrders(userInfo.token).then(res=>{
    //             console.log(res)
    //             setOrders(res.data)
    //         }).catch(err=>{
    //             console.log(err)
    //         })
    //     }
    // },[])
    const rowsPerPage = 20;
    const [page, setPage] = useState(1);
    const { slice, range } =  usePagination(orders, page, rowsPerPage);
    const navigate = useNavigate();
    const [ showDropdownOptions, setShowDropDownOptions ] = useState(false);
    const [ currentFilter, setCurrentFilter ] = useState("All");
    const validStatuses = ["All", "Pending", "Delivered", "Cancelled", "Shipped"];
    const statusDropdownRef = useRef(null);
    const [ orderData, setOrderData ] = useState([]);

    useClickOutside(statusDropdownRef, () => setShowDropDownOptions(false));

    useEffect(() => {
      
      setOrderData(slice);

    }, [slice])

    useEffect(() => {
      
      if (currentFilter === "All") return setOrderData(slice);

      const ordersToShow = slice.filter(item => item.deliveryStatus === currentFilter);
      setOrderData(ordersToShow);

    }, [currentFilter, slice])

    // useEffect(() => {
    //   if (!location.state) return;

    //   if (!location.state.status) return;

    //   setCurrentFilter(location.state.status);
      
    // }, [location])

    return ( 
        <>
            <BusinessDashboardLayout>
<div className='order-container'>
<h4>Your Orders</h4>
<div className='status__Dropdown__Select' onClick={() => setShowDropDownOptions(!showDropdownOptions)}>
  {currentFilter}
  <MdKeyboardArrowDown className={`dropdown__Icon ${showDropdownOptions ? 'invert' : ''}`} />
  { 
    showDropdownOptions && <div className='status__Dropdown__Options' ref={statusDropdownRef}>
      {
        React.Children.toArray(validStatuses.map(status => {
          return <div onClick={() => setCurrentFilter(status)}>{status}</div>
        }))
      }
    </div>
  }
</div>
<table>
<thead>
            <tr>
              <th className="serial-number">Order ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              {/* <th>Address</th> */}
              <th>Products</th>
              <th>Delivery Status</th>
              {/* <th >Price</th>
              <th >Paid</th>
              <th style={{width:"100px",lineHeight:"20px"}}>Paid At</th>
              <th>Delivered</th> */}
              <th  className="table-prducts-price">Referrer</th>
              {/* <th>Details</th> */}
            </tr>
          </thead>
          <tbody> 
         
            {orderData.map((item, idx) => (
              <tr key={idx} onClick={()=>navigate(`/accounts/business/dashboard/order-details/${item._id}`)}>
               <td>{item._id.substring(0,10)}</td>
                {/* <td><Link to={`/admin-user-verse-control/${item._id}`}>{item.name}</Link></td> */}
                <td>{item.user.name}</td>
                <td>{item.user.email}</td>
                <td>{item.user.phone}</td>
                {/* <td>{item.user.address}</td> */}
             <td>
              <select>
                {item.products.map((product,idx)=><option key={idx}>{product.name} X {product.qty}</option>)}
              </select>
             </td>
             <td>{item.deliveryStatus}</td>
              {/* <td>{item.totalAmount?`NGN ${item.totalAmount}`:"N/A"}</td>
              <td>{`${item.isPaid}`}</td>
              <td>{item.isPaidAt?.substring(0,10)}</td>
              <td>{`${item.isDelivered}`}</td> */}
              <td>{item.referrer?item.referrer:"N/A"}</td>
              {/* <td><Link to="">View Details</Link></td> */}
              </tr>
            ))}
          </tbody>
</table>
</div>

{orderData.length===0&&
<div style={{textAlign:"center", marginTop:"20px"}}>
  <span>No data to show</span>
</div>
         }

         {orderData.length>0&&<PaginateButton
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />}
            </BusinessDashboardLayout>
        </>
     );
}
 
export default Orders;
