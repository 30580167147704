import React, { useContext, useState ,useRef, useEffect} from 'react';
import { CartContext } from '../context/CartContext';
import { addToCart } from '../store/cart';
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import cartIcon from "../images/cartIcon.png";
import ProductModal from "./productModal/ProductModal";
import usePagination from "../hooks/usePagination"
import { useParams } from 'react-router-dom';
import { getUserProduct } from '../services/userService';


const Product = ({ product }) => {
    const dispatch = useDispatch()
    const [qty,setQty] = useState(1);
    const modalRef = useRef(null);
    //const [storeProduct,setStoreProduct] = useState(product)
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: "70%",
            height: "300px",
            transform: 'translate(-50%, -50%)',
        },
    };

    const {productId,storeName} = useParams();
    const [modalOpen, setModalOpen] = React.useState(false);
//     useEffect(()=>{
// if(productId){
//     getUserProduct(storeName,productId).then(res=>{
//         console.log(res)
//         setModalOpen(true)
//         setStoreProduct(res.data)
//     }).catch(err=>{
//         console.log(err)
//     })
// }else{
//     setStoreProduct(product)
// }
//     },[])



    Modal.setAppElement('#root');



    function openModal() {
        setModalOpen(!modalOpen);
    }

    function closeModal() {
        setModalOpen(!modalOpen);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function addToCartHandler(){
        dispatch(addToCart(product,qty))
        closeModal();
          }

    return (
        <>
            <div className='store-content' onClick={openModal}>
                <img src={product.image_url} alt="" />
                <span style={{ float: "left" }}>{product.name}</span>
                <span style={{ float: "right" }}>&#8358;{product.price}</span>
            </div>

            <ProductModal
                modalRef={modalRef}
                product={product}
                isModalOpen={modalOpen}
                closeModal={closeModal}
                qtySelected={qty}
                handleAddToCartBtnClick={addToCartHandler}
                updateQtySelected={setQty}
            />
   
        </>
    );
}

export default Product;