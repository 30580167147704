import React from 'react';
import "./footer.css";
import { Link } from 'react-router-dom';
import logo from "../../images/logo.png";

const Footer = () =>{
    return(
<footer>
<div className="footer">
        <div className="foot1">
          <div>
            <Link to="/">
              <img src={logo} alt="" className='logo'/>
            </Link>
          </div>

          <div className="footcontents">
            <div className="footcontent">
              <h1>Company</h1>
              <a href="/#">
                <h3>About Us</h3>
              </a>
              <a href="/#">
                <h3>Careers</h3>
              </a>
              <a href="/#">
                <h3>Blogs</h3>
              </a>
              <a href="/#">
                <h3>Press</h3>
              </a>
            </div>
          </div>

          <div className="footcontents">
            <div className="footcontent">
              <h1>Legal</h1>
              <a href="/#">
                <h3>Terms</h3>
              </a>
              <a href="/#">
                <h3>Privacy</h3>
              </a>
              <a href="/#">
                <h3>Security</h3>
              </a>
            </div>
          </div>

          <div className="footcontents social-community">
            <div className="footcontent">
              <h1>Community</h1>
              <a href="https://twitter.com/netgrohq" target="_blank" rel="noreferrer" >
              <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
              <a href="https://facebook.com/netgroapp" target="_blank" rel="noreferrer" >
              <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="https://www.instagram.com/netgroapp/" target="_blank" rel="noreferrer" >
              <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>

              <a href="https://www.linkedin.com/company/netgro/" target="_blank" rel="noreferrer" >
              <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

<div className='footer-bottom'>
       <p> Netgro &copy;{new Date().getFullYear()}, all rights reserved.</p>
       </div>
      </div>
</footer>
    )

}

export default Footer;