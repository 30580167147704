
export function usrEmail() {
  const usr_email = sessionStorage.getItem("usr_email");
  if (usr_email) return usr_email;
  else return "";
}

export function userMessage(countryState,country) {
   if (countryState?.length > 0 && country === "") return "Select State";
   else if (countryState?.length === 0 && country === "") return "Select State";
   else if (countryState?.length > 0 && country?.length > 0)
     return "Select State";
   else return "No State Available Here";
}

export function formatDateAndTime (dateToFormat) {
  if ( new Date(dateToFormat) === "Invalid Date" ) return "Null";

  const daysEndingWithSt = [1, 21, 31];
  const daysEndingWithNd = [2, 22];
  const daysEndingWithRd = [3, 23];

  const dateInValidFormat = new Date(dateToFormat);

  const day = dateInValidFormat.toLocaleDateString("en-us", {day: "numeric"})
  const dayAsNumber = Number(day);
  const month = dateInValidFormat.toLocaleDateString("en-us", {month: "long"})

  if ( daysEndingWithSt.includes(dayAsNumber) ) return `${day}st ${month}, ${dateInValidFormat.getFullYear()} at ${dateInValidFormat.getHours() >= 10 ? dateInValidFormat.getHours(): '0' + dateInValidFormat.getHours()}:${dateInValidFormat.getMinutes() >= 10 ? dateInValidFormat.getMinutes() : '0' + dateInValidFormat.getMinutes()}:${dateInValidFormat.getSeconds() >= 10 ? dateInValidFormat.getSeconds() : '0' + dateInValidFormat.getSeconds()}`;
  
  if ( daysEndingWithNd.includes(dayAsNumber) ) return `${day}nd ${month}, ${dateInValidFormat.getFullYear()} at ${dateInValidFormat.getHours() >= 10 ? dateInValidFormat.getHours(): '0' + dateInValidFormat.getHours()}:${dateInValidFormat.getMinutes() >= 10 ? dateInValidFormat.getMinutes() : '0' + dateInValidFormat.getMinutes()}:${dateInValidFormat.getSeconds() >= 10 ? dateInValidFormat.getSeconds() : '0' + dateInValidFormat.getSeconds()}`;

  if ( daysEndingWithRd.includes(dayAsNumber) ) return `${day}rd ${month}, ${dateInValidFormat.getFullYear()} at ${dateInValidFormat.getHours() >= 10 ? dateInValidFormat.getHours(): '0' + dateInValidFormat.getHours()}:${dateInValidFormat.getMinutes() >= 10 ? dateInValidFormat.getMinutes() : '0' + dateInValidFormat.getMinutes()}:${dateInValidFormat.getSeconds() >= 10 ? dateInValidFormat.getSeconds() : '0' + dateInValidFormat.getSeconds()}`;

  return `${day}th ${month}, ${dateInValidFormat.getFullYear()} at ${dateInValidFormat.getHours() >= 10 ? dateInValidFormat.getHours(): '0' + dateInValidFormat.getHours()}:${dateInValidFormat.getMinutes() >= 10 ? dateInValidFormat.getMinutes() : '0' + dateInValidFormat.getMinutes()}:${dateInValidFormat.getSeconds() >= 10 ? dateInValidFormat.getSeconds() : '0' + dateInValidFormat.getSeconds()}`;

}