import React,{useContext, useEffect, useState,useRef} from 'react';
import Cart from '../../components/Cart';
import Product from '../../components/Product';
import { useNavigate, useParams } from 'react-router-dom';
import StoreFooter from '../../components/StoreFooter';
import StoreNavBar from '../../components/StoreNavbar';
import { CartContext } from '../../context/CartContext';
import { getAllProducts, getProductByName, getUserProduct, validateReferrer } from '../../services/userService';
import "./StorePage.css";
import usePagination from '../../hooks/usePagination';
import PaginateButton from '../../components/PaginateButton';
import ProductModal from '../../components/productModal/ProductModal';
const StorePage = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)

    const {storeName,referrer,productId}= useParams();

    // const [products,setProducts] = useState([]);
    // const [owner,setOwner] = useState('')

    const [data,setData] = useState({
        owner:'',
        products:[],
        store:{}
    })

    const [productName,setProductName] = useState("")
    const numberPerDisplay =4;
    const [page, setPage] = useState(1);
    const { slice, range } = usePagination(data.products, page, numberPerDisplay);
    const modalRef = useRef(null);
    const [product,setProduct] = useState({})
    const [modalOpen, setModalOpen] = React.useState(false);
    const {qty,setQty} = useState(1);
useEffect(()=>{
if(productId){
    getUserProduct(storeName,productId).then(res=>{
        console.log(res)
        setProduct(res.data)
        openModal()
    }).catch(err=>{
        console.log(err)
    })
}
    if(productName.length===0)
     getAllProducts(storeName).then(res=>{
        setData(res.data)
        console.log(res)
        setLoading(true)
            }).catch(err=>{
                console.log(err)
                setLoading(true)
                navigate("*")
            })

            if(referrer){
                validateReferrer(referrer).then(res=>{
                    console.log(res)
                }).catch(err=>{
                    setLoading(true)
                    navigate("*")
                })
            }
},[storeName,navigate,referrer,productName,productId])

const {showCart,setShowCart} = useContext(CartContext)

// const products = [
//     {name:"Milk Milk Milk Milk",image:"./images/milk.png",price:"1500",id:1,numInStock:10},
//     {name:"Cream Milk Milk Milk",image:"./images/woman.png",price:"1500",id:2,numInStock:20},
//     {name:"Milk Milk Milk Milk",image:"./images/milk.png",price:"1500",id:3,numInStock:6},
//     {name:"Cream Milk Milk Milk",image:"./images/woman.png",price:"1500",id:4,numInStock:20},
// ]

function toggleCart(){
    setShowCart(!showCart)
}

function searchProduct(e){
    e.preventDefault();
    getProductByName(storeName,productName).then(res=>{
        console.log(res)
        setData({...data,products:res.data})
    }).catch(err=>{
        console.log(err)
    })
}


function openModal() {
    setModalOpen(!modalOpen);
}

function closeModal() {
    setModalOpen(!modalOpen);
}


    return (
        <>
      
        {!loading ? <div className='spinner-contatiner'>
                <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
               </div>:
               <>
               <StoreNavBar toggleCart={toggleCart} owner={data.owner} store={data.store}/>
        <div className='store-wrapper' style={{background: data.store.backgroundColor && data.store.backgroundColor.value}} onClick={()=>setShowCart(false)}>
        <form className='search__Container' onSubmit={searchProduct}>
        <i className='fa fa-search '></i>
        <input type={"text"} placeholder={"Search Products"} value={productName} onChange={({target})=>setProductName(target.value)}/>
        </form>
            <div className='store-container'>
            {data.products.length===0 && <h3>No Products for this store.</h3>}
            {slice.map((product,idx)=>(
            <Product product={product} key={idx}/>
            ))}
            <PaginateButton
              range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
            </div>
            <StoreFooter/>
            </div>
        
          {showCart&&<Cart toggleCart={toggleCart} store={data.store} owner={data.owner}/>}
          <ProductModal
                         modalRef={modalRef}
                product={product}
                isModalOpen={modalOpen}
                closeModal={closeModal}
                qtySelected={qty}
                updateQtySelected={setQty}
         />
         
               </>
               }
       
        </>
     );
        
               
          
}
 
export default StorePage;