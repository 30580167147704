import React from 'react';
// import './dashboard_home.styles.css';
import filter from '../../images/filter.svg';
import file from '../../images/file.svg';
import file1 from '../../images/file1.svg';
import home from '../../images/home.svg';
import shopCart from '../../images/shopping_cart.svg';
import lockedCash from '../../images/locked_cash.svg';
import double from '../../images/double.svg';
// import Recent_Sales from './recent_sales';

const DashboardHome = () => {

    const Arr = [
        { up_txt: "Total Sales", mid_txt: '50', bottom_txt: "View >", img: shopCart },
        { up_txt: "Total Income", mid_txt: '₦200.7k', bottom_txt: "Cash out >", img: lockedCash },
        { up_txt: "Your Affiliates", mid_txt: '50', bottom_txt: "View >", img: double }
    ]
    const url = 'https:netgro.co/ladybstore';

    return (
        <div className='dash_home_container'>
            <div className="dash_mid">
                <div className="dash_mid_top">
                    <span>Your Dashboard</span>
                    <div className="url">
                        <div className="url_content">
                            <div className="url_img">
                                <img src={file1} />
                                <img src={file} className="img2" />
                            </div>
                            <span>Affiliate link : {url}</span>
                        </div>
                    </div>
                </div>
                <div className="dash_mid_base">                       
                    <div className="dash_mid_overflow">
                        {Arr.map((arr, idx) => (
                            <div key={idx} className="dash_mids_wrapper">
                                <div className="dash_mids_content">
                                    <div className="dash_midd_top">{arr.up_txt}</div>
                                    <div className="dash_mid_mid">{arr.mid_txt}</div>
                                    <div className="dash_mid_bottom">
                                        <span>{arr.bottom_txt}</span>
                                        <div className="dash_mid_img">
                                            <img src={arr.img} />
                                        </div>                                                   
                                    </div>
                                </div>
                            </div>
                        ))}                      
                    </div>                   
                </div>
                    {/* </div> */}
                <div className="dash_base">
                    <div className="dash_base_top">
                        <span>Recent Sales</span>
                        <div className="filter">
                            <span>Filter</span>
                            <div className="filter_img"><img src={filter} /></div>
                        </div>
                    </div>
                    <div className="dash_base_main">
                        {/* <Recent_Sales /> */}
                    </div>

                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default DashboardHome;