import React from "react";
import "./card_res.css";

const Card = ({ content,id,children }) => {

    return (
      <div
        className={`card ${content.class}`}
        style={{ backgroundColor: content.bg }}
      >
            
            {children}
        <h3>{content.title}</h3>
        <p>{content.detail}</p>
      </div>
    );
}
 
export default Card;