import React, { useContext, useEffect, useState } from 'react';
import Input from './input';
// import circle_plus from '../../../../images/plus_circle.svg';
// import img_logo from '../../../../images/upload_img.svg';
import './settings_config.styles.css'
import Settings from './settings';
import { editUserDetails, getUserProfile } from '../../../../services/userService';
import { toast } from 'react-toastify';
import { BusinUserContext } from '../../../../context/BusinUserContext';

const Profile = () => {

 
const {user,setUser} = useContext(BusinUserContext);
const [loading,setLoading] = useState(false)

function handleFormInputChange({target:{name,value}}){
const userDetails = {...user}
userDetails[name] = value
setUser(userDetails);
}

function handleFormSubmit(e){
    setLoading(true)
    e.preventDefault();
    console.log(user)
    const token = localStorage.getItem("netgroAuthToken");
    if(token){
        editUserDetails(token,"business",user).then(res=>{
            console.log(res)
            toast.success("Successfully updated info 👍")
            setLoading(false)
        }).catch(err=>{
            console.log(err)
            setLoading(false)
            toast.error(!err.response.data?err.message:err.response.data)
        })
    }
}
    return (
        <Settings>
        <div className='settings_config_container'>
            <div className='settings_config_wrapper'>
                <div className='settings_config_content'>
                    <form onSubmit={handleFormSubmit}>
                    <div className='settings_config_body'>
                        <Input placeholder={'Name'} value={user.fullname} name="fullname" onChange={handleFormInputChange}/>
                        <br/>
                        <Input placeholder={'Email'} name="email" value={user.email} onChange={handleFormInputChange}/>
                        <br/>
                        <Input placeholder={'Business'} value={user.businessname} name="businessname" onChange={handleFormInputChange}/>
                        <br/>
                        <Input placeholder={'Phone'} value={user.phonenumber} name="phonenumber" onChange={handleFormInputChange}/>
                        <br/>
                    </div>
                    <br/>
                    <div className='settings_config_base' style={{fontWeight:"lighter"}}>
                     <button
                     disabled={loading}
                      style={{height:"40px",borderRadius:"10px"}}>{loading?"Updating...":'Update'}</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </Settings>
    )
}

export default Profile;