import BusinessDashboardLayout from "../../../../components/dashboard/business/BusinessDashboardLayout"
import { TbCurrencyNaira } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { BiMoney } from "react-icons/bi";
import { HiLockClosed } from "react-icons/hi";
import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Doughnut, Line } from "react-chartjs-2";
import AnalyticsCard from "../../../../components/common/analyticsCard/AnalyticsCard";
import React, { useEffect, useRef, useState } from "react";
import { getAnalytics, getAnalyticsForCustomDate } from "../../../../services/userService";
import { toast } from "react-toastify";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import useClickOutside from "../../../../hooks/useClickOutside";

import "./style.css";
import LoadingSpinner from "../../../../components/common/loadingSpinner/loadingSpinner";


ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend, 
    CategoryScale, 
    LinearScale, 
    PointElement, 
    LineElement,
    Title,
);

const BusinessAnalytics = () => {
    const [businessAnalytics, setBusinessAnalytics] = useState({});
    const [showDropdownOptions, setShowDropDownOptions] = useState(false);
    const [currentOverviewRange, setOverviewRange] = useState("Last 6 months");
    const [showCustomForm, setShowCustomForm] = useState(false);
    const selectRef = useRef(null);
    const minDateRef = useRef(null);
    const maxDateRef = useRef(null);
    const [isRequestLoading, setRequestLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [minDateValue, setMinDateValue] = useState("");
    const [maxDateValue, setMaxDateValue] = useState("");

    useClickOutside(selectRef, () => setShowDropDownOptions(false));

    useEffect(() => {
        const token = localStorage.getItem("netgroAuthToken");
        
        if (token){

            getAnalytics(token, "business").then(res => {
                
                setBusinessAnalytics(res.data.analytics);

            }).catch(err => {
                toast.error(!err.response ? err.message: err.response.data)
            });

        }
        
    }, [])

    useEffect(() => {

        if ( (!minDateRef.current) || (!maxDateRef.current) ) return

        minDateRef.current.max = new Date().toLocaleDateString("en-ca");
        maxDateRef.current.max = new Date().toLocaleDateString("en-ca");

    }, [showCustomForm])

    useEffect(() => {

        if ( (minDateValue === "") || (maxDateValue === "") ) return setDisabled(true);

        if ( minDateValue > maxDateValue ) return setDisabled(true);

        setDisabled(false);

    }, [minDateValue, maxDateValue])

    const salesConversionData = {
        labels: ["Conversion Rate"],
        datasets: [
            {
                label: "conversion rate",
                data: [businessAnalytics.conversionRate],
                backgroundColor: ["#B9F0D2",],
                borderColor: ["#B9F0D2",]
            }
        ]
    }

    const ordersData = {
        labels: ["Completed Orders", "Pending Orders", "Cancelled Orders"],
        datasets: [
            {
                label: "orders",
                data: businessAnalytics.orders ? [businessAnalytics.orders.completedOrders, businessAnalytics.orders.pendingOrders, businessAnalytics.orders.cancelledOrders] : [],
                backgroundColor: ["#34A853", "#EEAA33", "#D9D9D9",],
                borderColor: ["#34A853", "#EEAA33", "#D9D9D9",]
            }
        ]
    }

    const overviewOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Sales in ${currentOverviewRange}`
            }
        }
    }
    const overviewData = {
        labels: businessAnalytics.salesOverview ? businessAnalytics.salesOverview.months[currentOverviewRange] : [],
        datasets: [
            {
                label: "Sales",
                data: businessAnalytics.salesOverview ? businessAnalytics.salesOverview.data[currentOverviewRange] : [],
                backgroundColor: ["#B9F0D2",],
                borderColor: ["#B9F0D2",]
            }
        ]
    }

    const handleMouseEnter = (e, elem) => {
        elem.nextSibling.style.visibility = "visible";
        elem.nextSibling.style.left = e.clientX - 450 + "px";
        elem.nextSibling.style.top = e.clientY - 300 + "px";
        
    }

    const handleMouseLeave = (elem) => elem.nextSibling.style.visibility = "hidden";

    const handleCustomDateBtnSubmit = (e) => {
        e.preventDefault();

        setRequestLoading(true);
        setDisabled(true);

        const token = localStorage.getItem("netgroAuthToken");

        if (!token) return toast.error("Unauthorized");
        
        getAnalyticsForCustomDate(token, { from: minDateValue, to: maxDateValue},"business").then(res => {
            const newMonthSelection = Object.keys(res.data.analytics.customSalesOverview.months || {})[0];
            setOverviewRange("Sales " + newMonthSelection);
            setBusinessAnalytics(prevValue => { return { ...prevValue, salesOverview: { ...prevValue.salesOverview, months : {  ...prevValue.salesOverview.months, [`${newMonthSelection}`]: res.data.analytics.customSalesOverview.months[newMonthSelection] } }} });
            setBusinessAnalytics(prevValue => { return { ...prevValue, salesOverview: { ...prevValue.salesOverview, data : { ...prevValue.salesOverview.data, [`${newMonthSelection}`]: res.data.analytics.customSalesOverview.data[newMonthSelection] }}} });
            
            if (!selectRef) return;
            
            const newDistanceFromBottom = Number(window.getComputedStyle(selectRef.current).bottom.split("px")[0]) * 1.25 ;
            selectRef.current.style.bottom = `${newDistanceFromBottom}px`;

            setShowDropDownOptions(false);
            setShowCustomForm(false);
            setRequestLoading(false);
            setDisabled(false);

        }).catch(err => {
            toast.error(!err.response.data ? err.message: err.response.data);
        })

    }

    return <>
        <BusinessDashboardLayout>
            <div className="business__Analytics__Container">
                <h3 className="title__Text">Analytics</h3>

                <section className="business__Analytics__Intro" role="main">
                    
                    <AnalyticsCard 
                        analyticTitle={'Sales'}
                        analyticIcon={<TbCurrencyNaira className="dark__Blue__Color" />}
                        analyticValue={businessAnalytics.totalSales}
                    />
                    <AnalyticsCard 
                        analyticTitle={'Total Views'}
                        analyticIcon={<AiOutlineEye className="dark__Blue__Color" />}
                        analyticValue={businessAnalytics.storeViews}
                    />
                    <AnalyticsCard 
                        analyticTitle={'Orders'}
                        hasDoubleIcons={true}
                        analyticIcon={{firstIcon: <BiMoney className="dark__Blue__Color" />, secondIcon: <HiLockClosed className="dark__Blue__Color" />}}
                        analyticValue={businessAnalytics.orders ? businessAnalytics.orders.totalOrders : 0}
                    />
                    
                </section>

                <section className="business__Analytics__Sales__Overview first" role="complementary">
                    <div className="overview__Intro">
                        <h3>Overview</h3>
                        <div className="sales__Overview__Select" onClick={() => setShowDropDownOptions(prevValue => { return !prevValue })}>
                            {currentOverviewRange}
                            <MdKeyboardArrowDown className={`dropdown__Icon ${showDropdownOptions ? 'invert' : ''}`} />
                        </div>
                        { 
                            showDropdownOptions && <div className={`sales__Overview__Select__Options__Container ${showCustomForm ? 'custom__Show' : ''}`} ref={selectRef}>
                                
                            {
                                showCustomForm ? <form>
                                    <IoIosArrowBack className="form__Icon" onClick={() => setShowCustomForm(false)} />
                                    <label htmlFor="minDate">From</label>
                                    <input type={"date"} name={"minDate"} ref={minDateRef} value={minDateValue} onChange={(e) => setMinDateValue(e.target.value)} />
                                    <label htmlFor="maxDate">To</label>
                                    <input type={"date"} name={"maxDate"} ref={maxDateRef} value={maxDateValue} onChange={(e) => setMaxDateValue(e.target.value)} />
                                    <button className="sales__Overview__Select__Button" onClick={handleCustomDateBtnSubmit} type={"submit"} disabled={disabled}>{ isRequestLoading ? <LoadingSpinner color={"#283A57"} /> : "Show Results"}</button>
                                </form> :

                                React.Children.toArray(Object.keys(businessAnalytics.salesOverview ? businessAnalytics.salesOverview.months || {} : {}).map((key, index) => (

                                    <>
                                        {
                                            index + 1 === Object.keys(businessAnalytics.salesOverview.months || {}).length ? <>
                                                <div className="sales__Overview__Select__Option" onClick={() => { setOverviewRange(key); setShowDropDownOptions(false);} }>{key[0].toLocaleUpperCase() + key.slice(1)}</div>
                                                <button className="sales__Overview__Select__Button" onClick={() => setShowCustomForm(true)}>Select Other</button>
                                            </> :
                                            <div className="sales__Overview__Select__Option" onClick={() => { setOverviewRange(key); setShowDropDownOptions(false); }}>{key[0].toLocaleUpperCase() + key.slice(1)}</div>
                                        }
                                    </>

                                )))
                            }
                            </div>
                        }
                    </div>

                    <div className="charts__Container">
                        <div className="chart__Container">
                            <Line options={overviewOptions} data={overviewData} />
                        </div>
                    </div>
                </section>

                <section className="business__Analytics__Sales__Overview" role="complementary">
                    <h3>Sales Overview</h3>
                    
                    <div className="charts__Container">
                    
                        <div className="chart__Container">
                            <Doughnut data={ordersData} />
                        </div>
                        
                        <div className="chart__Container">
                            <Doughnut data={salesConversionData} />
                        </div>

                    </div>
                    
                </section>
                <section className="business__Analytics__Top__Product" role="complementary">
                    <h3>Your Top Selling Product this Month</h3>
                    <div className="business__Analytics__Product__Preview">
                        {
                            !businessAnalytics.topProduct ? <></> :
                            
                            businessAnalytics.topProduct.name === "" ? <>
                                <div className="product__Details">
                                    Your top selling product every month will appear here
                                </div>
                            </> :
                            
                            <>
                                <img src={businessAnalytics.topProduct.productImage} alt="top selling product" onMouseMove={(e) => handleMouseEnter(e, e.target)} onMouseLeave={(e) => handleMouseLeave(e.target)} />
                                <div className="stats__Count">It has being bought over {businessAnalytics.topProduct.timesBought} times!</div>
                                <div className="product__Details">
                                    <p className="product__Title">{businessAnalytics.topProduct.name}</p>
                                    <p className="product__Price">{businessAnalytics.topProduct.price}</p>
                                </div>
                                <div className="stats__Count__Static">
                                    It has being bought over {businessAnalytics.topProduct.timesBought} times this month!
                                </div>
                            </>
                        }
                    </div>
                    
                </section>

                <section className="business__Analytics__Top__Affliate" role="complementary">
                    <h3>Your Top Affliate this Month</h3>
                    {
                        !businessAnalytics.topAffliate ? <></> :

                        businessAnalytics.topAffliate.name === "" ? <>
                            <div className="business__Analytics__Product__Preview">
                                Your top affliate every month will appear here.
                            </div>
                        </> :
                        
                        <div className="business__Analytics__Affliate__Preview">
                            <div className="affliate__Profile__Image">
                                <img src={'/images/woman.png'} alt="top affliate" />
                            </div>
                            <div className="affliate__Details">
                                <p className="affliate__Display_Name">{businessAnalytics.topAffliate.name}</p>
                                <p className="affliate__Username">@{businessAnalytics.topAffliate.username}</p>
                                {/* <a href="./#">View Profile</a> */}
                            </div>
                        </div>

                    }
                </section>
            </div>
        </BusinessDashboardLayout>
    </>

}

export default BusinessAnalytics;
