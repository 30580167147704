import React,{useEffect} from 'react';
import SettingsNavBar from './settings_navbar';
import {useLocation,useNavigate } from 'react-router-dom';
import Profile from './profile';
import BusinessInfo from './businessinfo';
import Affiliate from './affiliate';
import Payout from './payout';
import BusinessDashboardLayout from '../../../../components/dashboard/business/BusinessDashboardLayout';
import { getUserProfile } from '../../../../services/userService';

const Settings = ({children}) => {
const location = useLocation();
const navigate = useNavigate()

useEffect(()=>{
    // const token = localStorage.getItem("netgroAuthToken");
    // getUserProfile(token,"business").then(res=>{
    //     console.log(res)
    // }).catch(err=>{
    //     console.log(err)
    // })
    if(location.pathname==="/accounts/business/dashboard/settings") return navigate("/accounts/business/dashboard/settings/profile")
})
    return (
        <BusinessDashboardLayout>
        <div className='settings_container'>
                    <div className='settings_wrapper'>
                <div className='settings_content'>
                    <SettingsNavBar />
                    {/* <Routes>
                        <Route path='/' element={<Profile />} />
                        <Route path='businessinfo' element={<BusinessInfo />} />
                        <Route path='affiliate' element={<Affiliate />} />
                        <Route path='payout' element={<Payout />} />
                    </Routes> */}
{children}
                    {/* <BusinessInfo /> */}
                </div>
            </div>
        </div>
        </BusinessDashboardLayout>
    )
}

export default Settings;