import React, { useState } from "react";
import RecentSalesTableFooter from "./RecentSalesTableFooter";
import usePagination from "../../../hooks/usePagination"
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RecentSalesTable = ({ data, rowsPerPage }) => {
  const [page, setPage] = useState(1);
  const { slice, range } =  usePagination(data, page, rowsPerPage);
  const navigate = useNavigate()
  const {userInfo} = useSelector(state=>state.userLogin);
  return (
    <>
      <div>
        <table>
          <thead>
            <tr>
              <th className="serial-number">Order ID</th>
              <th>Name</th>
              {userInfo && userInfo.accountType==="business" && <th>Phone</th>}
              {/* <th>Address</th> */}
              <th>Products</th>
              {/* <th >Price</th>
              <th >Paid</th>
              <th style={{width:"100px",lineHeight:"20px"}}>Paid At</th>
              <th>Delivered</th> */}
              <th >Delivery</th>
              <th>Payment</th>
              {userInfo && userInfo.accountType==="business"?
              <th className="table-prducts-price">Referrer</th>
              :
              <th className="table-prducts-price">Price</th>
              }
              {/* <th >Details</th> */}
            </tr>
          </thead>
          <tbody>
            {slice.map((item, idx) => (
              <tr style={{cursor:userInfo&&userInfo.accountType==="business"?"pointer":"default"}} 
              key={idx} onClick={()=>navigate(userInfo&&userInfo.accountType==="business"?
                `/accounts/business/dashboard/order-details/${item._id}`:`/accounts/individual/dashboard/order-details/${item._id}`)}>
               <td>{item._id.substring(0,7)}</td>
                {/* <td><Link to={`/admin-user-verse-control/${item._id}`}>{item.name}</Link></td> */}
                <td>{item.user.name}</td>
                {userInfo && userInfo.accountType==="business" &&   <td>{item.user.phone}</td>}
              
                {/* <td>{item.user.address}</td> */}
             <td>
              <select>
                {item.products.map((product,idx)=><option key={idx}>{product.name} X {product.qty}</option>)}
              </select>
             </td>
              {/* <td>{item.totalAmount?`NGN ${item.totalAmount}`:"N/A"}</td>
              <td>{`${item.isPaid}`}</td>
              <td>{item.isPaidAt?.substring(0,10)}</td>
              <td>{`${item.isDelivered}`}</td> */}
              <td>{item.deliveryStatus}</td>
              <td>{item.paymentStatus}</td>
              {userInfo && userInfo.accountType==="business"?
              <td>{item.referrer?item.referrer:"N/A"}</td>
              :
              <td> &#8358;{item.products.reduce((acc,item)=>acc+item.price*item.qty,0)}</td>
              }
              {/* <td><Link to="">View More</Link></td> */}

              </tr>
            ))}
          </tbody>
        </table>
        {/* <RecentSalesTableFooter
          range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        /> */}
      </div>
    </>
  );
};

export default RecentSalesTable;
