import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import home from '../../images/home.svg';
import brandLogo from "../../images/brand_logo.svg";
import analytics from '../../images/analytics.svg';
import Logout from '../../images/logout.svg';
// import './dashboard_sidebar.styles.css';
import wallet from '../../images/wallet.svg';
import storeImg from '../../images/store.svg';
import settings from '../../images/settings.svg';
// import "./affiliate_dashboard.styles.css";


const DashboardSidebar = ({ handleChange, full, user }) => {
    
    const [store, setStore] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if(window.innerWidth <= 500) setIsMobile(true)
    }, []);
    useEffect(() => {
        return () => {
            window.addEventListener("resize", () => {
              if (window.innerWidth <= 500) setIsMobile(true);
              else setIsMobile(false);
            });
        };
    }, []);
    
    
    const Lists = [
        { title: 'Home', img: home, to: '/accounts/user/', id: "home", className: 'sidebar_list', dropdowns: { dropdown: [{title: 'Home', url: '/accounts/user/'}], top: '100px' } },
        { title: (user?'Store':'Sales'), img: storeImg, id: "store", to: '#', className: 'sidebar_list', dropdowns: { dropdown: [{ title: 'Products', url: '/accounts/user/products/', className: 'store_dropdown', }, { title:'Orders', url: '#', className: 'store_dropdown', }], top: '150px' } },
        { title: 'Wallet', img: wallet, id: "wallet", to: '/accounts/user/wallet', className: 'sidebar_list', dropdowns: { dropdown: [{title: 'Wallet', url: '/accounts/user/wallet'}], top: '220px' } },
        { title: 'Analytics', img: analytics, id: "analytics", to: '/accounts/user/', className: 'sidebar_list', dropdowns: { dropdown: [{title: 'Analytics', url: '/accounts/user/'}], top: '280px' } },
        { title: 'Settings', img: settings, id: "settings", to: '/accounts/user/settings', className: 'sidebar_list', dropdowns: { dropdown: [{title: 'Settings', url: '/accounts/user/settings'}], top: '350px' } }
    ]
    
    const [sidebarhover, setSideBarHover] = useState(null);
    const [id, setId] = useState(null)
    const handler = (val, idd, en) => {
        if(en) {
            setSideBarHover(val);
            setId(idd);
        }
        else {
            setSideBarHover(null);
            setId(null);
        }
    }
    const handleStore = (val) => {
        if(val == 1) setStore(!store)
        else setStore(false);
    }

    return (
        <div className="dash_nav_container" style={{width: (isMobile ? '100vw' : (full?'100px': '200px')) }}>
            <div className={full ? "dash_nav_contain" : "dash_nav_contain_full"}>
                <div className="dash_nav_content">               
                    <div className="dash_nav_top">
                        
                        <div className="brand" onClick={handleChange}>
                            <img src={brandLogo} />
                            <span style={{display: (full && (!isMobile) ?'none':'block')}}>netgro</span>
                        </div>
                    </div>
                    <div className="dash_navlists">                      
                        <ul className="dash_nav_lists">
                            {Lists.map((list, idx) => (                             
                                <li key={idx} id={list.id}  onMouseEnter={() => handler(list.dropdowns, idx, true)} onMouseLeave={() => handler(list.dropdowns, idx, false)}> 
                                {/* dont remove the code below until u'vedone fixws , img classname before, className="sidebar_navlist_img"*/} 
                                    <div className={full||isMobile?"flex":"flex-full"} onClick={() => handleStore(idx)}>
                                        <Link to={list.to} className="flex-link">
                                        <div className={list.className}>
                                            <img src={list.img} className="sidebarlist_img"/>
                                            <span style={{display: (full && !isMobile ?'none':'block')}}>{list.title}</span>
                                        </div>
                                        </Link>
                                        {(store&&!full) &&
                                            <ul className={idx==1?"relative_dropdown_lists":"none"}>
                                                {list.dropdowns.dropdown.map((dropdown, idd) => (
                                                    <li key={idd}><Link to={dropdown.url} className="store_dropdown"><span>{dropdown.title}</span></Link></li>
                                                ))}
                                            </ul>
                                        }
                                        {(id==idx&&sidebarhover&&full&&!isMobile) && (
                                            <ul className="absolute_dropdown_lists">
                                                {sidebarhover.dropdown.map((dropdowns, idx) =>
                                                    <li key={idx}><Link to={dropdowns.url} className="dropdown_list"><span>{dropdowns.title}</span></Link></li>                         
                                                )}
                                            </ul>                    
                                        )}
                                        
                                    </div>   
                                </li>                               
                            ))}
                        </ul>
                    </div>
                    <div className="dash_nav_bottom">
                        <div className="dash_nav_logout"><Link to="#" className="dash_logout"><img src={Logout} className="sidebar_navlist_img"/><span style={{display: (full?'none':'block')}}>Logout</span></Link></div>
                    </div>

                </div>
            </div>
            <div className="slider" onClick={handleChange} style={{transform : `rotate(${(full ? `0deg` : `180deg`)})`}}>
                {">"}
            </div>
            
        </div>
    )
}

export default DashboardSidebar;