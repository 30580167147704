

export const navbarData = [
  {
    title: "Sell",
    path: "/",
    cName: "busin",
    id:"for-business"
  },
  {
    title: "Earn",
    cName: "indiv",
    path: "/",
    id:"for-individuals"
  },
  {
    title: "How?",
    cName: "resources",
    path: "/",
    id:"resources"
  },
  {
    title: "Login",
    path: "/accounts/signin/all",
    cName: "login",
  },
  {
    title: "Get Started",
    path: "/accounts/signup/select",
    cName: "start",
  },
];

