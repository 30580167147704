import React, { useContext, useEffect, useState } from 'react';
import "./StoreConfigLayout.css";
import brandLogo from "../../images/logo_.png"
import Progress from '../Progress';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinUserContext } from '../../context/BusinUserContext';
import { addProduct, checkUserStore, configureUserStore, generateToken, setAffiliateRate } from '../../services/userService';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';


const StoreConfigLayout = ({ children, title, header, prevLink, nextLink }) => {
        const { configureStore, logo, address, description, product, productType, rate,  instagram,
                facebook,
                twitter,} = useContext(BusinUserContext)
        const location = useLocation()
        const navigate = useNavigate()
        const { userInfo } = useSelector(state => state.userLogin);
        const [loading,setIsLoading] = useState(false);

        useEffect(() => {
                if (!userInfo) {
                        return navigate("/accounts/signin/all")
                }
                else if (userInfo && userInfo?.accountType === "individual") {
                        return navigate("/accounts/affiliate/dashboard")
                } else if (userInfo && userInfo?.accountType === "business" && userInfo?.storeConfigured.toString() === "true") {
                        return navigate("/accounts/business/dashboard")
                }
        }, [userInfo, navigate])


        function navigateNext() {
                navigate(nextLink)
        }
        function navigatePrev() {
                navigate(prevLink)
        }

        function handleStoreSubmit() {
                setIsLoading(true) 
                const token = localStorage.getItem("netgroAuthToken")
                configureUserStore({ logo, address, description,socialMedia:{instagram,facebook,twitter}}, token).then(res => {
                        swal("Success", "Congrats your store has been created", "success")
                        configureStore(res.data)
                        setIsLoading(false)
                        navigateNext()
                }).catch(err => {
                        setIsLoading(false)
                        console.log(err)
                        if (err.response.status === 409) {
                                swal("",`${err.response.data}`, "success")
                                navigateNext()
                        } else return swal("",`${err.response.data}`, 'error')
                })
        }

        function handleAddProduct() {
                setIsLoading(true)
                const token = localStorage.getItem("netgroAuthToken")
                if (!product.image || !product.name || !product.description || !product.price) {
                        return toast.warn("Pls fill all fields below to proceed.")
                } else {
                        addProduct(!product.content ?
                                { name: product.name, price: product.price, description: product.description, image: product.image,numberInStock: product.numberInStock } :
                                { name: product.name, price: product.price, description: product.description, image: product.image, content: product.content,numberInStock: product.numberInStock }
                                , productType.physical ? "physical" : "digital", token).then(res => {
                                        setIsLoading(false)
                                        swal("", "Successfully added product", "success")
                                        navigateNext();
                                        console.log(res)
                                }).catch(err => {
                                        setIsLoading(false)
                                        console.log(err)
                                        console.log(typeof(err.response.data))
                                        swal("Something's wrong", err.response.data==='undefined'?'':err.response.data, 'error')
                                })
                }

        }


        function handleSetAffiliateRate() {
                const token = localStorage.getItem("netgroAuthToken")
                setAffiliateRate(rate, token).then(async res => {
                        swal("Successful", `${res.data}`, "success");
                        try {
                                const { data } = await generateToken(token)
                                console.log(data)
                                localStorage.setItem("netgroAuthToken", data);
                                return navigateNext()
                        } catch (err) {
                                console.log(err)
                        }
                     
                }).catch(err => {
                        console.log(err)
                        swal("", `${err.response.data}`, "error")
                })
        }

        return (
                <>
                        <div className='layout-container'>
                                <img src={brandLogo} alt="" className='layout-logo' />

                                {header ? <p style={{ color: "#354661", fontSize: "2rem" }}>{header}</p> :
                                        <>
                                                <h1>Hi {userInfo ? userInfo?.businessname : "Business name        "} 👋</h1>
                                                <p>Your new shining store is almost ready!
                                                        Add some basic info & a product</p>
                                                <Progress />
                                        </>

                                }



                                <h3 className='layout-title'>{title}</h3>
                                <div className='layout-wrapper'>
                                        {children}
                                        <br />
                                </div>

                                {location.pathname === "/configure-store-1" && <button className='next-btn' disabled={loading} onClick={handleStoreSubmit} >
                                     {loading?"Please Wait ...":"Next"}{" "}
                                     {!loading&& <i className='fa fa-arrow-right'></i>}
                                        </button>}


                                {location.pathname === "/add-product-1" &&
                                        <>
                                                <button className='prev-btn' onClick={navigatePrev}><i className='fa fa-arrow-left'></i>{" "}Previous</button>{" "}
                                                <button className='next-btn' type='submit' onClick={handleAddProduct}> {loading?"Please Wait ...":"Next"}{" "}
                                     {!loading&& <i className='fa fa-arrow-right'></i>}</button>
                                        </>
                                }

                                {location.pathname === "/set-affiliate-program-1" &&
                                        <>
                                                <button className='prev-btn' onClick={navigatePrev}><i className='fa fa-arrow-left'></i>{" "}Previous</button>{" "}
                                                <button className='next-btn' type="submit" onClick={handleSetAffiliateRate}> {loading?"Please Wait ...":"Next"}{" "}
                                     {!loading&& <i className='fa fa-arrow-right'></i>}</button>
                                        </>
                                }
                        </div>
                </>
        );
}

export default StoreConfigLayout;