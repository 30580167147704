import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './settings.styles.css';

const SettingsNavBar = () => {
    
    const [selected, setSelected] = useState(0);
    const Array = [
        {name: 'Profile', link: '/accounts/user/settings/'},
        {name: 'Business Info', link: '/accounts/user/settings/businessinfo'},
        {name: 'Affiliate', link: '/accounts/user/settings/affiliate'},
        {name: 'Payout', link: '/accounts/user/settings/payout'}
    ]
    return (
        <div className='settings_navbar_container'>
            <ul className='settings_lists'>
                {Array.map((arr,id) => (
                    <li className='settings_list' key={id} style={{ borderBottom: (selected==id?'2px solid green':'none') }}>
                        <div onClick={() => setSelected(id)} className='span'><Link to={arr.link}>{arr.name}</Link></div>
                    </li>
                ))}
            </ul>

        </div>

    )
}

export default SettingsNavBar;