import swal from "@sweetalert/with-react";
import React, { useContext, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editUserDetails, getAllBanks, getUserProfile, validateBankAccountDetails } from "../../../../../services/userService";
import { BusinUserContext } from "../../../../../context/BusinUserContext";
import useClickOutside from "../../../../../hooks/useClickOutside";

import "./style.css";


const AddAccountModal = ({ isModalOpen, modalRef, closeModal }) => {

    const [ allBanks, setAllBanks ] = useState([]);
    const { userInfo } = useSelector(state => state.userLogin);
    const { user, setUser } = useContext(BusinUserContext);
    const [ disabled, setDisabled ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);

    useClickOutside(modalRef, () => closeModal());

    useEffect(() => {

        if (!userInfo) return;

        getAllBanks("NG", userInfo.accountType, userInfo.token).then(res=>{
            setAllBanks(res.data);
        }).catch(err=>{
            console.log(err)
        })

    }, [userInfo])

    const handleInputChange = (e) => {
        
        const { name, value } = e.target;
        
        setDisabled(true);

        setUser(prevValue => {
            return {...prevValue, accountDetails: { ...prevValue["accountDetails"], [name]: value } }
        })
    }

    const handleBankSelection = (e) => {
        setDisabled(true);

        let { value } = e.target;
        
        const bankDetails = {...user.accountDetails};
        
        if(user.accountDetails.accountNumber.length !== 10) return toast.warn("Enter a valid account number!");
        
        bankDetails["accountName"] = "Verifying your account..."
        setUser({...user,accountDetails: bankDetails})
        const { code, name }  = JSON.parse(value);
        
        validateBankAccountDetails({account_number:user.accountDetails.accountNumber,account_bank:code},userInfo.accountType,userInfo.token).then(res=>{
            console.log(res)
            if(res.data.status === "success"){
                bankDetails["accountName"] = res.data.data.account_name
                bankDetails["bankName"] = name
                setUser({...user,accountDetails: bankDetails})
                toast.success(res.data.message,{position:"top-center"})
                setDisabled(false);
            }else{
                toast.error("Couldn't verify your account")
                value = null
                bankDetails["accountName"] = "Account Name"
                setUser({...user,accountDetails:bankDetails})
            }
        }).catch(err=>{
            console.log(err)
            toast.error("Something failed...")
            bankDetails["accountName"] = "Account Name"
            setUser({...user,accountDetails:bankDetails})
        })

    }

    const maxLengthCheck = (object) => {

        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const handleUpdateAccountBtnClick = () => {
        setDisabled(true);
        setLoading(true);

        editUserDetails(userInfo.token, userInfo.accountType, user).then(res => {
            
            setLoading(false);
            toast.success("Successfully updated details!");
            swal("", "Successfully updated bank details!", "success")
            closeModal();

        }).catch(err => {
            setDisabled(false);
            setLoading(false);
            toast.error("Bank details update failed...");
        })
    }

    return <>
        {
            isModalOpen && <div className="product__Modal__Overlay">
                <div className="product__Modal__Container add__Account__Modal" ref={modalRef}>
                    <GrFormClose className="close__Icon" onClick={() => closeModal()} />

                    <h3 className="title">Add Account</h3>
                    <p className="info__Text">Enter new account details</p>

                    <div className="account__Details__Container">
                        <input type={"number"} name={"accountNumber"} pattern="\d*" onInput={maxLengthCheck} placeholder={"Account Number"} minLength={0} maxLength={10} value={user.accountDetails.accountNumber} onChange={handleInputChange} />
                        
                        <select name="bankName" onChange={handleBankSelection}>
                            <option>Your Bank - {user.accountDetails.bankName}</option>
                            {
                                React.Children.toArray(allBanks.map(bank => {
                                    return <option value={ JSON.stringify({code:bank.code, name:bank.name}) }>{bank.name}</option>
                                }))
                            }
                        </select>
                        
                        <input type={"text"} name={"accountName"} readOnly={true} value={user.accountDetails.accountName} />    
                        
                        <button onClick={handleUpdateAccountBtnClick} disabled={disabled}>{isLoading ? "Please wait..." : "Update"}</button>
                    </div>

                </div>
            </div>
        }
    </>
}

export default AddAccountModal;
