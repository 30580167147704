import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { downloadProduct, getCustomerOrder } from '../../services/userService';
import "./styles.css";
import logo from "../../images/logo_.png"
import { toast } from 'react-toastify';
import { saveAs } from "file-saver";

function DownloadPage(props) {
const {orderId} = useParams()
const navigate = useNavigate()

const [products,setProducts] = useState([])
const [productUrl,setProductUrl] = useState('#')

useEffect(()=>{
getCustomerOrder(orderId).then(res=>{
    setProducts(res.data.products.filter(product=>product.type==="Digital"))
    console.log(products)
}).catch(err=>{
    console.log(err)
    // toast.error(!err.response.data?err.message:err.response.data)
    navigate("*")
})

},[]);

function handleDownload(productId,productName){
downloadProduct(orderId,productId).then(res=>{
//     console.log(res)
const fileExtension = res.data.substring(res.data.lastIndexOf("."));
saveAs(
   res.data,
   `${productName}${fileExtension}` 
)
}).catch(err=>{
    console.log(err)
    toast.error(!err.response.data?err.message:err.response.data)
})
}

    return (
<div className='download-container'>

<div className='box-1'>
<img src={logo} alt=""/>
<h4>Download Page</h4>
</div>


        {console.log(products)}
        {products.map(product=>(
        <div className='download-content' key={product.productId}>
            <img alt='' src={product.image_url}/>
            <p>{product.name}</p>
        <button onClick={()=>handleDownload(product.productId,product.name)}>
        Download
        </button>
        </div>
        ))}

<div className='box-2'>
<img src={logo} alt=""/> 
<span style={{color:"black"}}>Powered by netgro  | {" "}</span>
<span style={{cursor:"pointer"}} onClick={()=>navigate("/accounts/signup/select")}>Create your store or join to earn</span>
</div>

        </div>
    );
}

export default DownloadPage;