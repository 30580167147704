import "./style.css";


const LoadingSpinner = ({ color }) => {
    const customSpinnerColor = {
        borderTopColor: color ? color : "#38b6ff",
    }

    return <div id="loading" className="display" style={customSpinnerColor}></div>
}

export default LoadingSpinner;
