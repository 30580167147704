import React, { useState ,useEffect} from 'react';
import { Link,useParams,useNavigate } from 'react-router-dom';
import './verification.styles.css';
import Brand from './Brand.js';
import message from '../../images/message.svg';
import {useSelector} from "react-redux";

const VerificationNotification = () => {
const navigate = useNavigate()
    const {userInfo} = useSelector(state=>state.userLogin)
    useEffect(()=>{
if(userInfo) return navigate("/accounts/signin/all")
    },[])
    
    const [verified, setVerified] = useState(false)
  const {email} = useParams()

    return (
        <div className='ver-container'>
            <div className='ver-wrapper'>
                <div className='ver-brand'>
                    <Brand />
                </div>
                <div className='note-wrapper'>
                    <div className='note-content'>
                        <div className='icon'>
                            <div className='icon-content'>
                                <img src={message} />
                            </div>
                        </div>
                        
                        <span className='ver-big-text'>Verify your account</span>
                        <span className='ver-med-text'> Please check the email sent to</span>
                        <span className='ver-med-text'><Link to="/accounts/verify/email-notify" style={{color: '#34A853'}}>{email}</Link></span>
                        <span className='ver-med-text'> Click the very link to activate your account</span>
                        <span className='ver-med-text'>Didn’t get the email? </span>
                        <span className='ver-med-text'><Link to="#" style={{color: '#34A853'}}>click to resend {" "}</Link>{" "} or check spam</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VerificationNotification;