import "./style.css";

const AnalyticsCard = ({ analyticTitle, analyticIcon, analyticValue, hasDoubleIcons, hasAdditionalInfo, additionalInfo, additionalInfoIcon, handleCardItemClick, dataInfo,analyticValue2,analyticValue3}) => {
    return <>
        <div className="business__Analytics__Card">
            <div className="card__Title">{analyticTitle}</div>
            <div className="card__Info">
                {
                    hasDoubleIcons ? 
                    
                    <>
                    <div className="double__Icons__Container">
                        {analyticIcon.firstIcon}
                        {analyticIcon.secondIcon}
                    </div> 
                    
                    {analyticValue} 
                 </> : 
                    
                    <>{dataInfo?
                    <div style={{fontSize:"1rem", lineHeight:"20px", fontWeight:"300"}}>
                   <span> {analyticValue}</span>
                    <br/>
                    <span>{analyticValue2} - {analyticValue3}</span>
                    </div>
                    :
                    <>
                    {analyticIcon} 
                    {analyticValue}
                    </>
                    }
                   </>
                }
            </div>

            { 
                hasAdditionalInfo && <>
                    <hr />
                    <div className="additional__Card__Info" onClick={handleCardItemClick}>
                        {additionalInfo}
                        {additionalInfoIcon}
                    </div>
                </>
            }
        </div>
    </>
}

export default AnalyticsCard;
