import React, { useState, useEffect } from "react";
import DashboardSidebar from "./dashboard_sidebar";
import shopCart from '../../images/shopping_cart.svg';
import user_avatar from '../../images/profile_user.svg';
import notification_bell from '../../images/notification_bell.svg';
import double from '../../images/double.svg';
// import "./affiliate_dashboard.styles.css";
import DashboardHome from './dashboard_home';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const AffiliateDashboard = () => {

    const [full, setFull] = useState(true);
    
    const handleChange = () => {
        setFull(!full);
    }
    useEffect(() => {
        return () => {
            window.addEventListener("resize", () => {
              if (window.innerWidth <= 500) setIsMobile(true);
              else setIsMobile(false);
            });
        };
    });
    
    const [isMobile, setIsMobile] = useState(false);
    const url = 'https:netgro.co/ladybstore';
    return (
        <div className="affiliate_container">
            {/* <div className="side_bar_wrapper" style={{width: (full ? '80px' : '250px')}}> */}
            <DashboardSidebar handleChange={handleChange} full={full} user={false} />
            {/* //style={{ width: (full ? "100%" : "90%")}} */}
            <div className="affiliate_wrapper" style={{ width: (isMobile ? '100%' : (full ? "95%" : "90%"))}}>
                <div className="affiliate_content">
                    <div className="dash_top">
                        <h3>Hi LadyB Store      👋</h3>
                        
                        <div className="right_top">
                            <img src={notification_bell} className='img' />
                            <img src={user_avatar} className='img' />
                        </div>
                    </div>
                    <DashboardHome />
                </div>
            </div>
        </div>
    )
}

export default AffiliateDashboard;