import React, { useContext, useEffect, useState } from 'react';
import AffiliateDashboardLayout from '../../../components/dashboard/affiliate/AffiliateDashboardLayout';
import {MdGroups, MdSentimentSatisfied} from "react-icons/md"
import {IoMdCopy} from "react-icons/io"
import { getAllStores, getStore } from '../../../services/userService';
import { useSelector } from 'react-redux';
import usePagination from "../../../hooks/usePagination"
import PaginateButton from '../../../components/PaginateButton';
import { toast } from 'react-toastify';
import { IndivUserContext } from '../../../context/IndivUserContext';

const Brand = () => {
    const {userInfo} = useSelector(state=>state.userLogin);
  
const [brands,setBrands] = useState([])
const [page, setPage] = useState(1);
const numberPerDisplay = 6;
const { slice, range } = usePagination(brands, page, numberPerDisplay);
const [brandToSearch,setBrandToSearch] = useState("")

    useEffect(()=>{
        if(userInfo){
        if(brandToSearch.length===0){
getAllStores(userInfo.token).then(res=>{
    console.log(res)
setBrands(res.data)
}).catch(err=>{
    console.log(err)
})
        }
        }
    },[userInfo,brandToSearch])

    function handleCopyToClipboard(text){
        navigator.clipboard.writeText(text);
        toast.success("Successfully copied to clipboard",{position:'top-center'})
        console.log(text)
    }

  

    function handleBrandSearch(e){
        e.preventDefault();
        if(brandToSearch.length>=1&&userInfo.token){
            getStore(brandToSearch,userInfo.token).then(res=>{
                console.log(res)
                setBrands(res.data)
            }).catch(err=>{
                console.log(err)
            })
        }
       
    }

    
    return ( 
        <>
        <AffiliateDashboardLayout>
       {userInfo?.username&&
       <>
       <form onSubmit={handleBrandSearch}>
       <div className='brands-first-section'>
        <h2>Brands</h2>
     
<input placeholder='Search' value={brandToSearch} onChange={({target})=>setBrandToSearch(target.value)}/>


        </div>
        </form> 
        {slice.length===0&&<div style={{marginTop:"60px"}}>
            {brandToSearch.length>=1 ?
            <p style={{textAlign:"center",margin:"0 auto"}}>No result for your search.</p>:
           <p  style={{textAlign:"center",margin:"0 auto"}}>No Brands for now.</p>  }
        </div>}
        <div className='brands-second-section'>
        
        {slice.map((item,idx)=>(
    <div className='brands-content' key={idx}>
    <img  src={item.logo_url} alt=""/>
    <div className='brands-details'>
    <h4>{item.owner ? item.owner.businessname : ""}</h4>
    <p>{item.description}</p>
    <div className='group-comission'>
    <MdGroups style={{float:"left", width:"40px", height:"40px", paddingLeft:"33px"}}/>
    <span>{item.affiliateRate}% commision</span>
    </div>
    <div className='store-url'>
    <IoMdCopy className='copy-img' onClick={()=>handleCopyToClipboard(`https://netgro.co/store/${item.owner ? item.owner.username : ""}/${userInfo.username}`)}/>
    <a href="#"> Store url: https://netgro.co/store/{item.owner ? item.owner.username : ""}/{userInfo.username}</a>
   
    </div>
    </div>
    
    </div>
))}
        </div>

        <PaginateButton
              range={range}
          slice={slice}
          setPage={setPage}
          page={page}
        />
         </>
}
        </AffiliateDashboardLayout>
       
        </>
     );
}
 
export default Brand;