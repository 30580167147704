import React from 'react';
import './new_products.styles.css';
import upload from '../../../images/cloud-upload.svg'

const NewProducts = () => {
    return ( 
        <div className='newproduct_container'>
            <h3>Add a Product</h3>
            <div className='newproduct_wrapper'>
            <div className='add-product-container'>
                <label for="input-1">
                    <div className='img-upload'>
                        <img src={upload} />
                        <span>Upload product image (500px by 500px recommended)</span>
                    </div>
                </label>
                <input type={"file"} id="input-1" className="upload-1"/>
                
                <br/>
                <input type={"text"} placeholder={"Name"} className="input-1"/>
                <br/>
                <textarea placeholder='Description' className='input-2'/>

                <div className='product-type'>
                    <h5>Type:</h5>
                    <br/>
                    <div style={{paddingTop:"20px"}}>
                        <input type={"radio"}/>{" "}
                        <label>Physical</label>
                        <input type={"radio"} className="digital-radio-box"/>{" "}
                        <label>Digital</label>
                    </div>
                
                </div>
                <br/>
                <label for="input-2">
                    <div className='img-upload'>
                        <img src={upload} />
                        <span>Upload digital file of product (pdf, mp3, mp4, zip,  jpg)</span>
                    </div>
                </label>
                <input type={"file"} id="input-2" className="upload-1"/>
               
                <br/>
                <div className='price-settings'>
                    <select disabled>
                        <option >NGN</option>
                    </select>
                    <input type={"text"}/>
                </div>
                <br/>
                <div className='newproduct_base'>
                    <button>Add Product</button>
                </div>
            </div>
            </div>
        </div>
     );
}
 
export default NewProducts;