import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IndivUserContext } from "../../../../context/IndivUserContext"
import { editUserDetails, getAllBanks, validateBankAccountDetails } from "../../../../services/userService";
import AffliateSettings from "./Settings";


const AffliatePayoutPage = () => {

    const { user, setUser } = useContext(IndivUserContext);
    const { userInfo } = useSelector(state => state.userLogin);
    const [ loading, setLoading ] = useState(false);
    const [ allBanks, setAllBanks ] = useState([]);
    const [ disabled, setDisabled ] = useState(false);

    useEffect(() => {
        
        if (!userInfo) return;

        getAllBanks("individual","NG", userInfo.token).then(res => {
            setAllBanks(res.data);
        }).catch(err => {
            console.log(err);
        })

    }, [userInfo])

    const handleInputChange = (e) => {

        const { name, value } = e.target;
        
        setDisabled(true);

        setUser(prevValue => {
            return {...prevValue, accountDetails: { ...prevValue["accountDetails"], [name]: value } }
        })
    }

    const handleBankSelect = (e) => {
        setDisabled(true);

        let { value } = e.target;
        
        const bankDetails = {...user.accountDetails};
        
        if(user.accountDetails.accountNumber.length !== 10) return toast.warn("Enter a valid account number!");
        
        bankDetails["accountName"] = "Verifying your account..."
        setUser({...user,accountDetails: bankDetails})
        const { code, name }  = JSON.parse(value);
        
        validateBankAccountDetails("individual",{account_number:user.accountDetails.accountNumber,account_bank:code},userInfo.token).then(res=>{
            if(res.data.status === "success"){
                bankDetails["accountName"] = res.data.data.account_name
                bankDetails["bankName"] = name
                bankDetails["bankCode"]=code
                setUser({...user,accountDetails: bankDetails})
                toast.success(res.data.message,{position:"top-center"})
                setDisabled(false);
            }else{
                toast.error("Couldn't verify your account")
                value = null
                bankDetails["accountName"] = "Account Name"
                setUser({...user,accountDetails:bankDetails})
            }
        }).catch(err=>{
            console.log(err)
            toast.error("Something failed...")
            bankDetails["accountName"] = "Account Name"
            setUser({...user,accountDetails:bankDetails})
        })

    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const handleUpdatePayoutDetails = (e) => {
        e.preventDefault();
        setDisabled(true);
        setLoading(true);

        editUserDetails(userInfo.token, userInfo.accountType, user).then(res => {
            setLoading(false);
            setDisabled(false);
            toast.success("Successfully updated details!");
        }).catch(err => {
            setLoading(false);
            setDisabled(false);
            toast.error("Bank details update failed...")
        })
        console.log(user);
    }

    return <>
    <AffliateSettings>
    <div className='indiv-profile-form'>
            <h1>Payout</h1>
            {console.log(user)}
            <form>
                <input 
                    placeholder="Account number" 
                    type={"number"} 
                    name={"accountNumber"}
                    minLength={10} 
                    maxLength={10}
                    value={user.accountDetails.accountNumber && user.accountDetails.accountNumber}
                    onInput={maxLengthCheck}
                    onChange={handleInputChange}
                />
                <br />
                <select name="bankName" className="banks-select" onChange={handleBankSelect}>
                    <option>{user.accountDetails.bankName && user.accountDetails.bankName.length > 1 ? `Your Bank - ${user.accountDetails.bankName}` : "Select Bank"}</option>
                    {
                        React.Children.toArray(allBanks.map(bank => {
                            return <option value={ JSON.stringify({code: bank.code, name: bank.name}) }>{bank.name}</option>
                        }))
                    }
                </select>
                <br />
                <input 
                    placeholder="Account name" 
                    name="accountName"
                    type={"text"}
                    disabled 
                    style={{background:"white"}}
                    readOnly={true}
                    value={user.accountDetails.accountName && user.accountDetails.accountName}
                />
                <br />
                <button type="submit" disabled={disabled} onClick={handleUpdatePayoutDetails}>{loading ? "Please wait..." : "Update"}</button>
            </form>
        </div>

    </AffliateSettings>
           </>
}

export default AffliatePayoutPage;
