import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BusinessRegistration from './pages/registration/business';
import IndividualRegistration from "./pages/registration/individual";
import RegistrationPage from './pages/registration';
import Login from './pages/login';
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import UserDashboard from './pages/dashboard/user_dashboard';
import AffiliateDashboard from './pages/dashboard/affiliate_dashboard';
import Verification from './pages/verification/verification';
import EmailNotification from './pages/verification/email_notification';
import EmailVerificationSuccess from './pages/verification/email_verification_success';
import HomePage from './pages/home/HomePage';
import StorePage from './pages/stores/StorePage';
import StoreConfigPageOne from './pages/stores/config/StoreConfigPageOne';
import StoreConfigPageTwo from './pages/stores/config/StoreConfigPageTwo';
import StoreConfigPageThree from './pages/stores/config/StoreConfigPageThree';
import SuccessfulSetup from './pages/stores/config/SuccessfulSetup';
import EmailVerificationPage from './pages/verification/EmailVerificationPage';
import AffiliateDashboardHome from './pages/dashboard/affiliate';
import BusinessDashboardHome from './pages/dashboard/business';
import VerificationNotification from "./pages/verification/VerificationNotification"
import Profile from "./pages/dashboard/business/settings/profile"
import BusinessInfo from './pages/dashboard/business/settings/businessinfo';
import Affiliate from './pages/dashboard/business/settings/affiliate';
import Payout from './pages/dashboard/business/settings/payout';
import AllProducts from './pages/dashboard/business/products/all_products';
import NewProducts from './pages/dashboard/business/products/new_products';
import EditProductPage from './pages/dashboard/business/products/EditProductPage';
import NotFoundPage from './pages/NotFoundPage';
import ProfileSettingsPage from './pages/dashboard/affiliate/settings/ProfileSettingsPage';
import Brand from './pages/dashboard/affiliate/Brands';
import BusinessAnalytics from './pages/dashboard/business/analytics/Analytics';
import AffliateAnalytics from './pages/dashboard/affiliate/analytics/Analytics';
import Orders from './pages/dashboard/business/orders/Orders';
import  OrdersAff from './pages/dashboard/affiliate/orders/OrdersAff';
import Wallet from './pages/dashboard/business/wallet/wallet';
import OrderDetails from './pages/dashboard/business/orders/OrderDetails';
import  OrderDetailsAff from './pages/dashboard/affiliate/orders/OrderDetailsAff';
import AffiliateAnalytics from "./pages/dashboard/affiliate/analytics/Analytics"
import WalletNew from './pages/dashboard/business/wallet/walletNew';
import AffiliateWallet from './pages/dashboard/affiliate/wallet/wallet';
import AffliateSettings from './pages/dashboard/affiliate/settings';
import DownloadPage from './pages/download/DownloadPage';
import AffliatePayoutPage from './pages/dashboard/affiliate/settings/payout';
import DeliveryConfirmation from './pages/deliveryConfirmation/DeliveryConfirmation';
import DeliveryFeedback from './pages/deliveryConfirmation/DeliveryFeedback';
// import SearchPage from './pages/search';


const App = () => {
  return (
    <>
      <HelmetProvider>
        <ToastContainer />
        <Routes>
        <Route path='*' element={<NotFoundPage />} />
          <Route path="/" exact element={<HomePage />} />
          <Route
            path="/accounts/signup/select"
            exact
            element={<RegistrationPage />}
          />
          <Route
            path="/accounts/individual/signup"
            exact
            element={<IndividualRegistration />}
          />
          <Route
            path="/accounts/business/signup"
            exact
            element={<BusinessRegistration />}
          />

          <Route
            path="/accounts/user/*"
            exact
            element={<UserDashboard />}
          />
          <Route
            path="/accounts/affiliate"
            exact
            element={<AffiliateDashboard />}
          />
          <Route
            path="/accounts/email_notification"
            exact
            element={<EmailNotification />}
          />
           <Route
            path="/accounts/verify_email"
            exact
            element={<Verification />}
          />
          <Route
            path="/accounts/verify_email/success"
            exact
            element={<EmailVerificationSuccess />}
          />
          
          
          {/* individual analytics page */}
            <Route path='/accounts/individual/dashboard/analytics' exact element={<AffliateAnalytics />} />
          {/** end of individual analytics page */}

          {/* routes for business user's dashboard */}
          <Route
            path="/accounts/business/dashboard"
            exact
            element={<BusinessDashboardHome/>}
          />
          <Route
            path="/accounts/business/dashboard/home"
            exact
            element={<BusinessDashboardHome/>}
          />

          {/* settings page for business dahboard */}
<Route
  path='/accounts/business/dashboard/settings'
  exact
  element={<Profile/>}
/>
<Route
  path='/accounts/business/dashboard/settings/profile'
  exact
  element={<Profile/>}
/>

<Route
 path='/accounts/business/dashboard/settings/business-info'
  exact
  element={<BusinessInfo/>}
/>
<Route
 path='/accounts/business/dashboard/settings/affiliate'
  exact
  element={<Affiliate/>}
/>

<Route
 path='/accounts/business/dashboard/settings/payout'
  exact
  element={<Payout/>}
/>

<Route
 path='/accounts/individual/dashboard/settings/payout'
  exact
  element={<AffliatePayoutPage/>}
/>

<Route
 path='/accounts/individual/dashboard/settings'
  exact
  element={<ProfileSettingsPage/>}
/>

<Route
 path='/accounts/individual/dashboard/settings/profile'
  exact
  element={<ProfileSettingsPage/>}
/>
{/* end of business settings routes */}

{/* business analytics page */}
<Route path='/accounts/business/dashboard/analytics' exact element={<BusinessAnalytics />} />
{/** end of business analytics page */}

{/* business user route to add products and view all products */}
{/* business user  */}
<Route
  path='/accounts/business/dashboard/products'
  exact
  element={<AllProducts/>}
/>

<Route
  path='/accounts/business/dashboard/products/new'
  exact
  element={<NewProducts/>}
/>

<Route
  path='/accounts/business/dashboard/:productId/edit'
  element={<EditProductPage/>}
/>

<Route
  exact
  path='/accounts/business/dashboard/wallet'
  element={<WalletNew/>}
/>

<Route
  exact
  path='/accounts/individual/dashboard/wallet'
  element={<AffiliateWallet/>}
/>
{/* end */}


{/* analytics page for both biz and individual users */}
<Route
  path="/accounts/business/dashboard/analytics"
  exact
  element={<BusinessAnalytics/>}
/>

<Route
  path="/accounts/individual/dashboard/analytics"
  exact
  element={<AffiliateAnalytics/>}
/>

<Route
  path='/accounts/individual/dashboard/brands'
  exact
  element={<Brand />}
/>

<Route
  path='/accounts/individual/dashboard/settings'
  exact
  element={<AffliateSettings/>}
/>

<Route
  path='/accounts/individual/dashboard/settings/:section'
  exact
  element={<AffliateSettings/>}
/>

<Route 
  path='/accounts/individual/dashboard/wallet'
  exact
  element={<AffiliateWallet />}
/>
{/* end */}


{/* business user route to view all orders and a particular order */}
<Route
  path='/accounts/business/dashboard/orders'
  exact
  element={<Orders/>}
/>

<Route
  path='/accounts/individual/dashboard/orders'
  exact
  element={<OrdersAff/>}
/>

<Route
  path='/accounts/business/dashboard/order-details/:orderId'
  exact
  element={<OrderDetails/>}
/>

<Route
 path='/accounts/individual/dashboard/order-details/:orderId'
 exact
  element={<OrderDetailsAff/>}/>
{/* end */}


          <Route
            path="/accounts/individual/dashboard/home"
            exact
            element={<AffiliateDashboardHome/>}
          />
    <Route
            path="/accounts/individual/dashboard"
            exact
            element={<AffiliateDashboardHome/>}
          />
          <Route path="/accounts/signin/all" exact element={<Login />} />

          <Route path="/:storeName" element={<StorePage />} />
          <Route path="/:storeName/product/:productId" element={<StorePage />} />
          <Route path="/store/:storeName/:referrer" element={<StorePage />} />
          <Route path="/store/:storeName/product/:productId/:referrer" element={<StorePage />} />
          {/* <Route path='/:storeName/search/:product' element={<SearchPage/>} /> */}

          <Route path="/accounts/verify-account/:email" exact element={<VerificationNotification/>}/>
          <Route path="/accounts/verify-page/:verificationToken" exact element={<EmailVerificationPage/>}/>
        
        <Route path='/download/:orderId' exact element={<DownloadPage/>}/>
        <Route path="/delivery-confirmation/:orderId" exact element={<DeliveryConfirmation/>}/>
        <Route path="/delivery-feedback/:orderId" exact element={<DeliveryFeedback/>}/>


          {/* onboarding routes */}
          <Route path='/configure-store-1' exact element={<StoreConfigPageOne />} />
          <Route path='/add-product-1' exact element={<StoreConfigPageTwo />} />
          <Route path='/set-affiliate-program-1' exact element={<StoreConfigPageThree />} />

          <Route path="/sucessful-setup" exact element={<SuccessfulSetup/>} />
        </Routes>
      </HelmetProvider>
    </>
  );
}

export default App;