import React, { useState, useEffect } from 'react';
import Navbar from "../../components/navbar/Navbar";
import PageTitle from "../../components/PageTitle";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";
import youngMan from "../../images/young_man.png";
import groupImg from "../../images/affiliates.png";
import "./homepage_res.css";
import Footer from '../../components/footer/Footer';
import woman from "../../images/business-netgro.png";
import network from "../../images/network.png";
import store from "../../images/store.png";
import wallet from "../../images/wallet.png";
import { useSelector } from 'react-redux';

const HomePage = () => {
  const { userInfo } = useSelector(state => state.userLogin);
  let navigate = useNavigate();
  // useEffect(() => {
  //   sessionStorage.removeItem("usr_email");
  //   if(userInfo && userInfo?.accountType==="business"){
  //     return navigate("/accounts/business/dashboard")
  //   }else if(userInfo && userInfo?.accountType==="individual"){
  //     return navigate("/accounts/individual/dashboard")
  //   }
  // }, [userInfo,navigate]);

  const [email, setEmail] = useState("");



  function handleChange({ target }) {
    setEmail(target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    sessionStorage.setItem("usr_email", email);
    navigate("/accounts/signup/select");
  }

  /**stat for the FAQ answered**/
  const [showAnsOne, setShowAnsOne] = useState(false);
  const [showAnsTwo, setShowAnsTwo] = useState(false);
  const [showAnsThree, setShowAnsThree] = useState(false);
  const [showAnsFour, setShowAnsFour] = useState(false);
  const [showAnsFive, setShowAnsFive] = useState(false);

  function showAnsHandler(value, setter) {
    setter(!value)
  }


  return (
    <>
      <PageTitle title={"Netgro | A democratized way of selling and earning big."} />
      <Navbar />
      <div className="home-banner">
        <div className="home-bannner-text">
          {/* <h2>{userInfo?.fullname}</h2> */}
          <h1>A new way to grow business and <span>earn big.</span></h1>
          <p>Smart commerce tools + strong affiliate networks to drive sales quickly.</p>
        </div>

        <div className="home-banner-img">
          <img src={youngMan} alt="netro hero image" width={600} height={500} />
        </div>

        <div className="home-banner-register">
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder='Enter email to get started' onChange={handleChange} />
            <button type="submit">Register</button>
          </form>
        </div>
      </div>

      <div className='box_2' style={{ textAlign: "center" }}>
        <h1>Why netgro?</h1>
        <p>Omnichannel social commerce platform to help creators, publishers, and businesses sell and earn more from the modern partnership economy.</p>
      </div>
      <br />
      <br />

      <div className='box_3' id="for-business">
        <div className='box_3_section_1'>
          <h1>For businesses</h1>
          <p>
            All the tools you need to setup your online store and start
            selling fast with a growing network of enthusiastic affiliates and influencers.
          </p>
          <br />
          <button className='start_selling' onClick={() => navigate("/accounts/business/signup")}>
            Start Selling
          </button>
        </div>

        <div className='box_3_section_2'>
          <img src={woman} alt="" />
        </div>
        {/* <div style={{textAlign:"center", paddingTop:"5px"}}>
            <button className='display_btn_1'></button>{" "}<button className='display_btn_2'></button>
            </div> */}
      </div>


      <div className='box_4' id="for-individuals">
        <div className='box_4_section_1'>
          <img src={groupImg} alt="" />
        </div>

        <div className='box_4_section_2'>
          <br />
          <br />
          <br />
          <h1>For Individuals</h1>
          <p>
          The opportunities to earn are limitless. You get paid for talking about the brands & products you love. 
          </p>
          <br />
          <button className='start_earning' onClick={() => navigate("/accounts/individual/signup")}>
            Start Earning
          </button>
        </div>
      </div>


      <div class="box_5">
        <h1>Tools for Growth</h1>
        <div className='box_5_content' >
          <br />
          <div className='box_5_container'>
            <div className="box_5_card">
              <img src={store} alt="" />
              <br />
              <h3 className="card_1_title">Store</h3>
              <p>
                Sell anything digital or a physical product like a makeup kit for the ladies. 😍

              </p>
            </div>


            <div className="box_5_card">
              <img src={wallet} alt="" />
              <br />
              <h3 className="card_2_title">Wallet</h3>
              <p>We help you bank your sales or earnings and you cash out whenever you like.</p>
            </div>


            <div className="box_5_card">
              <img src={network} alt="" />
              <br />
              <h3 className="card_3_title">Affiliate System</h3>
              <p>Who pays you for talking about your favourite products to a friend? we do!</p>
            </div>
          </div>

          {/* <div style={{textAlign:"center", paddingTop:"10px"}}>
            <button className='display_btn_1'></button>{" "}<button className='display_btn_2'></button>
            </div> */}
        </div>

      </div>
      <div className='box_6' id='resources'>
        <br />
        <section>
          <h1 className="title">FAQS</h1>

          <div className='faq' onClick={() => showAnsHandler(showAnsOne, setShowAnsOne)}>
            <div className='question'>
              <h3 style={{ color: showAnsOne && "#34A853" }}>Do I pay to signup as a business?</h3>
              <i className={showAnsOne ? "fa fa-minus" : 'fa fa-plus'} aria-hidden="true" style={{ color: showAnsOne && "#34A853" }}></i>
            </div>
            <div className='answer' style={{ maxHeight: showAnsOne && "300px" }}>
              <p>No signup fees, no renewal fees, we only take a 2% for every successful order</p>
            </div>
          </div>

          <div className='faq' onClick={() => showAnsHandler(showAnsTwo, setShowAnsTwo)}>
            <div className='question'>
              <h3 style={{ color: showAnsTwo && "#34A853" }}>Do I pay to signup as an affiliate?</h3>
              <i className={showAnsTwo ? "fa fa-minus" : 'fa fa-plus'} aria-hidden="true" style={{ color: showAnsTwo && "#34A853" }}></i>
            </div>
            <div className='answer' style={{ maxHeight: showAnsTwo && "300px" }}>
              <p>No signup fees, no renewal fees, we only take a 5% for every successful payout from your affiliate earning</p>
            </div>
          </div>


          <div className='faq' onClick={() => showAnsHandler(showAnsThree, setShowAnsThree)}>
            <div className='question'>
              <h3 style={{ color: showAnsThree && "#34A853" }}>How do I earn as an affiliate?</h3>
              <i className={showAnsThree ? "fa fa-minus" : 'fa fa-plus'} aria-hidden="true" style={{ color: showAnsThree && "#34A853" }}></i>
            </div>

            <div className='answer' style={{ maxHeight: showAnsThree && "300px" }}>
              <p>You earn when a customer buys a product from the brand(s) you are promoting based on the commission set.</p>
            </div>
          </div>


          <div className='faq' onClick={() => showAnsHandler(showAnsFour, setShowAnsFour)}>
            <div className='question'>
              <h3 style={{ color: showAnsFour && "#34A853" }}>Can I sell for multiple brands ?</h3>
              <i className={showAnsFour ? "fa fa-minus" : 'fa fa-plus'} aria-hidden="true" style={{ color: showAnsFour && "#34A853" }}></i>
            </div>
            <div className='answer' style={{ maxHeight: showAnsFour && "300px" }}>
              <p>Yes, absolutely! You can sell for as many brand you want, the power to earn is yours!</p>
            </div>
          </div>



          <div className='faq' onClick={() => showAnsHandler(showAnsFive, setShowAnsFive)}>
            <div className='question'>
              <h3 style={{ color: showAnsFive && "#34A853" }}>What kind of products can I sell?</h3>&nbsp;
              <i className={showAnsFive ? "fa fa-minus" : 'fa fa-plus'} aria-hidden="true" style={{ color: showAnsFive && "#34A853" }}></i>
            </div>
            <div className='answer' style={{ maxHeight: showAnsFive && "300px" }}>
              <p>You can sell either digital or physical products on this platform, currently.</p>
            </div>

          </div>



        </section>
        <br />
      </div>


      <div class="box_7" style={{ textAlign: "center" }}>
        <h1>
          A democratized way of selling and earning big
        </h1>
        <br />
        <button onClick={() => navigate("/accounts/signup/select")}>Get Started</button>
      </div>

      <Footer />
    </>
  );
}

export default HomePage;