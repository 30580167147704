import { GrFormClose } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";
import useClickOutside from "../../../hooks/useClickOutside";

import "./style.css";
import { useState } from "react";
import swal from "@sweetalert/with-react";


const ConfirmModal = ({ modalTitle, questionText, confirmButtonText, isModalOpen, modalRef, confirmAction, closeModal }) => {

    const [ disabled, setDisabled ] = useState(false);
    useClickOutside(modalRef, () => closeModal());

    const handleConfirmButtonClick = () => {
        setDisabled(true);
        confirmAction();
        swal("", "Successfully deleted product!", "success")
        setDisabled(false);
    }

    return <>
        { 
            isModalOpen && <div className="product__Modal__Overlay">
            <div className="product__Modal__Container confirm__Modal" ref={modalRef}>
                <GrFormClose className="close__Icon" onClick={(closeModal)} />

                <BsTrash className="confirm__Icon" />

                <h3 className="title">{modalTitle}</h3>
                <p className="confirm__Modal__Text">{questionText}</p>
                <div className="modal__Btns__Container">
                    <button className="modal__Cancel__Btn" onClick={(closeModal)}>Cancel</button>
                    <button className="modal__Confirm__Btn" disabled={disabled} onClick={(handleConfirmButtonClick)}>{confirmButtonText}</button>
                </div>
            </div>
        </div>
        }
    </>
}

export default ConfirmModal;
