import swal from '@sweetalert/with-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmOrder, getCustomerOrder } from '../../services/userService';
import DeliveryConfirmationLayout from './Layout';

function DeliveryConfirmation(props) {
    const [order,setOrder] = useState({
        _id:"",
        user:{name:""},
        products:[]
    });

    const navigate = useNavigate()
    const {orderId} = useParams()
    useEffect(()=>{
getCustomerOrder(orderId).then(res=>{
console.log(res);
setOrder(res.data)
}).catch(err=>{
    console.log(err)
    navigate("*")
})
    },[])

    function handleBtnClick(){
      const data =  window.prompt("Are you sure, yes or no?")
      if(data.toLowerCase()==="yes"){
            confirmOrder(order._id).then(res=>{
                swal("",res.data,"success")
            // toast.success(res.data)
            navigate(`/delivery-feedback/${order._id}`)
            console.log(res)
            }).catch(err=>{
                console.log(err)
                toast.error(!err.response.data?err.message:err.response.data)
            })
        }
    }
    return (
        <DeliveryConfirmationLayout name={order.user.name}>
<div className='delivery-confirmation'>
            <h3>Orders ID #${orderId.substring(0,7)}</h3>

            <div className='delivered-products-container'>  
            <div className='delivered-products'>

            {order.products.map((product,idx)=>(
<div className='delivered-product' key={idx}>
<img src={product.image_url} alt=""/>
<div className='delivered-product-detail'>
<h5>{product.name}</h5>
<h5>Qty: {product.qty}</h5>
<h5>Cost: &#8358;{product.qty*product.price}</h5>
</div>
</div>
            ))}   
            </div>
</div>

<button onClick={()=>handleBtnClick()}>Yes</button>
            </div>
        </DeliveryConfirmationLayout>
        
    );
}

export default DeliveryConfirmation;