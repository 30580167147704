import React, {useContext, useEffect} from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { CartContext } from '../context/CartContext';
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { BiCart } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import cart from "../images/cartEmpty.png";

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width:"70%",
    height:"auto",
    transform: 'translate(-50%, -50%)',
    borderRadius: "10px"
  },
  
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
    position: "fixed",
    zIndex: "1000000"
},
};

const StoreNavBar = ({toggleCart,owner,store}) => {
  const {cartItems} = useSelector(state=>state.cart);
  const {showCart,setShowCart} = useContext(CartContext);
  const location = useLocation();


  
  Modal.setAppElement('#root');

    const [modalOpen, setModalOpen] = React.useState(false);

//     useEffect(()=>{
// setModalOpen(true)
//     },[])
    function openModal() {
setModalOpen(true)
}


      function closeModal() {
        setModalOpen(false);
      }

      function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
      }
    return ( 
        <>
            <div className='store-navbar'>
            <i class="fa fa-question-circle question-icon fa-2x" aria-hidden="true" onClick={openModal}></i>
            <div className='store-navbar-content'>
           <a href='#' onClick={openModal}> <i className="fa fa-question-circle" aria-hidden="true"></i>About</a>
            <div className='store-title'>
            <div className="store-img">
          <img src={store.logo_url}/>
            </div>
            <h1 style={{textAlign:"center", marginTop:"-10px", paddingBottom:"4px"}}>{owner}</h1>
            <p style={{marginTop:"-20px", fontWeight:"lighter",fontSize:"20px",textAlign:"center"}}>Welcome to our Store</p>
            </div>
           
            {/* <i class="fa fa-shopping-cart fa-2x" aria-hidden="true" style={{color:"#545454"}} onClick={toggleCart}></i> */}
            <span><img src={cart} alt="" width={40} onClick={()=>setShowCart(true)} style={{cursor:"pointer"}}/>{cartItems.reduce((acc,item)=> acc+item.qty,0)}</span>
            </div>
            </div>

            <Modal
        isOpen={modalOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"    
      >
      <div className="modal-container">
        <button onClick={closeModal}><IoCloseOutline className='close__Icon' /></button>
        <h2 style={{textAlign:"center", paddingBottom:"10px"}}>About</h2>
        <hr/>
        <br/>
        <div className="modal-content">
        <h4>Address:</h4>
       <p>{store.address}</p>  
       <br/> 
        <h4>Description:</h4>
       <p>{store.description}</p>  
       <br/>
       <h4>Store email address:</h4>
       <p>{store.owner.email}</p>
       <br/>
       <h4>Store mobile number</h4>
       <p>{store.owner.phonenumber}</p>
<br/>
<div>   
<a href={store.socialMedia.facebook}  target={"_blank"}>
<i class="fa fa-facebook fa-2x" aria-hidden="true" style={{color:"#545454"}}></i>
</a>
<a href={store.socialMedia.instagram}  target={"_blank"}>
            <i class="fa fa-instagram fa-2x"  aria-hidden="true" style={{color:"#545454"}}></i>
</a>
      <a href={store.socialMedia.twitter}  target={"_blank"}>
            <i class="fa fa-twitter fa-2x" aria-hidden="true" style={{color:"#545454"}}></i>     
      </a>
        </div>
        </div> 
       </div>
        <br/>
      </Modal>

        </>
     );
}
 
export default StoreNavBar;